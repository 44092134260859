// @file
// Styles for checkbox form items.

.c-form-item--checkbox {
  margin-bottom: gesso-spacing(xs);
}

.c-form-item__checkbox {
  @extend %visually-hidden;

  + .c-form-item__label {
    cursor: pointer;
    display: block;

    &::before {
      @include focus();
      background: gesso-color(form, background-unchecked);
      border: 1px solid gesso-color(form, background-checked);
      content: '\a0';
      display: inline-block;
      height: 1em;
      line-height: 1;
      margin-right: 0.6em;
      margin-top: -0.1875em;
      text-indent: 0.15em;
      transition-duration: gesso-duration(short);
      transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
      transition-timing-function: gesso-easing(ease-in-out);
      vertical-align: middle;
      width: 1em;
    }
  }

  &:checked + .c-form-item__label::before {
    @include svg-background(correct);
    background-color: gesso-color(form, background-checked);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1em;
  }

  &:focus + .c-form-item__label::before {
    outline-color: gesso-color(ui, generic, focus);
  }

  &:disabled + .c-form-item__label {
    cursor: default;
    opacity: $form-disabled-opacity;

    &::before {
      cursor: not-allowed;
    }
  }
}
