// @file
// Image replacement

// After applying mixin, inline elements will need the display property set to
// block or inline-block.
@mixin image-replace($width, $height:$width) {
  height: $height;
  overflow: hidden;
  width: $width;

  &::before {
    content: '';
    display: block;
    height: 150%;
    width: 0;
  }
}

// This image replacement approach works with <input> and <button> elements.
// The font-size declaration may cause problems for SEO and browsers with a
// minimum font-size setting. Other inherited styles might also cause problems.
@mixin image-replace-alt($width, $height:$width) {
  color: transparent;
  font: 0/0 a;
  height: $height;
  text-shadow: none;
  width: $width;
}
