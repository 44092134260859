// @file
// Styles for a search.

.c-search--maroon {
  margin-bottom: 0;

  .c-form-item {
    position: relative;
  }

  .c-form-item__label {
    display: inline-block;
    font-size: gesso-font-size(5);
    font-weight: 700;
    margin-right: 1rem;

    @include breakpoint(1360px) {
      /*left: rem(-180px);
      position: absolute;
      top: rem(7px);*/
    }
  }
  .c-form-item__text {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.7);
    color: gesso-grayscale(white);
    height: auto;
    line-height: 1;
    min-width: rem(300px);
    padding-bottom: rem(16px);
    padding-left: rem(24px);
    padding-top: rem(16px);

    &::placeholder {
      color: gesso-grayscale(white);
    }
  }
}
