// @file
// Styles for the homepage hero.

.c-hero--homepage {
  background-color: gesso-grayscale(white);

  .c-hero__intro {
    position: relative;
  }

  .c-hero__title {
    @extend %hN;
    @include display-text-style(display);
    font-weight: 500;
    margin-bottom: 1rem;

    @include breakpoint(max-width gesso-breakpoint(font-size)) {
      font-size: rem(gesso-font-size(7));
      font-size: responsive-font-size(
        gesso-font-size(7),
        gesso-font-size(11),
        gesso-breakpoint(font-size)
      );
    }
  }

  .c-hero__subtitle {
    font-size: rem(gesso-font-size(3));
    margin-bottom: 1rem;
  }

  .c-hero__content {
    align-items: center;
    /* stylelint-disable */
    display: -ms-grid;
    /* stylelint-enable */
    display: grid;
    //grid-gap: rem(120px);
    /* stylelint-disable */
    -ms-grid-columns: 1fr;
    /* stylelint-enable */
    grid-template-columns: 1fr;
    /* stylelint-disable */
    -ms-grid-rows: repeat(1fr);
    /* stylelint-enable */
    grid-template-rows: repeat(1fr);
    padding: 2rem 0 rem(60px) 0;
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      /* stylelint-disable */
      -ms-grid-columns: 1fr 1fr;
      /* stylelint-enable */
      grid-template-columns: 1fr 1fr;
      padding: rem(100px) 0 rem(60px) 0;
    }
  }

  .c-hero__text {
    @include breakpoint(0 gesso-breakpoint(footer-layout)) {
      padding-top: 1.5rem;
    }
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      margin-right: 120px;
    }
  }

  .c-hero__image {
    /* stylelint-disable */
    -ms-grid-row: 1; 
    -ms-grid-column: 1;
    /* stylelint-enable */
    order: -1;
    padding-bottom: 2rem;
    position: relative;

    @include breakpoint(450px gesso-breakpoint(footer-layout)) {
      margin: 0 auto;
      max-width: 80%;
    }

    @include breakpoint(gesso-breakpoint(footer-layout)) {
      order: 2;
      /* stylelint-disable */
      -ms-grid-column: 2;
      /* stylelint-enable */
      padding-bottom: 0;
    }

    img {
      border-radius: 50%;
      position: relative;
      z-index: 4;
    }

    &::before,
    &::after {
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      @include svg-background(arrow-right--blue);
      animation-duration: 1.25s;
      animation-name: sneak;
      background-size: contain;
      height: rem(90px);
      left: 0;
      top: 0;
      transition: all 0.5s ease;
      width: rem(75px);
      z-index: 5;

      @include breakpoint(500px) {
        height: rem(212px);
        left: rem(-65px);
        width: rem(181px);
      }
    }

    &::after {
      animation-duration: 2.25s;
      animation-name: sneak;
      background-image: url('../images/triangle-trio-light-blue.png');
      background-size: contain;
      bottom: rem(-20px);
      //height: rem(60px);
      height: rem(125px);
      right: 0;
      transition: all 1.5s ease;
      //width: rem(70px);
        width: rem(150px);
      z-index: 2;

      @include breakpoint(0 500px) {
        //height: rem(60px);
        //width: rem(70px);
      }

      @include breakpoint(0 980px) {
        bottom: rem(-20px);
        height: rem(125px);
        right: rem(-15px);
        width: rem(150px);
      }

      @include breakpoint(1070px) {
        bottom: rem(20px);
        height: rem(125px);
        right: rem(-15px);
        width: rem(150px);
      }

      @include breakpoint(1300px) {
        bottom: rem(20px);
        height: rem(125px);
        right: rem(-50px);
        width: rem(150px);
      }
    }
  }

  @keyframes flight {
    0% {
      left: -150px;
      top: -20px;
      transform: rotate(90deg);
    }
    50% {
      left: -80px;
      top: 40px;
      transform: rotate(45deg);
    }
    100% {
      left: 0;
      top: 0;
      transform: rotate(0);
    }
  }

  @keyframes sneak {
    0% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
