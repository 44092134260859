// @file
// Styles for inverted form items.

.c-form-item--inverted {
  .c-form-item__label,
  .c-form-item__description {
    color: gesso-grayscale(white);
  }

  .c-form-item__color,
  .c-form-item__date,
  .c-form-item__email,
  .c-form-item__month,
  .c-form-item__number,
  .c-form-item__password,
  .c-form-item__search,
  .c-form-item__select,
  .c-form-item__tel,
  .c-form-item__text,
  .c-form-item__textarea,
  .c-form-item__time,
  .c-form-item__url,
  .c-form-item__week {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid gesso-grayscale(gray-3);
    color: gesso-grayscale(white);
    font-size: gesso-font-size(4);

    &::placeholder {
      color: gesso-grayscale(gray-3);
    }

    &:hover,
    &:focus {
      border-color: gesso-grayscale(white);
    }

    &:focus {
      outline-color: gesso-color(ui, generic, focus-light);
    }
  }
}
