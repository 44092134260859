// @file
// SVG background image

@mixin svg-background($image-name, $image-location: '../images/') {
  background-image: url($image-location + $image-name + '.svg');
}

@mixin svgz-background($image-name, $image-location: '../images/') {
  background-image: url($image-location + $image-name + '.svgz');
}

@mixin svg-background-inline($image-name, $image-location: '../images/') {
  background-image: inline($image-location + $image-name + '.svg');
}

@mixin svgz-background-inline($image-name, $image-location: '../images/') {
  background-image: inline($image-location + $image-name + '.svgz');
}
