// @file
// Styles for a banner.

.c-banner {
  background-color: gesso-grayscale(gray-2);
  overflow-x: hidden;
  padding: rem(gesso-spacing(2xl)) 0;

  > * {
    margin-bottom: 0;
  }

  &.is-centered {
    text-align: center;
  }

  &.no-spacing--bottom {
    padding-bottom: 0;
  }
}

.c-banner--half-padding {
  padding: rem(gesso-spacing(lg)) 0 rem(gesso-spacing(md)) 0;
}

.c-banner__content {
  .c-banner--featured-col &,
  .has-image & {
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      align-items: center;
      /* stylelint-disable */
      display: -ms-grid;
      /* stylelint-enable */
      display: grid;
      grid-gap: 2rem;

      /* stylelint-disable */
      -ms-grid-columns: 1fr;
      /* stylelint-enable */
      grid-template-columns: 1fr;
      grid-template-rows: repeat(1fr);
    }
  }

  .has-image & {
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      grid-gap: rem(100px);
      /* stylelint-disable */
      -ms-grid-columns: 1fr 450px;
      /* stylelint-enable */
      grid-template-columns: 1fr rem(450px);
    }
  }

  .c-banner--featured-col & {
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      /* stylelint-disable */
      -ms-grid-columns: 1fr 2fr;
      /* stylelint-enable */
      grid-template-columns: 1fr 2fr;
    }

    .c-banner__title {
      margin-bottom: gesso-spacing(md);

      &:only-child {
        @include breakpoint(gesso-breakpoint(footer-layout)) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.c-banner__title {
  @extend %h1;
  color: inherit;

  &.is-centered {
    margin: 0 auto;
    text-align: center;
  }
}

.c-banner__summary {
  margin-bottom: rem(gesso-spacing(sm));
}

.c-banner__button {

  > * {
    margin-bottom: 0 !important;
  }

  .c-banner__button-no-space {
    //margin-top: -(rem(gesso-spacing(lg)));
  }
}

.c-banner__text {
  /* stylelint-disable */
  -ms-grid-row: 1; 
  -ms-grid-column: 1;
  /* stylelint-enable */
}

.c-banner__image {

  /* stylelint-disable */
  -ms-grid-row: 1; 
  -ms-grid-column: 1;
  /* stylelint-enable */
  order: -1;
  position: relative;

  @include breakpoint(450px gesso-breakpoint(footer-layout)) {
    margin: 0 auto;
    max-width: 80%;
  }

  @include breakpoint(gesso-breakpoint(footer-layout)) {

    /* stylelint-disable */
    -ms-grid-column: 2;
    /* stylelint-enable */
    order: 2;
  }

  img {
    border-radius: 50%;
    position: relative;
    z-index: 4;
  }

  .c-banner--image-no-accents & {
    img {
      border-radius: 0;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.c-banner--map {
  padding-bottom: 0;
  padding-top: 0;
  @include breakpoint(gesso-breakpoint(footer-layout)) {
    /*margin-bottom: -150px;
    margin-top: -200px;*/
  }

  .c-banner__content {
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      align-items: center;
      //grid-gap: rem(50px);
      /* stylelint-disable */
      -ms-grid-columns: 1fr 400px;
      /* stylelint-enable */
      grid-template-columns: 1fr 400px;
    }

    @include breakpoint(930px) {

      /* stylelint-disable */
      -ms-grid-columns: 1fr 600px;
      /* stylelint-enable */
      grid-template-columns: 1fr 600px;
    }

    @include breakpoint(1130px) {

      /* stylelint-disable */
      -ms-grid-columns: 1fr 800px;
      /* stylelint-enable */
      grid-template-columns: 1fr 800px;
    }
  }

  .c-banner__text {
    margin-top: 2rem;
    /* stylelint-disable */
    -ms-grid-row: 1; 
    -ms-grid-column: 1;
    /* stylelint-enable */
    @include breakpoint(gesso-breakpoint(footer-layout)) {
      margin-right: 50px;
    }
    @include breakpoint(930px) {
      //padding-top: rem(gesso-spacing(lg));
      margin-top: 0;
    }
  }

  .c-banner__image {
    /* stylelint-disable */
    -ms-grid-column: 2;
    /* stylelint-enable */
    margin: 0;
    max-width: 100%;

    video {
      max-width: 100%;
      object-fit: contain;
      @include breakpoint(0 930px) {
        max-height: 400px;
        /*margin-bottom: -200px;
        margin-top: -300px;*/
      }
    }
  }
}

.c-banner.has-image:not(.c-banner--image-no-accents) {
  [data-aos='arrow-animation'] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
      opacity: 1;

      &::before,
      &::after {
        background-repeat: no-repeat;
        content: '';
        display: block;
        position: absolute;
      }

      &::before {
        @include svg-background(arrow-right--blue);
        animation-duration: 1.25s;
        animation-name: sneak;
        background-size: contain;
        height: rem(70px);
        left: rem(-65px);
        top: 0;
        transition: all 0.5s ease;
        width: rem(60px);
        z-index: 5;
  
        @include breakpoint(500px) {
          height: rem(212px);
          width: rem(181px);
        }
      }
  
      &::after {
        animation-duration: 2.25s;
        animation-name: sneak;
        background-image: url('../images/triangle-trio-light-blue.png');
        background-size: contain;
        bottom: rem(-60px);
        height: rem(60px);
        right: rem(-20px);
        transition: all 1.5s ease;
        width: rem(70px);
        z-index: 2;
  
        @include breakpoint(0 500px) {
          height: rem(60px);
          width: rem(70px);
        }
  
        @include breakpoint(500px) {
          bottom: rem(20px);
          height: rem(125px);
          right: rem(-50px);
          width: rem(150px);
        }
      }
    }
  }
}

@keyframes flight {
  0% {
    left: -150px;
    top: -20px;
    transform: rotate(90deg);
  }
  50% {
    left: -80px;
    top: 40px;
    transform: rotate(45deg);
  }
  100% {
    left: 0;
    top: 0;
    transform: rotate(0);
  }
}

@keyframes sneaky {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
