// @file
// Blockquote styles.

$blockquote-cite-divider-color: gesso-color(ui, generic, border);

blockquote {
  @include display-text-style(blockquote);
  border-bottom: 1px solid gesso-grayscale(gray-3);
  margin: rem(56px) 0 rem(gesso-spacing(md)) 0;
  padding: rem(40px) 10%;
  position: relative;
  text-align: center;

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(6));
    font-size: responsive-font-size(
      gesso-font-size(6),
      gesso-font-size(8),
      gesso-breakpoint(font-size)
    );
  }

  @include breakpoint(gesso-breakpoint(blockquote)) {
    margin-bottom: rem(gesso-spacing(3xl));

    * + & {
      margin-top: rem(gesso-spacing(lg));
    }
  }

  &::before {
    @include linear-gradient(
      gesso-color(gradients, blue-maroon, start),
      gesso-color(gradients, blue-maroon, middle),
      gesso-color(gradients, blue-maroon, end),
      90deg
    );
    content: '';
    display: block;
    height: rem(8px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  p {
    color: gesso-brand(maroon, base);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    &::before {
      content: '\201C';
    }

    &::after {
      content: '\201D';
    }
  }

  cite {
    @include display-text-style(cite);
    display: block;
    margin: rem(gesso-spacing(md)) 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media print {
    page-break-inside: avoid;
  }
}
