// @file
// Styles for an accordion.

$border-color: gesso-grayscale(gray-3);

.c-accordion {
  border-bottom: 1px solid $border-color;
  margin-bottom: rem(gesso-spacing(md));

  .c-accordion__icon {
    display: none;
    top: rem(-1px);
  }
}

.c-accordion__title {
  @extend %h6;
  background-color: gesso-grayscale(white);
  border-top: 1px solid $border-color;
  color: gesso-brand(navy, base);
  margin: 0;

  .no-js & {
    padding: rem(gesso-spacing(sm));
  }
}

.c-accordion__button {
  @extend %h6;
  @include focus();
  align-items: center;
  background: transparent;
  border: 0;
  color: inherit;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: rem(gesso-spacing(sm)) rem(gesso-spacing(xs));
  position: relative;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus {
    background-color: gesso-grayscale(gray-2);
  }

  > * {
    flex-grow: 1;
  }

  &[aria-expanded='false']::after {
    @include accordion-icon-is-closed();
  }

  &[aria-expanded='true']::after {
    @include accordion-icon-is-open();
    top: 40%;
  }
}

.c-accordion__content {
  padding: rem(gesso-spacing(sm)) rem(gesso-spacing(xs)) rem(gesso-spacing(md));

  > :last-child {
    margin-bottom: 0;
  }
}
