// @file
// Styles for the social menu.

$link-font-size: gesso-font-size(6);
$link-width: 44px;
$link-padding: ($link-width - $link-font-size) / 2;

.c-menu--social {
  @include list-clean;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: rem(gesso-spacing(md));

  @include breakpoint(gesso-breakpoint(footer-layout)) {
    justify-content: flex-start;
    margin-left: rem(-$link-padding);
  }

  .c-menu__link {
    color: gesso-brand(true-blue, base);
    display: block;
    font-size: rem($link-font-size);
    line-height: 0.75;
    padding: rem($link-padding);
    text-decoration: none;

    &:hover,
    &:focus {
      color: gesso-brand(maroon, base);
    }
  }
}
