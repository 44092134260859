// @file
// Styles for filters list.

.c-list--filters {
  @include list-clean();

  li:last-child {
    margin-bottom: 0;
  }
}
