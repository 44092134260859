// @file
// Heading styles.

%hN {
  color: inherit;
  font-weight: gesso-font-weight(bold);
  hyphens: none;
  line-height: gesso-line-height(base);
  margin: 0 0 rem(gesso-spacing(md));
  text-rendering: optimizeLegibility;

  @media print {
    orphans: 3;
    page-break-after: avoid;
    widows: 3;

    &::after {
      display: inline-block;
    }
  }

  // Add top margin when headers are preceded by other elements (p, ul, etc.)
  * + & {
    margin-top: 1em;
  }
}

h1,
%h1 {
  @extend %hN;
  @include display-text-style(h1);

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(8));
    font-size: responsive-font-size(gesso-font-size(8), gesso-font-size(10), gesso-breakpoint(font-size));
  }
}

h2,
%h2 {
  @extend %hN;
  @include display-text-style(h2);

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(7));
    font-size: responsive-font-size(gesso-font-size(7), gesso-font-size(9), gesso-breakpoint(font-size));
  }
}

h3,
%h3 {
  @extend %hN;
  @include display-text-style(h3);

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(6));
    font-size: responsive-font-size(gesso-font-size(6), gesso-font-size(8), gesso-breakpoint(font-size));
  }
}

h4,
%h4 {
  @extend %hN;
  @include display-text-style(h4);

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(5));
    font-size: responsive-font-size(gesso-font-size(5), gesso-font-size(7), gesso-breakpoint(font-size));
  }
}

h5,
%h5 {
  @extend %hN;
  @include display-text-style(h5);

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(4));
    font-size: responsive-font-size(gesso-font-size(4), gesso-font-size(5), gesso-breakpoint(font-size));
  }
}

h6,
%h6 {
  @extend %hN;
  @include display-text-style(h6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    color: gesso-color(text, primary);

    &:hover,
    &:focus {
      color: gesso-color(text, link-hover);
    }
  }

  // Remove top margin for adjacent subheadings.
  & + & {
    margin-top: 0;
  }
}
