// @file
// Inline element styles.

a {
  @include focus();
  background-color: transparent;
  color: gesso-color(text, link);
  -webkit-text-decoration-skip: objects;
  transition-duration: gesso-duration(short);
  transition-property: background, color;
  transition-timing-function: gesso-easing(ease-in);

  &:hover,
  &:focus {
    color: gesso-color(text, link-hover);
  }

  &:active {
    color: gesso-color(text, link-active);
  }

  &:visited {
    color: gesso-color(text, link-visited);
  }

  &[href^="https://"]:not([href*='leverforchange']),
  &[href^="http://"]:not([href*='leverforchange']){

    &::after {
      background-color: gesso-grayscale(white);
      background-image: url("../images/external-link--grey.svg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 25px;
      margin-bottom: -10px;
      margin-left: 0.3rem;
      width: 15px;
    }
  }

  @media print {
    &,
    &:visited {
      text-decoration: none;
    }

    &[href]::after {
      content: ' <' attr(href) '>';
      font-family: gesso-font-family(primary);
      font-size: 10pt;
      font-weight: normal;
      text-transform: lowercase;
    }

    // Uncomment and replace example.com with your site’s domain.
    //&[href^='/']::after {
    //  content: ' <http://example.com' attr(href) '>';
    //}

    &[href^='javascript:']::after,
    &[href^='mailto:']::after,
    &[href^='tel:']::after,
    &[href^='#']::after,
    &[href*='?']::after {
      content: '';
    }
  }
}

// For text links only.
.c-article__main a,
.c-field a {
  @include focus();
  background-color: transparent;
  box-shadow: inset 0 -0.1rem 0 0 #902f57;
  color: gesso-color(text, primary);
  text-decoration: none;
  text-decoration-skip: objects;
  transition: box-shadow 430ms cubic-bezier(0.55, 0.085, 0, 0.99);

  &:hover,
  &:focus {
    box-shadow: inset 0 -0.75rem 0 0 rgba(144, 47, 87, 0.3);
    transition: box-shadow 430ms cubic-bezier(0.55, 0.085, 0, 0.99);
  }
}

abbr {
  &[title] {
    border-bottom: 0;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  &:hover {
    cursor: help;
  }

  @media print {
    &[title]::after {
      content: ' (' attr(title) ')';
    }
  }
}

// b {}

// cite {}

// code {}

// del {}

dfn {
  font-style: italic;
}

// em {}

// i {}

// ins {}

// kbd {}

mark {
  background: gesso-color(mark, background);
  color: gesso-color(mark, text);
}

// q {}

// s {}

// samp {}

small {
  font-size: 75%;
}

// strong {}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

// time {}

// u {}

// var {}
