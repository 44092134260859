@mixin display-text-style($keys...) {
  $display: gesso-get-map(typography, display, $keys...);

  @each $property, $value in $display {
    // @debug #{$property} $value;

    @if ($property == "font-size") {
      //check for px if not output value
      // @debug type-of($value);
      #{$property}: #{rem(convert($value))};
    } @else {
      #{$property}: #{$value};
    }
  }

}
