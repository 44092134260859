// @file
// Styles for radio form items.

.c-form-item--radio {
  margin-bottom: gesso-spacing(xs);

  .c-form-item__radio {
    @include focus();
    appearance: none;
    background-color: gesso-color(form, background-unchecked);
    border: 1px solid gesso-color(form, background-checked);
    border-radius: 50%;
    box-shadow: 0 0 0 2px transparent;
    cursor: pointer;
    font-size: rem($form-text-size);
    height: 1em;
    margin-right: 0.125em;
    position: relative;
    top: 0.1875em;
    transition-duration: gesso-duration(short);
    transition-property: background-color, border-color, border-radius;
    transition-timing-function: gesso-easing(ease-in-out);
    width: 1em;

    &:checked {
      background-color: gesso-color(form, background-checked);
      border: 2px solid gesso-color(form, background-unchecked);
      box-shadow: 0 0 0 2px gesso-color(form, background-checked);
    }

    &:disabled {
      cursor: default;
      opacity: $form-disabled-opacity;
    }

    + .c-form-item__label {
      cursor: pointer;
      display: inline;
    }

    &:disabled + .c-form-item__label {
      cursor: default;
      opacity: $form-disabled-opacity;
    }
  }
}
