.data-item {
  @include breakpoint(0 615px) {
    text-align: center;
  }
}

.data-item__first-line {
  font-family: gesso-font-family(secondary);
  font-size: gesso-font-size(11);
  font-weight: 700;
  line-height: 1;
}

.data-item__second-line {
  font-size: gesso-font-size(5);
  line-height: 1.1;

  @include breakpoint(880px) {
    font-size: gesso-font-size(6);
  }

  @include breakpoint(950px) {
    font-size: gesso-font-size(7);
  }
}