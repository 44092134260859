.three-columns {
  margin-top: 4rem;

  @include breakpoint(775px) {
    align-items: stretch;
    /* stylelint-disable */
    display: -ms-grid;
    /* stylelint-enable */
    display: grid;
    /* stylelint-disable */
    -ms-grid-columns: 200px 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    /* stylelint-enable */
    grid-template-columns: 200px 1fr 1fr;
  }

  @include breakpoint(980px) {
    /* stylelint-disable */
    -ms-grid-columns: 400px 1fr 1fr;
    /* stylelint-enable */
    grid-template-columns: 400px 1fr 1fr;
  }

  p {
    font-size: 18px;
  }
}

.three-columns__image {
  /* stylelint-disable */
  -ms-grid-column: 200px;
  /* stylelint-enable */

  @include breakpoint(0 775px) {
    margin-bottom: 2rem;
  }

  @include breakpoint(775px) {
    margin-right: 72px;
  }

  @include breakpoint(980px) {
    /* stylelint-disable */
    -ms-grid-column: 400px;
    /* stylelint-enable */
  }
}

.three-columns__text-first {
  margin-right: 24px;
  /* stylelint-disable */
  -ms-grid-column: 2;
  /* stylelint-enable */

}

.three-columns__text-last {
  /* stylelint-disable */
  -ms-grid-column: 3;
  /* stylelint-enable */
}

.three-columns__empty {
  /* stylelint-disable */
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  /* stylelint-enable */
}

.three-columns__button {
  
  /* stylelint-disable */
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  /* stylelint-enable */
}