.c-search-forms {
  margin: gesso-spacing(md) 0;

  .c-form-item {
    margin-bottom: rem(12px);

    @include breakpoint(800px) {
      max-width: 85%;
    }
  }

  .c-form-item__label {
    @extend %hidden;
    display: none;
  }
}

.c-search-forms__select,
.c-search-forms__search {
  background-color: gesso-grayscale(white);
  background-position: 10px center;
  background-repeat: no-repeat;
  border: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  padding-left: rem(40px);
}

.c-banner--maroon .c-search-forms__search:hover,
.c-banner--maroon .c-search-forms__search:focus {
  border: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.9);
} 

.c-search-forms__select {
  @include svg-background(location);
}

.c-search-forms__search {
  @include svg-background(search-gray);
  margin-bottom: rem(24px);
}
