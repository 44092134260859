// @file
// Gradient

@mixin linear-gradient($start-color, $middle-color, $end-color, $direction: 90deg) {
  background: $start-color;

  @if $middle-color {
    background: linear-gradient($direction, $start-color 0%, $middle-color 50%, $end-color 100%);
  }
  @else {
    background: linear-gradient($direction, $start-color 0%, $end-color 100%);
  }
}
