// @file
// HTML styles.

html {
  box-sizing: border-box;
  font-family: gesso-font-family(primary);
  font-size: 100% * (gesso-base-font-size() / 16px);
  line-height: gesso-line-height(base);
  min-height: 100%;
  text-size-adjust: 100%;
}
