// @file
// Styles for a button.

$button-font-size: gesso-font-size(2);

.c-button {
  background-color: transparent;
  border: 1px solid transparent;
  color: gesso-color(button, primary, text);
  cursor: pointer;
  display: inline-block;
  font-family: gesso-font-family(primary);
  font-size: rem($button-font-size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: gesso-font-weight(bold);
  line-height: (gesso-font-size(3) / $button-font-size);
  margin: 0 0 rem(gesso-spacing(xs));
  padding: em(14px, $button-font-size) em(25px, $button-font-size)
    em(12px, $button-font-size);
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background gesso-duration(short) gesso-easing(ease-in);
  vertical-align: top;
  white-space: normal;
  z-index: 0;

  &::before {
    @include linear-gradient(
      gesso-color(gradients, maroon, start),
      gesso-color(gradients, maroon, middle),
      gesso-color(gradients, maroon, end),
      90deg
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &:visited {
    color: gesso-color(button, primary, text);
  }

  &:focus {
    outline: 2px solid gesso-color(ui, generic, focus);
    outline-offset: 1px;
  }

  &:hover,
  &:focus {
    border-color: #A91552;
    color: #A91552;
    &::before {
      //background-color: gesso-grayscale(white);
      @include linear-gradient(
      gesso-grayscale(white),
      gesso-grayscale(white),
      gesso-grayscale(white),
      90deg
    );
    }
  }

  &:active {
    background-color: gesso-color(button, primary, background-active);
    color: gesso-color(button, primary, text-active);
  }

  &[disabled] {
    background-color: gesso-color(button, disabled, background);
    color: gesso-color(button, disabled, text);
    cursor: default;
    pointer-events: none;
  }

  & + & {
    margin-left: rem(gesso-spacing(xs)); // LTR

    @if $support-for-rtl {
      [dir='rtl'] & {
        margin-left: 0;
        margin-right: rem(gesso-spacing(xs));
      }
    }
  }
}

.c-button--external {
  padding-right: rem(40px);
  &::after {
    @include svg-background(external-link);
    background-repeat: no-repeat;
    background-size: rem(14px) rem(14px);
    content: '';
    display: block;
    height: rem(14px);
    position: absolute;
    right: rem(gesso-spacing(sm));
    top: rem(15px);
    width: rem(14px);
  }

  @media print {
    &::after {
      background: transparent;
      content: ' <' attr(href) '>';
      position: static;
      width: 100%;
    }
  }
}
