// @file
// Styles for a navy-blue-dark gradient hero.

.c-hero--maroon {
  @include linear-gradient(
    gesso-color(gradients, maroon, start),
    gesso-color(gradients, maroon, middle),
    gesso-color(gradients, maroon, end),
    90deg);
  border-top: 1px solid gesso-grayscale(white);
  color: gesso-grayscale(white);

  .c-hero__type {
    border: 1px solid gesso-grayscale(white);
    color: gesso-grayscale(white);
  }

  .c-hero__title {
    color: gesso-grayscale(white);
  }

  .c-hero__date {
    color: gesso-grayscale(white);
  }
}
