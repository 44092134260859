// @file
// Styles for a flipped billboard.

.c-billboard.is-flipped {
  .c-billboard__media {
    @include breakpoint($bp-billboard-layout) {
      order: 2;
    }
  }
}
