// @file
// Styles for a billboard.

$bp-billboard-layout: 800px;

.c-billboard {
  @include breakpoint($bp-billboard-layout) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.c-billboard__media {
  position: relative;

  &::before {
    background-image: url('../images/billboard-before.png');
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: rem(182px);
    margin: 0 0 rem(-43px) rem(117px);
    width: rem(218px);
  }

  &::after {
    background-image: url('../images/billboard-after.png');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: rem(187px);
    margin-top: rem(-118px);
    position: relative;
    right: rem(21px);
    width: 100%;
  }

  @include breakpoint($bp-billboard-layout) {
    width: 48%;
    width: calc(50% - #{rem(gesso-spacing(sm))});
  }
}

.c-billboard__wrapper {
  content: '';
}

.c-billboard__image {
  box-shadow: gesso-box-shadow(1);
}

.c-billboard__content {
  @include breakpoint($bp-billboard-layout) {
    width: 48%;
    width: calc(50% - #{rem(gesso-spacing(sm))});
  }
}
