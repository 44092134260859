// @file
// Styles for the newsletter signup.

.c-newsletter-signup {
  margin-bottom: rem(gesso-spacing(md));
}

.c-newsletter-signup__header {
  @extend %h5;
}

.c-newsletter-signup__content {
  display: flex;

  > * {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.c-newsletter-signup__button {
  flex-grow: 0;

  > * {
    margin: 0;
  }
}
