.c-hero .l-constrain {
  // Aside from the cta, there shouldn't be another element in the hero, so the 
  // flex property won't do anything if the cta isn't present.
  @include breakpoint(gesso-breakpoint(small-constrain)) {
    display: flex;
    justify-content: space-between;
  }
}

.cta-right {
  align-self: flex-start;
  background-color: gesso-grayscale(white);
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
  margin-top: 60px;
  padding: 35px;

  @include breakpoint(gesso-breakpoint(small-constrain)) {
    margin: 60px 0 0 40px;
    min-width: 360px;
  }
}

.hero-cta__form-title {
  font-size: gesso-font-size(8);
}

.hero-cta__form-content {
  font-size: gesso-font-size(3);
}

.hero-cta__thanks {
  display:none;
}

.hero-cta__form .c-button[disabled], 
.hero-cta__form .c-button[disabled]::before {
  background: #515760;
}

.cta-form {
  .c-form-item__label {
    display: none;
  }

  .cta-email-input {
    @include svg-background(email);
    background-position-x: 10px;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 1px solid gesso-grayscale(gray-3);
    border-radius: 2px;
    box-shadow: none;
    padding: 5px 0 5px 30px;
    width: 100%;
  
    &::placeholder {
      font-size: gesso-font-size(1);
      padding-left: 20px;
    }
  }

  .c-button {
    margin-bottom: 0;
    width: 100%;
  }
}

.errortext {
  color: gesso-brand(maroon, light);
  font-size: .7em;
}
