// @file
// Styles for the first alternate billboard.

.c-billboard--alt-1 {
  .c-billboard__media {
    &::before {
      background-image: url('../images/billboard-alt-1-before.png');
    }

    &::after {
      background-image: url('../images/billboard-alt-1-after.png');
    }
  }
}
