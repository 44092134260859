.drop-down {
  border: 1px solid #A2A4B2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  height: 40px;
  list-style: none;
  margin: 100px;
  padding: 0;
  position: relative;
  width: 250px;
  
  &__label {
    background: #fff;
    color: #444;
    font-family: Lato, sans-serif;
    font-size: .8em;
    left: 15px;
    position: absolute;
    top: -9px;
  }
  
  &__arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transition: transform .2s linear;
    
    &.expanded {
      transform: rotate(-180deg);
    }
  }

  &__list {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    //box-shadow: 0px 3px 2px 0 #a2a4b2;
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: opacity .1s cubic-bezier(0, 0, 0.38, 0.9), max-height .5s cubic-bezier(0, 0, 0.38, 0.9);
    width: 100%;
  }
  
  &__list-container {
    position: relative;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
   &__list-item {
    color: #444;
    font-family: Lato, sans-serif;
    list-style-position: inside;
    overflow: hidden;
    padding: 10px 0;
    padding-left: 15px;
    text-overflow: ellipsis;
    transition: background-color .1s linear, color .1s linear;
    white-space: nowrap;

     &:hover,
     &:focus {
       background-color: #00C2FF;
       color: #fff;
     }
  }
}

.drop-down__selected {
  color: #444;
  font-family: Lato, sans-serif;
  list-style-position: inside;
  max-width: 80%;
  overflow: hidden;
  padding: 10px 0;
  padding-left: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  &:focus {
    outline: 1px solid #00C2FF;
  }
}

.open {
  max-height: 80vh;
  opacity: 1;
  overflow: auto;
}