.teaser {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  margin-bottom: calc(2rem + 40px);

  @include breakpoint(800px) {
    grid-template-columns: 270px 1fr;
  }
}

.teaser__title {
  margin: gesso-spacing(sm) 0;
  
  a {
    text-decoration: none;

    &:hover,
    &:focus {
      border-bottom: 2px solid gesso-brand(navy, base);
    }

    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: gesso-brand(navy, base);
    }
  }
}

.teaser__tag {
  color: gesso-brand(maroon, base);
  font-weight: 600;
}

.award-teaser {
  color: gesso-grayscale(gray-5);
  font-size: gesso-font-size(1);
  line-height: 1;
  margin-top: rem(gesso-spacing(md));
  padding-bottom: rem(gesso-spacing(sm));
  padding-top: rem(gesso-spacing(xs));

  &.award-teaser__award {
    @include svg-background(trophy);
    background-position: left top;
    background-repeat: no-repeat;
    padding-left: rem(gesso-spacing(lg));
  }
}
