// @file
// Styles for Login form on page

.login {
    padding: 20px;
    position: relative;

    @include breakpoint(800px) {
        max-width: 45%;
    }

    h2 {
        font-weight: 300;
    }

    form {
        display: flex;
        flex-direction: column;

        input {
            margin-bottom: 1rem;
        }

        .c-form-item__label {
            display: none;
        }

        .c-button {
            align-self: flex-start;
        }
    }

    &::after {
        background-image: url('../images/triangle-trio-light-blue.png');
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -95px;
        content: '';
        display: block;
        height: rem(100px);
        position: absolute;
        right: 0;
        width: rem(100px);
        z-index: 5;

        @include breakpoint(500px) {
            bottom: -25px;
            height: rem(120px);
            right: -15px;
            width: rem(150px);
        }
    }
}
