// @file
// Ordered list styles.

ol {
  margin: 0 0 rem(gesso-spacing(md));
  padding: 0 0 0 rem(gesso-spacing(md)); // LTR

  @if $support-for-rtl {
    [dir='rtl'] & {
      padding: 0 rem(gesso-spacing(md)) 0 0;
    }
  }

  ol,
  ul {
    margin: rem(gesso-spacing(sm) / 2) 0 0;
  }
}
