.tag {
  border: 1px solid gesso-brand(magenta, base);
  color: gesso-grayscale(white);
  display: inline-block;
  font-size: gesso-font-size(1);
  margin: 0 gesso-spacing(xs) gesso-spacing(xs) 0;
  padding: gesso-spacing(2xs) gesso-spacing(xs);
  text-decoration: none;

  &:visited,
  &:hover,
  &:focus,
  &:active {
    background-color: gesso-brand(magenta, base);
    color: gesso-grayscale(white);
  }
}