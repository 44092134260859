// @file
// Styles for the mobile menu buttons.

.c-mobile-menu-button {
  @include focus();
  align-items: center;
  align-self: center;
  background-color: gesso-grayscale(white);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: gesso-brand(maroon, base);
  display: flex;
  font-family: gesso-font-family(system);
  font-size: rem(gesso-font-size(1));
  font-weight: gesso-font-weight(bold);
  height: rem(75px);
  line-height: gesso-line-height(tight);
  margin-bottom: 0;
  outline-offset: 0;
  padding: 0 rem(gesso-spacing(sm));
  text-decoration: none;

  @include breakpoint(0 rem(800px)) {
    padding: 0 rem(gesso-spacing(xs));
  }
}

.c-mobile-menu-button__icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: rem(20px) rem(14px);
  height: rem(14px);
  margin-left: rem(gesso-spacing(xs));
  width: rem(20px);
}

.c-mobile-menu-button--close {
  float: right;
  //margin-right: -rem(gesso-spacing(xs));

  .c-mobile-menu-button__icon {
    @include svg-background-inline(mobile-close);
    background-size: rem(14px);
    width: rem(14px);
  }
}

.c-mobile-menu-button--menu {
  //margin-right: rem(-8px);

  .c-mobile-menu-button__icon {
    @include svg-background-inline(mobile-menu);
  }
}
