// @file
// Styles for a secondary button.

$button-font-size: gesso-font-size(2);

.c-button--secondary {
  border: 1px solid gesso-brand(true-blue, dark);
  color: gesso-brand(true-blue, dark);
  padding: em(13px, $button-font-size) em(25px, $button-font-size) em(11px, $button-font-size);
  transition-duration: gesso-duration(short);
  transition-property: background, border, color;
  transition-timing-function: gesso-easing(ease-in);

  &::before {
    display: none;
  }

  &:visited {
    color: gesso-brand(true-blue, dark);
  }

  &:hover,
  &:focus {
    background-color: gesso-grayscale(white);
    border-color: gesso-brand(navy, base);
    color: gesso-brand(navy, base);
  }

  &:active {
    background-color: gesso-grayscale(white);
    border-color: gesso-brand(true-blue, light);
    color: gesso-brand(true-blue, light);
  }

  &[disabled] {
    background-color: gesso-grayscale(white);
    border-color: gesso-color(button, disabled, background);
    color: gesso-color(button, disabled, background);
  }
}
