// @file
// Styles for constraining the page width.

.l-constrain {
  @include layout-constrain;
}

.l-constrain--large {
  max-width: rem(gesso-constrain(lg));
}

.l-constrain--no-padding {
  padding-left: 0;
  padding-right: 0;
}

.l-constrain--small {
  max-width: rem(gesso-constrain(sm));
}

.l-constrain--xsmall {
  max-width: rem(825px);
}
