// @file
// Styles for a maroon-blue banner.

.c-banner--gradient-text {
  background-color: gesso-grayscale(white);

  .c-banner__title {
    @include linear-gradient(
      gesso-brand(true-blue, dark),
      false,
      gesso-color(gradients, blue-maroon, end),
      90deg
    );


    -webkit-background-clip: text;
    background-clip: text;
    color: gesso-grayscale(white);
    line-height: 1.3;
    -webkit-text-fill-color: transparent;
  }
}
