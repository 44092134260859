// @file
// Styles for a tertiary button.

$button-font-size: gesso-font-size(2);

.c-button--tertiary {
  background: transparent;
  border: 1px solid gesso-grayscale(white);
  color: gesso-grayscale(white);
  padding: em(13px, $button-font-size) em(25px, $button-font-size) em(11px, $button-font-size);
  transition-duration: gesso-duration(short);
  transition-property: background, border, color;
  transition-timing-function: gesso-easing(ease-in);

  &::before {
    display: none;
  }

  &:visited {
    color: gesso-grayscale(white);
  }

  &:hover,
  &:focus {
    background: gesso-grayscale(white);
    border-color: gesso-grayscale(white);
    color: gesso-brand(navy, base);
  }

  &:focus {
    outline-color: gesso-color(ui, generic, focus-light);
  }

  &:active {
    background: gesso-grayscale(white);
    border-color: gesso-grayscale(white);
    color: gesso-brand(true-blue, light);
  }

  &[disabled] {
    background: transparent;
    border-color: gesso-grayscale(gray-4);
    color: gesso-grayscale(gray-4);
  }
}
