// @file
// Styles for form fieldsets.


$fieldset-legend-offset: rem(gesso-line-height(base) * gesso-base-font-size()) !default;

.c-fieldset {
  margin-bottom: rem(gesso-spacing(md) + (gesso-line-height(base) * gesso-base-font-size()));
  padding: 0;
  position: relative;
  top: $fieldset-legend-offset; // Offsets the negative margin of legends.
}

.c-fieldset__legend {
  box-sizing: content-box;
  display: block;
  left: -1px; // LTR
  position: absolute;
  text-indent: 0;
  top: -$fieldset-legend-offset;
  width: 100%;

  @if $support-for-rtl {
    [dir='rtl'] & {
      left: auto;
      right: -1px;
    }
  }
}

.c-fieldset__legend-text {
  display: block;
}

.c-fieldset__legend-link {
  display: block;
}

// .c-fieldset__content {}

.c-fieldset__description {
  color: gesso-color(text, secondary);
  font-size: rem(gesso-font-size(2));

  > :last-child {
    margin-bottom: 0;
  }
}

.c-fieldset--default {
  background: #fff;
  border: 1px solid gesso-color(form, border);
  margin: rem(gesso-spacing(sm)) 0 (rem(gesso-spacing(sm)) + rem(12px));
  padding: 0 1em;

  .c-fieldset__legend {
    background: gesso-color(form, border);
    border: 1px solid gesso-color(form, border);
    border-bottom: 0;
    color: #212121;
    height: 2em;
    line-height: 2;
    padding: 0;
    text-shadow: 0 1px 0 #fff;
  }

  .c-fieldset__legend-text {
    padding-left: 1em; // LTR

    @if $support-for-rtl {
      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 1em;
      }
    }
  }

  .c-fieldset__content {
    margin-top: 2.5em;
  }

  .c-fieldset__description {
    font-style: italic;
    margin: rem(gesso-spacing(sm)) 0;
  }
}
