// @file
// Layout mixins

@mixin layout-align-left($margin) {
  float: left;

  @if $margin {
    margin-right: $margin;
  }
}

@mixin layout-align-right($margin) {
  float: right;

  @if $margin {
    margin-left: $margin;
  }
}

@mixin layout-align-none {
  float: none;
  margin-left: 0;
  margin-right: 0;
}

@mixin layout-constrain($max-width: gesso-constrain(md), $gutter-width: gesso-spacing(lg)) {
  margin-left: auto;
  margin-right: auto;
  max-width: rem($max-width);

  @if $gutter-width {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
  }
}
