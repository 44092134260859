// @file
// Styles for the return to top link.

.c-return-to-top {
  align-items: center;
  color: gesso-brand(true-blue, base);
  display: inline-flex;
  font-size: rem(gesso-font-size(1));
  font-weight: 300;
  text-decoration: none;

  &::before {
    @include svg-background(return-arrow);
    background-color: gesso-brand(true-blue, base);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: rem(14px) rem(8px);
    border-radius: 0 0 60% 60% / 0 0 100% 100%;
    content: '';
    display: inline-block;
    height: rem(33px);
    margin-right: gesso-spacing(xs);
    transition-duration: gesso-duration(short);
    transition-property: background-color background-position;
    transition-timing-function: gesso-easing(ease-in-overshoot);
    width: rem(48px);
  }

  &:visited {
    color: gesso-brand(true-blue, base);
  }

  &:hover,
  &:focus,
  &:active {
    color: gesso-brand(true-blue, light);

    &::before {
      background-color: gesso-brand(true-blue, light);
      background-position: 50% 40%;
    }
  }
}
