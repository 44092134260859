// @file
// Styles for tertiary form items.

.c-form-item--tertiary {
  .c-form-item__label,
  .c-form-item__description {
    color: gesso-grayscale(white);
  }

  .c-form-item__color,
  .c-form-item__date,
  .c-form-item__email,
  .c-form-item__month,
  .c-form-item__number,
  .c-form-item__password,
  .c-form-item__search,
  .c-form-item__select,
  .c-form-item__tel,
  .c-form-item__text,
  .c-form-item__textarea,
  .c-form-item__time,
  .c-form-item__url,
  .c-form-item__week {
    background-color: transparent;
    border-color: gesso-grayscale(white);
    color: gesso-grayscale(white);
    transition-duration: gesso-duration(short);
    transition-property: background, border, color;
    transition-timing-function: gesso-easing(ease-in);

    &::placeholder {
      color: gesso-grayscale(gray-3);
    }

    &:hover,
    &:focus {
      background: gesso-grayscale(white);
      border-color: gesso-grayscale(white);
      color: gesso-brand(navy, base);

      &::placeholder {
        color: gesso-grayscale(gray-5);
      }
    }

    &[disabled] {
      background: transparent;
      border-color: gesso-grayscale(gray-4);
      color: gesso-grayscale(gray-4);
    }
  }
}
