// @file
// Styles for the info bar.

.c-info-bar {
  @include linear-gradient(
    gesso-color(gradients, navy-blue-light, start),
    gesso-color(gradients, navy-blue-light, middle),
    gesso-color(gradients, navy-blue-light, end),
    90deg);
  color: gesso-grayscale(white);
  font-size: rem(gesso-font-size(1));
  margin-bottom: rem(gesso-spacing(md));
  padding: rem(gesso-spacing(xs)) 0;
}
