// @file
// Accessibility classes

.u-visually-hidden {
  @include visually-hidden-important;

  &.is-focusable {
    &:focus,
    &:active {
      @include visually-hidden-off-important;
    }
  }
}

.u-hidden {
  @include hidden-important;
}

.u-invisible {
  @include invisible-important;
}
