// @file
// Styles for a filter.

.c-filter {
  color: gesso-brand(navy, base);
  display: block;
  position: relative;
  text-decoration: none;

  &:hover,
  &:focus {
    color: gesso-brand(navy, base);
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid #006ece;
  }

  &::before {
    background: gesso-color(form, background-unchecked);
    border: 1px solid gesso-grayscale(gray-5);
    content: '\a0';
    display: inline-block;
    height: 1em;
    line-height: 1;
    margin-right: 0.6em;
    margin-top: -0.1875em;
    text-indent: 0.15em;
    transition-duration: gesso-duration(short);
    transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
    transition-timing-function: gesso-easing(ease-in-out);
    vertical-align: middle;
    width: 1em;
  }

  &.is-active::before {
    @include svg-background(filter-selected);
    background-color: gesso-brand(maroon, base);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.6em;
    border-color: gesso-brand(maroon, base);
  }
}

.c-filter__count {
  color: gesso-brand(true-blue, light);
}