.c-slider {
  //overflow-x: hidden;
  background: linear-gradient(
    to right,
    gesso-grayscale(white) 0%,
    gesso-grayscale(white) 30%,
    gesso-grayscale(gray-2) 30%,
    gesso-grayscale(gray-2) 100%
  );
  overflow-x: hidden;
  padding: gesso-spacing(2xl) 0 gesso-spacing(2xl) gesso-spacing(lg);
  position: relative;

  .slick-track {
    padding: rem(10px) 0 rem(10px) rem(7px);
  }

  &.c-slider--no-nav {
    padding-right: gesso-spacing(2xl);
  }
}

.c-slider__count,
.c-slider__title {
  display: inline-block;
}

.c-slider__count {
  color: gesso-grayscale(gray-5);
  font-size: gesso-font-size(2);
  margin-left: rem(gesso-spacing(md));

  strong {
    font-size: gesso-font-size(6);
  }

  @include breakpoint(800px) {
    margin-left: rem(gesso-spacing(3xl));
  }
}

.c-slider__slides {
  /*left: 15rem;
  position: absolute;*/
  @include breakpoint(800px) {
    margin-left: 5rem;
    width: 102vw;
  }

  .c-slider--no-nav & {
    @include breakpoint(800px) {
      margin-left: 0;
      //margin-right: gesso-spacing(2xl);
      width: 95vw;
    }
  }

  .c-card__image {
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}

.c-slider__content {
  .c-card {
    //border: 5px solid transparent;
    margin-right: rem(20px);

    @include breakpoint(0 800px) {
      margin-bottom: rem(20px);
    }
  }

  .slick-current {
    //border-color: gesso-brand(true-blue);

    box-shadow: 0 0 7px 0 rgba(12, 15, 20, 0.5),
      inset 0 0 0 5px gesso-brand(true-blue, base);

    .c-card__image {
      border: 5px solid gesso-brand(true-blue, base);
      border-bottom: 0;
    }
  }
}

.c-slider__navigation {
  @include breakpoint(800px) {
    left: gesso-spacing(lg);
    position: absolute;
  }

  .slick-arrow {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100%;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: rem(60px);
    margin-bottom: rem(gesso-spacing(sm));
    transition: transform 0.3s ease-in-out;
    width: rem(60px);

    @include breakpoint(800px) {
      display: block;
    }

    span {
      @extend %hidden;
    }

    &:hover,
    &:focus {
      transform: translate(5px);
    }
  }

  .slick-prev {
    @include svg-background(circle-arrow--left);

    &:hover,
    &:focus {
      @include svg-background(circle-arrow--left-full);
    }
  }

  .slick-next {
    @include svg-background(circle-arrow--right-full);
    // &:hover,
    // &:focus {
    //   @include svg-background(circle-arrow--right);
    // }
  }
}

// Disable if equal height cards in slider are no longer desired;
.slick-track {
  display: flex;

  .slick-slide {
    display: flex;
    height: auto;
    padding-bottom: rem(gesso-spacing(2xl));
  }
}
