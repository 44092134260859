// @file
// Styles for vertical spacing.

$sizes: 2xs, xs, sm, md, lg, xl, 2xl, 3xl;

@each $size in $sizes {
  .u-spaced-#{$size},
  .u-spaced-#{$size}-above {
    margin-top: #{rem(gesso-spacing($size))} !important;
  }

  .u-spaced-#{$size},
  .u-spaced-#{$size}-below {
    margin-bottom: #{rem(gesso-spacing($size))} !important;
  }
}

.u-spaced-none,
.u-spaced-none-above {
  margin-top: 0 !important;
}

.u-spaced-none,
.u-spaced-none-below {
  margin-bottom: 0 !important;
}
