// @file
// Styles for a pager.

$bp-pager-ellipsis: 800px !default;
$bp-pager: 600px !default;

.c-pager__items {
  @include list-clean();
  clear: both;
  padding: 0;
  text-align: center;
}

.c-pager__item {
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: top;

  &:not([class*='pager__item--']) {
    display: none;

    @include breakpoint($bp-pager) {
      display: inline-block;
    }
  }
}

%pager__link {
  background-color: transparent;
  border-radius: 50%;
  color: gesso-brand(navy, base);
  display: inline-block;
  min-width: rem(gesso-spacing(lg));
  padding: em(gesso-spacing(xs));
  transition-duration: gesso-duration(short);
  transition-property: background, color;
  transition-timing-function: gesso-easing(ease-in);

  &:visited {
    color: gesso-brand(navy, base);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: gesso-brand(magenta, base);
    color: gesso-grayscale(white);
  }
}

.c-pager__item--ellipsis {
  @extend %pager__link;
  display: none;

  @include breakpoint($bp-pager-ellipsis) {
    display: inline-block;
  }
}

.c-pager__item--current {
  @extend %pager__link;
  background-color: gesso-brand(maroon, base);
  color: gesso-grayscale(white);
  padding-left: em(gesso-spacing(xs)) !important;

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: gesso-grayscale(white);
  }
}

.c-pager__link {
  @extend %pager__link;
  text-decoration: none;
}

.c-pager__link--previous,
.c-pager__link--next {
  background-color: transparent;
  position: relative;

  &:hover,
  &:focus {
    background-color: transparent;
    color: gesso-brand(navy, base);
    text-decoration: underline;
  }
}
