.c-applied-facets {
  @include list-clean;
  margin-bottom: gesso-spacing(md);

  a {
    color: gesso-grayscale(gray-5);
    font-size: gesso-font-size(1);
    text-decoration: none;

    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: gesso-grayscale(gray-6);
    }
  }
}

.c-applied-facets__clear {
  font-size: gesso-font-size(0);
}

.c-applied-facets__facet {
  @include svg-background(mobile-close);
  background-position: right center;
  background-repeat: no-repeat;
  // using pixle values to fix icon cropping issue in IE
  background-size: 14px 14px;
  padding-right: rem(22px);
}
