// @file
// Styles for the search bar.

.c-search-bar {
  background-color: gesso-brand(maroon, base);
  color: gesso-grayscale(white);
  padding: rem(gesso-spacing(md)) 0;
}

.c-search-bar__content {
  align-items: center;
  display: flex;
  justify-content: center;

  > * {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.c-search-bar__button {
  @include focus(gesso-color(ui, generic, focus-light));
  align-items: center;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: gesso-font-family(primary);
  height: rem(44px);
  justify-content: center;
  margin-left: rem(gesso-spacing(xs));
  padding: 0;
  transition-duration: gesso-duration(short);
  transition-property: background, color;
  transition-timing-function: gesso-easing(ease-in);
  width: rem(44px);

  &:hover,
  &:focus {
    background-color: gesso-brand(magenta, base);
  }
}

.c-search-bar__button-icon {
  @include image-replace(rem(8px), rem(14px));
  @include svg-background-inline(search-arrow);
  background-position: 50%;
  background-repeat: no-repeat;
}

.c-search-bar--mobile {
  margin-bottom: rem(gesso-spacing(md));
  margin-left: rem(gesso-spacing(sm));
}

// Hide search bar on load if JS is enabled so there’s no ghosting.
.js .js-search-block {
  display: none;
}
