// @file
// Styles for Article.

$bp-article-layout: 800px;

.c-article {
  padding-bottom: rem(gesso-spacing(lg));

  h2,
  h3,
  h4 {
    margin-bottom: rem(gesso-spacing(sm));
  }
}

.c-article__content {
  padding-top: rem(gesso-spacing(lg));

  > *:only-child {
    /* stylelint-disable */
    -ms-grid-column: span 2;
    /* stylelint-enable */
    grid-column: span 2;
  }

  @include breakpoint($bp-article-layout) {
    /* stylelint-disable */
    display: -ms-grid;
    /* stylelint-enable */
    display: grid;
    //grid-gap: rem(50px);

    /* stylelint-disable */
    -ms-grid-columns: 1fr 250px;
    /* stylelint-enable */
    grid-template-columns: 1fr 250px;
    padding-top: rem(gesso-spacing(2xl));
  }

  @media print {
    display: block;
    grid-gap: rem(gesso-spacing(sm));

    /* stylelint-disable */
    -ms-grid-columns: 1fr;
    /* stylelint-enable */
    grid-template-columns: 1fr;
    padding-top: rem(gesso-spacing(sm));
  }
}

.c-article__footer {
  padding-top: rem(40px);
  /* stylelint-disable */
  -ms-grid-column: 2;
  /* stylelint-enable */

  @media print {
    order: -1;
  }
}

.c-article__main {
  /* stylelint-disable */
  -ms-grid-column: 1;
  /* stylelint-enable */
  @include breakpoint($bp-article-layout) {
    margin-right: 50px;
  }
  order: -1;

  @media print {
    order: 1;
  }
}

.c-article__status {
  color: gesso-brand(maroon, light);
  font-size: rem(gesso-font-size(5));
  font-weight: gesso-font-weight(bold);
  line-height: gesso-line-height(tight);
  margin-bottom: rem(gesso-spacing(md));
}

.c-article__sidebar-title {
  color: gesso-brand(maroon, base);
  font-weight: 600;
  margin-bottom: rem(gesso-spacing(md));
}

// Sidebar sliding section
.sliding-section {
  border-bottom: 1px solid rgba(gesso-brand(navy, base), 0.2);
  padding-bottom: gesso-spacing(xs);

  .c-list {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  .section-control {
    cursor: pointer;
    padding-right: gesso-spacing(md);
    position: relative;

    &::after {
      @include accordion-icon-is-open();
    }
  }

  &.closed {
    .section-control {
      margin-bottom: 0;
      transition: margin-bottom 0.3s;

      &::after {
        @include accordion-icon-is-closed();
      }
    }
  }
}
