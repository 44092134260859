// @file
// Styles for a menu.

.c-menu {
  @include list-clean;

  @media print {
    display: none;
  }
}

.c-menu__item {
  margin-bottom: 0;
}
