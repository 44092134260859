article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
pre,
samp {
  font-family: Consolas, "Lucida Console", "Liberation Mono", "Courier New", monospace, sans-serif;
  font-size: 1em; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

audio,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button::-moz-focusring,
[type='button']::-moz-focusring,
[type='reset']::-moz-focusring,
[type='submit']::-moz-focusring {
  outline: 1px dotted ButtonText; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

template,
[hidden] {
  display: none; }

abbr,
blockquote,
code,
kbd,
q,
samp,
tt,
var {
  hyphens: none; }

[tabindex='-1']:focus {
  outline: none !important; }

/* stylelint-disable-next-line selector-no-vendor-prefix */
::-moz-selection {
  background: #0058a4;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #0058a4;
  color: #fff;
  text-shadow: none; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@media print {
  * {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important; }
  @page {
    margin: 2cm; } }

html {
  box-sizing: border-box;
  font-family: Source Sans Pro, sans-serif;
  font-size: 112.5%;
  line-height: 1.5;
  min-height: 100%;
  text-size-adjust: 100%; }

body {
  background-color: #fbfbfb;
  color: #002d55;
  margin: 0;
  padding: 0;
  word-wrap: break-word; }
  body.has-open-mobile-menu {
    overflow: hidden;
    -webkit-overflow-scrolling: touch; }

@media print {
  nav {
    display: none; } }

summary {
  display: list-item; }

iframe {
  border: 0; }
  @media print {
    iframe {
      display: none; } }

h1, .c-banner__title, .c-newsletter-signup--banner .c-newsletter-signup__header, h2, h3, h4, .c-card__title, h5, .c-newsletter-signup__header, h6, .c-accordion__title, .c-accordion__button, .c-hero--homepage .c-hero__title {
  color: inherit;
  font-weight: 700;
  hyphens: none;
  line-height: 1.5;
  margin: 0 0 1.3333333333rem;
  text-rendering: optimizeLegibility; }
  @media print {
    h1, .c-banner__title, .c-newsletter-signup--banner .c-newsletter-signup__header, h2, h3, h4, .c-card__title, h5, .c-newsletter-signup__header, h6, .c-accordion__title, .c-accordion__button, .c-hero--homepage .c-hero__title {
      orphans: 3;
      page-break-after: avoid;
      widows: 3; }
      h1::after, .c-banner__title::after, .c-newsletter-signup--banner .c-newsletter-signup__header::after, h2::after, h3::after, h4::after, .c-card__title::after, h5::after, .c-newsletter-signup__header::after, h6::after, .c-accordion__title::after, .c-accordion__button::after, .c-hero--homepage .c-hero__title::after {
        display: inline-block; } }
  * + h1, * + .c-banner__title, .c-newsletter-signup--banner * + .c-newsletter-signup__header, * + h2, * + h3, * + h4, * + .c-card__title, * + h5, * + .c-newsletter-signup__header, * + h6, * + .c-accordion__title, * + .c-accordion__button, .c-hero--homepage * + .c-hero__title {
    margin-top: 1em; }

h1,
.c-banner__title,
.c-newsletter-signup--banner .c-newsletter-signup__header {
  color: #002d55;
  font-family: Oswald, sans-serif;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2; }
  @media (max-width: 43.75em) {
    h1,
    .c-banner__title,
    .c-newsletter-signup--banner .c-newsletter-signup__header {
      font-size: 1.7777777778rem;
      font-size: calc(1.4444444444rem + 3.1428571429vw); } }

h2 {
  color: #002d55;
  font-family: Oswald, sans-serif;
  font-size: 2.2222222222rem;
  font-weight: 500;
  line-height: 1.2; }
  @media (max-width: 43.75em) {
    h2 {
      font-size: 1.5555555556rem;
      font-size: calc(1.2222222222rem + 2.5714285714vw); } }

h3 {
  color: #002d55;
  font-family: Oswald, sans-serif;
  font-size: 1.7777777778rem;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 43.75em) {
    h3 {
      font-size: 1.3333333333rem;
      font-size: calc(1rem + 2vw); } }

h4,
.c-card__title {
  color: #002d55;
  font-family: Oswald, sans-serif;
  font-size: 1.5555555556rem;
  font-weight: 500;
  line-height: 1.3; }
  @media (max-width: 43.75em) {
    h4,
    .c-card__title {
      font-size: 1.2222222222rem;
      font-size: calc(0.8888888889rem + 1.7142857143vw); } }

h5,
.c-newsletter-signup__header {
  color: #002d55;
  font-family: Oswald, sans-serif;
  font-size: 1.2222222222rem;
  font-weight: 500;
  line-height: 1.1; }
  @media (max-width: 43.75em) {
    h5,
    .c-newsletter-signup__header {
      font-size: 1.1111111111rem;
      font-size: calc(0.7777777778rem + 1.1428571429vw); } }

h6,
.c-accordion__title,
.c-accordion__button {
  color: #002d55;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 1.1; }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #002d55; }
  h1 a:hover, h1 a:focus,
  h2 a:hover,
  h2 a:focus,
  h3 a:hover,
  h3 a:focus,
  h4 a:hover,
  h4 a:focus,
  h5 a:hover,
  h5 a:focus,
  h6 a:hover,
  h6 a:focus {
    color: #63203c; }

h1 + h1,
h2 + h1,
h3 + h1,
h4 + h1,
h5 + h1,
h6 + h1, h1 +
h2,
h2 +
h2,
h3 +
h2,
h4 +
h2,
h5 +
h2,
h6 +
h2, h1 +
h3,
h2 +
h3,
h3 +
h3,
h4 +
h3,
h5 +
h3,
h6 +
h3, h1 +
h4,
h2 +
h4,
h3 +
h4,
h4 +
h4,
h5 +
h4,
h6 +
h4, h1 +
h5,
h2 +
h5,
h3 +
h5,
h4 +
h5,
h5 +
h5,
h6 +
h5, h1 +
h6,
h2 +
h6,
h3 +
h6,
h4 +
h6,
h5 +
h6,
h6 +
h6 {
  margin-top: 0; }

p {
  font-size: 1.2222222222rem;
  line-height: 1.5;
  margin: 0 0 1.3333333333rem; }
  @media print {
    p {
      orphans: 3;
      widows: 3; } }

a {
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: transparent;
  color: #902f57;
  -webkit-text-decoration-skip: objects;
  transition-duration: 200ms;
  transition-property: background, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  a:focus {
    outline-color: #006ece; }
  a:hover, a:focus {
    color: #63203c; }
  a:active {
    color: #a91552; }
  a:visited {
    color: #63203c; }
  a[href^="https://"]:not([href*='leverforchange'])::after, a[href^="http://"]:not([href*='leverforchange'])::after {
    background-color: #fff;
    background-image: url("../images/external-link--grey.svg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 25px;
    margin-bottom: -10px;
    margin-left: 0.3rem;
    width: 15px; }
  @media print {
    a, a:visited {
      text-decoration: none; }
    a[href]::after {
      content: " <" attr(href) ">";
      font-family: Source Sans Pro, sans-serif;
      font-size: 10pt;
      font-weight: normal;
      text-transform: lowercase; }
    a[href^='javascript:']::after, a[href^='mailto:']::after, a[href^='tel:']::after, a[href^='#']::after, a[href*='?']::after {
      content: ''; } }

.c-article__main a,
.c-field a {
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: transparent;
  box-shadow: inset 0 -0.1rem 0 0 #902f57;
  color: #002d55;
  text-decoration: none;
  text-decoration-skip: objects;
  transition: box-shadow 430ms cubic-bezier(0.55, 0.085, 0, 0.99); }
  .c-article__main a:focus,
  .c-field a:focus {
    outline-color: #006ece; }
  .c-article__main a:hover, .c-article__main a:focus,
  .c-field a:hover,
  .c-field a:focus {
    box-shadow: inset 0 -0.75rem 0 0 rgba(144, 47, 87, 0.3);
    transition: box-shadow 430ms cubic-bezier(0.55, 0.085, 0, 0.99); }

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted; }

abbr:hover {
  cursor: help; }

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")"; } }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #212121; }

small {
  font-size: 75%; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

blockquote {
  color: #002d55;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.7777777778rem;
  font-weight: 300;
  line-height: 1.5;
  border-bottom: 1px solid #c5c8ce;
  margin: 3.1111111111rem 0 1.3333333333rem 0;
  padding: 2.2222222222rem 10%;
  position: relative;
  text-align: center; }
  @media (max-width: 43.75em) {
    blockquote {
      font-size: 1.3333333333rem;
      font-size: calc(1rem + 2vw); } }
  @media (min-width: 31.25em) {
    blockquote {
      margin-bottom: 5.3333333333rem; }
      * + blockquote {
        margin-top: 1.7777777778rem; } }
  blockquote::before {
    background: #002d55;
    background: linear-gradient(90deg, #002d55 0%, #0058a4 50%, #902f57 100%);
    content: '';
    display: block;
    height: 0.4444444444rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  blockquote p {
    color: #902f57;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit; }
    blockquote p::before {
      content: '\201C'; }
    blockquote p::after {
      content: '\201D'; }
  blockquote cite {
    color: #515760;
    font-family: Source Sans Pro, sans-serif;
    font-size: 0.8888888889rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 1.1;
    display: block;
    margin: 1.3333333333rem 0; }
  blockquote :last-child {
    margin-bottom: 0; }
  @media print {
    blockquote {
      page-break-inside: avoid; } }

pre {
  margin: 0 0 1.3333333333rem;
  overflow: auto; }
  @media print {
    pre {
      page-break-inside: avoid; } }

hr {
  border: 1px solid #959ba5;
  box-sizing: content-box;
  height: 0;
  margin: 1.3333333333rem 0;
  padding-bottom: -1px; }

ul {
  list-style-type: disc;
  margin: 0 0 1.3333333333rem;
  padding: 0 0 0 1.3333333333rem; }
  [dir='rtl'] ul {
    padding: 0 1.3333333333rem 0 0; }
  ul ol,
  ul ul {
    margin: 0.4444444444rem 0 0; }

ol {
  margin: 0 0 1.3333333333rem;
  padding: 0 0 0 1.3333333333rem; }
  [dir='rtl'] ol {
    padding: 0 1.3333333333rem 0 0; }
  ol ol,
  ol ul {
    margin: 0.4444444444rem 0 0; }

li {
  margin-bottom: 0.4444444444rem;
  padding-left: 0.4444444444rem; }
  @media print {
    li {
      page-break-inside: avoid; } }

dd {
  margin-bottom: 0.4444444444rem; }

dl {
  margin: 0 0 0.4444444444rem; }

caption {
  margin-bottom: 0.2222222222rem; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1.3333333333rem;
  min-width: 100%; }

td {
  background-color: #fff;
  border: 1px solid #515760;
  padding: 0.8888888889rem; }

tfoot td,
tfoot th {
  background-color: #f0f0f2; }

th {
  background-color: #fff;
  border: 1px solid #515760;
  padding: 0.8888888889rem;
  text-align: left; }

thead td,
thead th {
  background-color: #f0f0f2; }

@media print {
  thead {
    display: table-header-group; } }

@media print {
  tr {
    page-break-inside: avoid; } }

button {
  overflow: visible; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

input {
  line-height: normal; }

legend {
  border: 0;
  box-sizing: border-box;
  color: inherit;
  display: table;
  margin: 0;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

optgroup {
  font-weight: 700; }

textarea {
  overflow: auto; }

audio:not([controls]) {
  display: none;
  height: 0; }

canvas {
  display: inline-block; }

figure {
  margin: 0 0 0.8888888889rem; }

img {
  border: 0;
  font-style: italic;
  height: auto;
  max-width: 100%;
  vertical-align: middle; }
  @media print {
    img {
      max-width: 100% !important;
      page-break-inside: avoid; } }

svg:not(:root) {
  overflow: hidden; }

.c-breadcrumb__title, .c-form-item__checkbox {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.c-search-forms .c-form-item__label, .c-slider__navigation .slick-arrow span {
  display: none; }

.l-constrain {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.6666666667rem;
  padding-left: 16px;
  padding-right: 16px; }

.l-constrain--large {
  max-width: 77.7777777778rem; }

.l-constrain--no-padding {
  padding-left: 0;
  padding-right: 0; }

.l-constrain--small {
  max-width: 55.5555555556rem; }

.l-constrain--xsmall {
  max-width: 45.8333333333rem; }

.l-flex {
  display: flex; }

@media (min-width: 53.75em) {
  .l-footer__main {
    align-items: flex-end;
    display: grid;
    grid-template-columns: 1fr 2fr; } }

.l-footer__site-name {
  padding-top: 2.4444444444rem; }
  .l-footer__site-name h3 a {
    color: #002d55;
    margin-bottom: 0;
    text-decoration: none; }

@media (min-width: 53.75em) {
  .l-footer__menu {
    padding-top: 1.3333333333rem;
    text-align: right; } }

@media (min-width: 53.75em) {
  .l-footer__legal {
    align-items: baseline;
    display: flex;
    justify-content: flex-end; } }

.l-footer__legal > * {
  margin-right: 1.3333333333rem; }
  .l-footer__legal > * :last-child {
    margin-right: 0; }

@media print {
  .l-footer__legal {
    justify-content: flex-start; } }

.l-footer__affiliated {
  border-top: 1px solid #d8d8d8;
  padding: 1.3333333333em 0; }

.l-grid {
  display: flex;
  flex-wrap: wrap; }
  .l-grid.has-gutters {
    margin: 0 -0.8888888889rem; }
    .l-grid.has-gutters > * {
      margin-left: 0.8888888889rem;
      margin-right: 0.8888888889rem; }

.l-grid--2col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px; }
  .l-grid--2col > * {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: calc((100%) - 32px);
    min-width: 0; }
  @supports (display: grid) {
    .l-grid--2col {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid--2col > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }
  @media (min-width: 37.5em) {
    .l-grid--2col > * {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 32px;
      margin-left: 16px;
      margin-right: 16px;
      width: calc((50%) - 32px);
      min-width: 275px; }
    @supports (display: grid) {
      .l-grid--2col {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px; }
        .l-grid--2col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }

.l-grid--3col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  justify-content: center; }
  .l-grid--3col > * {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: calc((100%) - 32px);
    min-width: 0; }
  @supports (display: grid) {
    .l-grid--3col {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid--3col > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }
  @media (min-width: 47.5em) {
    .l-grid--3col > * {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 32px;
      margin-left: 16px;
      margin-right: 16px;
      width: calc((33.3333333333%) - 32px);
      min-width: 275px; }
    @supports (display: grid) {
      .l-grid--3col {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px; }
        .l-grid--3col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }

.l-grid--4col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  align-items: center;
  justify-content: center; }
  .l-grid--4col > * {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: calc((100%) - 32px);
    min-width: 0; }
  @media (min-width: 37.5em) and (max-width: 50em) {
    .l-grid--4col > * {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 32px;
      margin-left: 16px;
      margin-right: 16px;
      width: calc((50%) - 32px);
      min-width: 275px; } }
  @media (min-width: 50.0625em) {
    .l-grid--4col > * {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 32px;
      margin-left: 16px;
      margin-right: 16px;
      width: calc((25%) - 32px);
      min-width: 0; } }

.l-listing {
  margin-bottom: 1.7777777778rem;
  padding-top: 5.3333333333rem; }
  @media (min-width: 50em) {
    .l-listing {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5.3333333333rem; } }

@media (min-width: 50em) {
  .l-listing__facets {
    width: 23%;
    width: calc(25% - 0.8888888889rem); } }

.l-listing__sort {
  text-align: right; }
  .l-listing__sort .c-form-item__label {
    display: inline; }

@media (min-width: 50em) {
  .l-listing__content {
    width: 73%;
    width: calc(75% - 0.8888888889rem); } }

.l-listing__items {
  margin-bottom: 1.3333333333rem; }

.l-masthead {
  background: #63203c;
  background: linear-gradient(90deg, #63203c 0%, #902f57 50%, #a91552 100%);
  position: relative; }
  @media (min-width: 0) and (max-width: 50em) {
    .l-masthead .l-constrain {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 50em) {
    .l-masthead {
      padding-top: 0.8888888889rem; } }
  @media print {
    .l-masthead {
      background-color: transparent; } }

.l-masthead__content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 50em) {
    .l-masthead__content {
      align-items: flex-end; } }
  @media (min-width: 68.75em) {
    .l-masthead__content {
      padding-top: 15px; } }

.l-masthead__footer {
  display: none; }
  @media (min-width: 50em) {
    .l-masthead__footer {
      border-top: 1px solid #d8d8d8;
      display: block;
      padding-top: 1.3333333333rem; } }
  @media print {
    .l-masthead__footer {
      display: none; } }

.c-accordion {
  border-bottom: 1px solid #c5c8ce;
  margin-bottom: 1.3333333333rem; }
  .c-accordion .c-accordion__icon {
    display: none;
    top: -0.0555555556rem; }

.c-accordion__title {
  background-color: #fff;
  border-top: 1px solid #c5c8ce;
  color: #002d55;
  margin: 0; }
  .no-js .c-accordion__title {
    padding: 0.8888888889rem; }

.c-accordion__button {
  outline: 2px solid transparent;
  outline-offset: 2px;
  align-items: center;
  background: transparent;
  border: 0;
  color: inherit;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0.8888888889rem 0.4444444444rem;
  position: relative;
  text-align: left;
  width: 100%; }
  .c-accordion__button:focus {
    outline-color: #006ece; }
  .c-accordion__button:hover, .c-accordion__button:focus {
    background-color: #f0f0f2; }
  .c-accordion__button > * {
    flex-grow: 1; }
  .c-accordion__button[aria-expanded='false']::after {
    color: #a91552;
    content: '+';
    display: block;
    font-size: 1.7777777778rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .c-accordion__button[aria-expanded='true']::after {
    color: #a91552;
    content: '-';
    display: block;
    font-size: 2.6666666667rem;
    position: absolute;
    right: 0;
    top: 30%;
    transform: translateY(-50%);
    top: 40%; }

.c-accordion__content {
  padding: 0.8888888889rem 0.4444444444rem 1.3333333333rem; }
  .c-accordion__content > :last-child {
    margin-bottom: 0; }

.c-applied-facets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 24px; }
  .c-applied-facets li {
    padding-left: 0; }
    .c-applied-facets li::before {
      display: none; }
  .c-applied-facets a {
    color: #515760;
    font-size: 14px;
    text-decoration: none; }
    .c-applied-facets a:visited, .c-applied-facets a:hover, .c-applied-facets a:focus, .c-applied-facets a:active {
      color: #32353b; }

.c-applied-facets__clear {
  font-size: 12px; }

.c-applied-facets__facet {
  background-image: url("../images/mobile-close.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  padding-right: 1.2222222222rem; }

.c-article {
  padding-bottom: 1.7777777778rem; }
  .c-article h2,
  .c-article h3,
  .c-article h4 {
    margin-bottom: 0.8888888889rem; }

.c-article__content {
  padding-top: 1.7777777778rem; }
  .c-article__content > *:only-child {
    /* stylelint-disable */
    -ms-grid-column: span 2;
    /* stylelint-enable */
    grid-column: span 2; }
  @media (min-width: 50em) {
    .c-article__content {
      /* stylelint-disable */
      display: -ms-grid;
      /* stylelint-enable */
      display: grid;
      /* stylelint-disable */
      -ms-grid-columns: 1fr 250px;
      /* stylelint-enable */
      grid-template-columns: 1fr 250px;
      padding-top: 3.5555555556rem; } }
  @media print {
    .c-article__content {
      display: block;
      grid-gap: 0.8888888889rem;
      /* stylelint-disable */
      -ms-grid-columns: 1fr;
      /* stylelint-enable */
      grid-template-columns: 1fr;
      padding-top: 0.8888888889rem; } }

.c-article__footer {
  padding-top: 2.2222222222rem;
  /* stylelint-disable */
  -ms-grid-column: 2;
  /* stylelint-enable */ }
  @media print {
    .c-article__footer {
      order: -1; } }

.c-article__main {
  /* stylelint-disable */
  -ms-grid-column: 1;
  /* stylelint-enable */
  order: -1; }
  @media (min-width: 50em) {
    .c-article__main {
      margin-right: 50px; } }
  @media print {
    .c-article__main {
      order: 1; } }

.c-article__status {
  color: #a91552;
  font-size: 1.2222222222rem;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 1.3333333333rem; }

.c-article__sidebar-title {
  color: #902f57;
  font-weight: 600;
  margin-bottom: 1.3333333333rem; }

.sliding-section {
  border-bottom: 1px solid rgba(0, 45, 85, 0.2);
  padding-bottom: 8px; }
  .sliding-section .c-list {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out; }
  .sliding-section .section-control {
    cursor: pointer;
    padding-right: 24px;
    position: relative; }
    .sliding-section .section-control::after {
      color: #a91552;
      content: '-';
      display: block;
      font-size: 2.6666666667rem;
      position: absolute;
      right: 0;
      top: 30%;
      transform: translateY(-50%); }
  .sliding-section.closed .section-control {
    margin-bottom: 0;
    transition: margin-bottom 0.3s; }
    .sliding-section.closed .section-control::after {
      color: #a91552;
      content: '+';
      display: block;
      font-size: 1.7777777778rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }

.c-article--image-hero .c-article__title {
  margin-top: 0; }

.c-banner {
  background-color: #f0f0f2;
  overflow-x: hidden;
  padding: 3.5555555556rem 0; }
  .c-banner > * {
    margin-bottom: 0; }
  .c-banner.is-centered {
    text-align: center; }
  .c-banner.no-spacing--bottom {
    padding-bottom: 0; }

.c-banner--half-padding {
  padding: 1.7777777778rem 0 1.3333333333rem 0; }

@media (min-width: 53.75em) {
  .c-banner--featured-col .c-banner__content,
  .has-image .c-banner__content {
    align-items: center;
    /* stylelint-disable */
    display: -ms-grid;
    /* stylelint-enable */
    display: grid;
    grid-gap: 2rem;
    /* stylelint-disable */
    -ms-grid-columns: 1fr;
    /* stylelint-enable */
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr); } }

@media (min-width: 53.75em) {
  .has-image .c-banner__content {
    grid-gap: 5.5555555556rem;
    /* stylelint-disable */
    -ms-grid-columns: 1fr 450px;
    /* stylelint-enable */
    grid-template-columns: 1fr 25rem; } }

@media (min-width: 53.75em) {
  .c-banner--featured-col .c-banner__content {
    /* stylelint-disable */
    -ms-grid-columns: 1fr 2fr;
    /* stylelint-enable */
    grid-template-columns: 1fr 2fr; } }

.c-banner--featured-col .c-banner__content .c-banner__title {
  margin-bottom: 24px; }
  @media (min-width: 53.75em) {
    .c-banner--featured-col .c-banner__content .c-banner__title:only-child {
      margin-bottom: 0; } }

.c-banner__title {
  color: inherit; }
  .c-banner__title.is-centered {
    margin: 0 auto;
    text-align: center; }

.c-banner__summary {
  margin-bottom: 0.8888888889rem; }

.c-banner__button > * {
  margin-bottom: 0 !important; }

.c-banner__text {
  /* stylelint-disable */
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  /* stylelint-enable */ }

.c-banner__image {
  /* stylelint-disable */
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  /* stylelint-enable */
  order: -1;
  position: relative; }
  @media (min-width: 28.125em) and (max-width: 53.75em) {
    .c-banner__image {
      margin: 0 auto;
      max-width: 80%; } }
  @media (min-width: 53.75em) {
    .c-banner__image {
      /* stylelint-disable */
      -ms-grid-column: 2;
      /* stylelint-enable */
      order: 2; } }
  .c-banner__image img {
    border-radius: 50%;
    position: relative;
    z-index: 4; }
  .c-banner--image-no-accents .c-banner__image img {
    border-radius: 0; }
  .c-banner--image-no-accents .c-banner__image::before, .c-banner--image-no-accents .c-banner__image::after {
    display: none; }

.c-banner--map {
  padding-bottom: 0;
  padding-top: 0; }
  @media (min-width: 53.75em) {
    .c-banner--map {
      /*margin-bottom: -150px;
    margin-top: -200px;*/ } }
  @media (min-width: 53.75em) {
    .c-banner--map .c-banner__content {
      align-items: center;
      /* stylelint-disable */
      -ms-grid-columns: 1fr 400px;
      /* stylelint-enable */
      grid-template-columns: 1fr 400px; } }
  @media (min-width: 58.125em) {
    .c-banner--map .c-banner__content {
      /* stylelint-disable */
      -ms-grid-columns: 1fr 600px;
      /* stylelint-enable */
      grid-template-columns: 1fr 600px; } }
  @media (min-width: 70.625em) {
    .c-banner--map .c-banner__content {
      /* stylelint-disable */
      -ms-grid-columns: 1fr 800px;
      /* stylelint-enable */
      grid-template-columns: 1fr 800px; } }
  .c-banner--map .c-banner__text {
    margin-top: 2rem;
    /* stylelint-disable */
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    /* stylelint-enable */ }
    @media (min-width: 53.75em) {
      .c-banner--map .c-banner__text {
        margin-right: 50px; } }
    @media (min-width: 58.125em) {
      .c-banner--map .c-banner__text {
        margin-top: 0; } }
  .c-banner--map .c-banner__image {
    /* stylelint-disable */
    -ms-grid-column: 2;
    /* stylelint-enable */
    margin: 0;
    max-width: 100%; }
    .c-banner--map .c-banner__image video {
      max-width: 100%;
      object-fit: contain; }
      @media (min-width: 0) and (max-width: 58.125em) {
        .c-banner--map .c-banner__image video {
          max-height: 400px;
          /*margin-bottom: -200px;
        margin-top: -300px;*/ } }

.c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'] {
  opacity: 0;
  transition-property: transform, opacity; }
  .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate {
    opacity: 1; }
    .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::before, .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::after {
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute; }
    .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::before {
      background-image: url("../images/arrow-right--blue.svg");
      animation-duration: 1.25s;
      animation-name: sneak;
      background-size: contain;
      height: 3.8888888889rem;
      left: -3.6111111111rem;
      top: 0;
      transition: all 0.5s ease;
      width: 3.3333333333rem;
      z-index: 5; }
      @media (min-width: 31.25em) {
        .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::before {
          height: 11.7777777778rem;
          width: 10.0555555556rem; } }
    .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::after {
      animation-duration: 2.25s;
      animation-name: sneak;
      background-image: url("../images/triangle-trio-light-blue.png");
      background-size: contain;
      bottom: -3.3333333333rem;
      height: 3.3333333333rem;
      right: -1.1111111111rem;
      transition: all 1.5s ease;
      width: 3.8888888889rem;
      z-index: 2; }
      @media (min-width: 0) and (max-width: 31.25em) {
        .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::after {
          height: 3.3333333333rem;
          width: 3.8888888889rem; } }
      @media (min-width: 31.25em) {
        .c-banner.has-image:not(.c-banner--image-no-accents) [data-aos='arrow-animation'].aos-animate::after {
          bottom: 1.1111111111rem;
          height: 6.9444444444rem;
          right: -2.7777777778rem;
          width: 8.3333333333rem; } }

@keyframes flight {
  0% {
    left: -150px;
    top: -20px;
    transform: rotate(90deg); }
  50% {
    left: -80px;
    top: 40px;
    transform: rotate(45deg); }
  100% {
    left: 0;
    top: 0;
    transform: rotate(0); } }

@keyframes sneaky {
  0% {
    transform: translateX(-100px); }
  100% {
    transform: translateX(0); } }

.c-banner--blue {
  background: #0058a4;
  background: linear-gradient(270deg, #0058a4 0%, #00427c 50%, #002d55 100%);
  color: #fff; }

.c-banner--2col .c-banner__content {
  align-items: center;
  display: grid;
  /* stylelint-disable */
  -ms-grid-columns: 1fr;
  /* stylelint-enable */
  grid-template-columns: 1fr; }
  @media (min-width: 50em) {
    .c-banner--2col .c-banner__content {
      /* stylelint-disable */
      -ms-grid-columns: 1fr 1fr;
      /* stylelint-enable */
      grid-template-columns: 1fr 1fr; } }

.c-banner--2col.c-banner--top-align .c-banner__content {
  align-items: flex-start; }

@media (min-width: 53.75em) {
  .c-banner--2col.c-banner--featured-col .c-banner__content {
    /* stylelint-disable */
    -ms-grid-columns: 1fr 2fr;
    /* stylelint-enable */
    grid-template-columns: 1fr 2fr; } }

.c-banner--2col .c-banner__title {
  /* stylelint-disable */
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  /* stylelint-enable */ }
  @media (min-width: 50em) {
    .c-banner--2col .c-banner__title {
      margin-right: 5rem; } }

.c-banner--2col .c-banner__col2 {
  /* stylelint-disable */
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  /* stylelint-enable */ }

.c-banner--2col .c-banner__col2-title {
  color: #fff;
  font-weight: 500;
  margin-bottom: 16px; }

.c-banner--2col .c-banner__col2-title--small p {
  font-size: 18px; }

.c-banner--2col .c-banner__col2-title--small a,
.c-banner--2col .c-banner__col2-title--small a:visited {
  color: #fff; }

.c-banner--2col .c-banner__col2-title--small + .c-search-forms {
  margin-bottom: 24px; }
  @media (min-width: 53.75em) {
    .c-banner--2col .c-banner__col2-title--small + .c-search-forms {
      margin-bottom: 0; } }

.c-banner--gradient-text {
  background-color: #fff; }
  .c-banner--gradient-text .c-banner__title {
    background: #00427c;
    background: linear-gradient(90deg, #00427c 0%, #902f57 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: #fff;
    line-height: 1.3;
    -webkit-text-fill-color: transparent; }

.c-banner--maroon-blue {
  background: #002d55;
  background: linear-gradient(270deg, #002d55 0%, #902f57 100%);
  color: #fff; }

.c-banner--maroon {
  background: #63203c;
  background: linear-gradient(90deg, #63203c 0%, #902f57 50%, #a91552 100%);
  color: #fff; }

.c-banner--white {
  background-color: #fff; }

@media (min-width: 50em) {
  .c-billboard {
    align-items: center;
    display: flex;
    justify-content: space-between; } }

.c-billboard__media {
  position: relative; }
  .c-billboard__media::before {
    background-image: url("../images/billboard-before.png");
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 10.1111111111rem;
    margin: 0 0 -2.3888888889rem 6.5rem;
    width: 12.1111111111rem; }
  .c-billboard__media::after {
    background-image: url("../images/billboard-after.png");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 10.3888888889rem;
    margin-top: -6.5555555556rem;
    position: relative;
    right: 1.1666666667rem;
    width: 100%; }
  @media (min-width: 50em) {
    .c-billboard__media {
      width: 48%;
      width: calc(50% - 0.8888888889rem); } }

.c-billboard__wrapper {
  content: ''; }

.c-billboard__image {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }

@media (min-width: 50em) {
  .c-billboard__content {
    width: 48%;
    width: calc(50% - 0.8888888889rem); } }

.c-billboard--alt-1 .c-billboard__media::before {
  background-image: url("../images/billboard-alt-1-before.png"); }

.c-billboard--alt-1 .c-billboard__media::after {
  background-image: url("../images/billboard-alt-1-after.png"); }

.c-billboard--alt-2 .c-billboard__media::before {
  background-image: url("../images/billboard-alt-2-before.png"); }

.c-billboard--alt-2 .c-billboard__media::after {
  background-image: url("../images/billboard-alt-2-after.png"); }

@media (min-width: 50em) {
  .c-billboard.is-flipped .c-billboard__media {
    order: 2; } }

.c-breadcrumb {
  background-color: #f0f0f2;
  color: #515760;
  font-size: 0.7777777778rem;
  padding: 1.6111111111rem 0; }
  .c-breadcrumb::after {
    clear: both;
    content: "";
    display: table; }

.c-breadcrumb__list {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.6666666667rem;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  max-width: 55.5555555556rem;
  padding: 0; }
  @media (min-width: 68.75em) {
    .c-breadcrumb__list {
      margin-left: auto;
      margin-right: auto;
      max-width: 66.6666666667rem;
      padding-left: 16px;
      padding-right: 16px;
      max-width: 55.5555555556rem; } }

.c-breadcrumb__item {
  display: inline-block;
  margin: 0.1666666667rem 0.1666666667rem 0.1666666667rem 0;
  padding: 0; }
  .c-breadcrumb__item::after {
    content: '/';
    margin-left: 0.1666666667rem; }
  .c-breadcrumb__item:last-child::after {
    content: '';
    margin-left: 0; }

.c-breadcrumb__link {
  color: #002d55;
  text-decoration: none; }
  .c-breadcrumb__link:visited {
    color: #002d55; }
  .c-breadcrumb__link:hover {
    text-decoration: underline; }
  .c-breadcrumb__link:hover, .c-breadcrumb__link:focus {
    color: #902f57; }

@media (max-width: 31.25em) {
  .c-breadcrumb .c-breadcrumb__item {
    display: none; }
    .c-breadcrumb .c-breadcrumb__item:nth-last-child(2) {
      display: block; }
      .c-breadcrumb .c-breadcrumb__item:nth-last-child(2)::after {
        display: none; }
      .c-breadcrumb .c-breadcrumb__item:nth-last-child(2)::before {
        content: '<';
        margin-right: 0.1666666667rem; } }

.c-button {
  background-color: transparent;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Source Sans Pro, sans-serif;
  font-size: 0.8888888889rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1.125;
  margin: 0 0 0.4444444444rem;
  padding: 0.875em 1.5625em 0.75em;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background 200ms cubic-bezier(0.4, 0, 1, 1);
  vertical-align: top;
  white-space: normal;
  z-index: 0; }
  .c-button::before {
    background: #63203c;
    background: linear-gradient(90deg, #63203c 0%, #902f57 50%, #a91552 100%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
  .c-button:visited {
    color: #fff; }
  .c-button:focus {
    outline: 2px solid #006ece;
    outline-offset: 1px; }
  .c-button:hover, .c-button:focus {
    border-color: #A91552;
    color: #A91552; }
    .c-button:hover::before, .c-button:focus::before {
      background: #fff;
      background: linear-gradient(90deg, #fff 0%, #fff 50%, #fff 100%); }
  .c-button:active {
    background-color: #a91552;
    color: #fff; }
  .c-button[disabled] {
    background-color: #515760;
    color: #fff;
    cursor: default;
    pointer-events: none; }
  .c-button + .c-button {
    margin-left: 0.4444444444rem; }
    [dir='rtl'] .c-button + .c-button {
      margin-left: 0;
      margin-right: 0.4444444444rem; }

.c-button--external {
  padding-right: 2.2222222222rem; }
  .c-button--external::after {
    background-image: url("../images/external-link.svg");
    background-repeat: no-repeat;
    background-size: 0.7777777778rem 0.7777777778rem;
    content: '';
    display: block;
    height: 0.7777777778rem;
    position: absolute;
    right: 0.8888888889rem;
    top: 0.8333333333rem;
    width: 0.7777777778rem; }
  @media print {
    .c-button--external::after {
      background: transparent;
      content: " <" attr(href) ">";
      position: static;
      width: 100%; } }

.c-button--icon {
  align-items: center;
  display: inline-flex;
  justify-content: center; }
  .c-button--icon .c-button__text {
    margin-left: 0.4444444444rem; }

.c-button--large {
  font-size: 1.1111111111rem;
  padding: 0.9em 2.8em 0.8em; }

.c-button--search {
  padding: 0.7777777778rem 0;
  width: 2.4444444444rem; }

.c-button--secondary {
  border: 1px solid #00427c;
  color: #00427c;
  padding: 0.8125em 1.5625em 0.6875em;
  transition-duration: 200ms;
  transition-property: background, border, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .c-button--secondary::before {
    display: none; }
  .c-button--secondary:visited {
    color: #00427c; }
  .c-button--secondary:hover, .c-button--secondary:focus {
    background-color: #fff;
    border-color: #002d55;
    color: #002d55; }
  .c-button--secondary:active {
    background-color: #fff;
    border-color: #006ece;
    color: #006ece; }
  .c-button--secondary[disabled] {
    background-color: #fff;
    border-color: #515760;
    color: #515760; }

.c-button--small {
  font-size: 0.7777777778rem;
  padding: 0.5928571429em 0.9285714286em 0.45em; }

.c-button--tertiary {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.8125em 1.5625em 0.6875em;
  transition-duration: 200ms;
  transition-property: background, border, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .c-button--tertiary::before {
    display: none; }
  .c-button--tertiary:visited {
    color: #fff; }
  .c-button--tertiary:hover, .c-button--tertiary:focus {
    background: #fff;
    border-color: #fff;
    color: #002d55; }
  .c-button--tertiary:focus {
    outline-color: #1888bb; }
  .c-button--tertiary:active {
    background: #fff;
    border-color: #fff;
    color: #006ece; }
  .c-button--tertiary[disabled] {
    background: transparent;
    border-color: #959ba5;
    color: #959ba5; }

.c-card {
  box-shadow: 0 0 7px 0 rgba(149, 155, 165, 0.5);
  color: #002d55;
  display: block;
  text-decoration: none;
  transition: background 0.2s ease-in-out; }
  .c-card * {
    transition: background 0.2s ease-in-out; }
  .c-card:hover, .c-card:focus {
    background-color: #002d55;
    color: #fff; }
    .c-card:hover .c-card__type,
    .c-card:hover .c-card__date,
    .c-card:hover .c-card__award,
    .c-card:hover .award-teaser, .c-card:focus .c-card__type,
    .c-card:focus .c-card__date,
    .c-card:focus .c-card__award,
    .c-card:focus .award-teaser {
      color: #fff; }
  .c-card:visited .c-card__title,
  .c-card:visited .c-card__text {
    color: #002d55; }
  .c-card:hover .c-card__title,
  .c-card:hover .c-card__text,
  .c-card:hover .c-card__type,
  .c-card:hover .c-card__date,
  .c-card:hover .c-card__award, .c-card:focus .c-card__title,
  .c-card:focus .c-card__text,
  .c-card:focus .c-card__type,
  .c-card:focus .c-card__date,
  .c-card:focus .c-card__award {
    color: #fff !important; }

.c-card__content > :last-child {
  margin-bottom: 0; }

.c-card__text {
  padding: 1.1111111111rem; }

.c-card__title {
  color: inherit;
  margin: 16px 0; }

.c-card__status {
  background: #a91552;
  color: #fff;
  font-size: 0.8888888889rem;
  padding: 0.1666666667rem 0.4444444444rem; }

.c-card__meta {
  align-items: center;
  display: flex;
  margin-bottom: 1.3333333333rem; }

.c-card__type {
  color: #902f57;
  display: inline-block;
  line-height: 1.1;
  margin-right: 0.8888888889rem;
  transition-duration: 200ms;
  transition-property: background, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.c-card__date {
  color: #515760;
  font-size: 0.7777777778rem;
  transition-duration: 200ms;
  transition-property: background, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.c-card__view-more {
  transition: none; }

.c-card--image {
  background-color: #fff;
  padding: 0; }
  .c-card--image .c-card__image {
    position: relative; }
  .c-card--image .c-card__status {
    left: 1.3333333333rem;
    position: absolute;
    top: 1.3333333333rem; }
  .c-card--image.c-card__view-more {
    background: #63203c;
    background: linear-gradient(90deg, #63203c 0%, #902f57 50%, #a91552 100%);
    color: #fff; }
    .c-card--image.c-card__view-more .c-card__content {
      align-self: center; }
    .c-card--image.c-card__view-more a,
    .c-card--image.c-card__view-more .c-card__title {
      color: #fff;
      font-size: 48px;
      max-width: 80%; }
      .c-card--image.c-card__view-more a::after,
      .c-card--image.c-card__view-more .c-card__title::after {
        background-image: url("../images/yellow-arrow-right.svg");
        background-position: right 0.2777777778rem;
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 52px;
        margin-left: 0.5rem;
        vertical-align: middle;
        width: 30px; }
    .c-card--image.c-card__view-more:visited, .c-card--image.c-card__view-more:hover, .c-card--image.c-card__view-more:focus, .c-card--image.c-card__view-more:active {
      color: #fff; }
      .c-card--image.c-card__view-more:visited .c-card__title, .c-card--image.c-card__view-more:hover .c-card__title, .c-card--image.c-card__view-more:focus .c-card__title, .c-card--image.c-card__view-more:active .c-card__title {
        color: #fff; }
    .c-card--image.c-card__view-more:hover, .c-card--image.c-card__view-more:focus {
      background: #902f57; }

.c-card--maroon {
  background-color: transparent;
  color: #fff;
  position: relative; }
  .c-card--maroon::before {
    background: #63203c;
    background: linear-gradient(134deg, #63203c 0%, #902f57 50%, #a91552 100%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
  .c-card--maroon:hover, .c-card--maroon:focus {
    background-color: #002d55;
    background-image: none; }
  .c-card--maroon .c-card__type {
    background-color: #fff; }
  .c-card--maroon .c-card__date {
    color: #fff; }

@media (min-width: 0) and (max-width: 38.4375em) {
  .data-item {
    text-align: center; } }

.data-item__first-line {
  font-family: Oswald, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 1; }

.data-item__second-line {
  font-size: 22px;
  line-height: 1.1; }
  @media (min-width: 55em) {
    .data-item__second-line {
      font-size: 24px; } }
  @media (min-width: 59.375em) {
    .data-item__second-line {
      font-size: 28px; } }

.drop-down {
  border: 1px solid #A2A4B2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  height: 40px;
  list-style: none;
  margin: 100px;
  padding: 0;
  position: relative;
  width: 250px; }
  .drop-down__label {
    background: #fff;
    color: #444;
    font-family: Lato, sans-serif;
    font-size: .8em;
    left: 15px;
    position: absolute;
    top: -9px; }
  .drop-down__arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transition: transform .2s linear; }
    .drop-down__arrow.expanded {
      transform: rotate(-180deg); }
  .drop-down__list {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: opacity 0.1s cubic-bezier(0, 0, 0.38, 0.9), max-height 0.5s cubic-bezier(0, 0, 0.38, 0.9);
    width: 100%; }
  .drop-down__list-container {
    position: relative; }
  .drop-down ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .drop-down__list-item {
    color: #444;
    font-family: Lato, sans-serif;
    list-style-position: inside;
    overflow: hidden;
    padding: 10px 0;
    padding-left: 15px;
    text-overflow: ellipsis;
    transition: background-color .1s linear, color .1s linear;
    white-space: nowrap; }
    .drop-down__list-item:hover, .drop-down__list-item:focus {
      background-color: #00C2FF;
      color: #fff; }

.drop-down__selected {
  color: #444;
  font-family: Lato, sans-serif;
  list-style-position: inside;
  max-width: 80%;
  overflow: hidden;
  padding: 10px 0;
  padding-left: 15px;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .drop-down__selected:focus {
    outline: 1px solid #00C2FF; }

.open {
  max-height: 80vh;
  opacity: 1;
  overflow: auto; }

.c-field {
  color: #002d55;
  margin-bottom: 1.3333333333rem; }
  .c-field > :last-child {
    margin-bottom: 0; }

.c-field__label {
  font-weight: 700; }

.c-fieldset {
  margin-bottom: 2.8333333333rem;
  padding: 0;
  position: relative;
  top: 1.5rem; }

.c-fieldset__legend {
  box-sizing: content-box;
  display: block;
  left: -1px;
  position: absolute;
  text-indent: 0;
  top: -1.5rem;
  width: 100%; }
  [dir='rtl'] .c-fieldset__legend {
    left: auto;
    right: -1px; }

.c-fieldset__legend-text {
  display: block; }

.c-fieldset__legend-link {
  display: block; }

.c-fieldset__description {
  color: #515760;
  font-size: 0.8888888889rem; }
  .c-fieldset__description > :last-child {
    margin-bottom: 0; }

.c-fieldset--default {
  background: #fff;
  border: 1px solid #c5c8ce;
  margin: 0.8888888889rem 0 1.5555555556rem;
  padding: 0 1em; }
  .c-fieldset--default .c-fieldset__legend {
    background: #c5c8ce;
    border: 1px solid #c5c8ce;
    border-bottom: 0;
    color: #212121;
    height: 2em;
    line-height: 2;
    padding: 0;
    text-shadow: 0 1px 0 #fff; }
  .c-fieldset--default .c-fieldset__legend-text {
    padding-left: 1em; }
    [dir='rtl'] .c-fieldset--default .c-fieldset__legend-text {
      padding-left: 0;
      padding-right: 1em; }
  .c-fieldset--default .c-fieldset__content {
    margin-top: 2.5em; }
  .c-fieldset--default .c-fieldset__description {
    font-style: italic;
    margin: 0.8888888889rem 0; }

.c-figure {
  margin: 0 auto 1.3333333333rem; }
  @media (min-width: 31.25em) {
    .c-figure.is-aligned-left, .c-figure.is-aligned-right {
      max-width: 40%; }
    .c-figure.is-aligned-left {
      float: left;
      margin-left: 0;
      margin-right: 1.3333333333rem;
      margin-top: 0.2777777778rem; }
    .c-figure.is-aligned-right {
      float: right;
      margin-left: 1.3333333333rem;
      margin-right: 0;
      margin-top: 0.2777777778rem; } }

.c-figure__caption {
  color: #515760;
  font-size: 0.7777777778rem;
  margin-top: 1.3333333333rem; }

.c-figure__credit {
  font-size: 0.6666666667rem;
  margin-top: 0.2222222222rem; }

.c-figure--large {
  max-width: 40rem; }

.c-figure--small {
  max-width: 15.5555555556rem; }

.c-filter {
  color: #002d55;
  display: block;
  position: relative;
  text-decoration: none; }
  .c-filter:hover, .c-filter:focus {
    color: #002d55;
    text-decoration: underline; }
  .c-filter:focus {
    outline: 2px solid #006ece; }
  .c-filter::before {
    background: #fff;
    border: 1px solid #515760;
    content: '\a0';
    display: inline-block;
    height: 1em;
    line-height: 1;
    margin-right: 0.6em;
    margin-top: -0.1875em;
    text-indent: 0.15em;
    transition-duration: 200ms;
    transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    width: 1em; }
  .c-filter.is-active::before {
    background-image: url("../images/filter-selected.svg");
    background-color: #902f57;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.6em;
    border-color: #902f57; }

.c-filter__count {
  color: #006ece; }

.c-form-item {
  margin: 0 0 1.3333333333rem; }

.c-form-item__label {
  display: block;
  font-size: 0.8888888889rem;
  font-weight: 300; }
  .is-disabled > .c-form-item__label {
    opacity: 0.5; }

.c-form-item__fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.c-form-item__description {
  color: #515760;
  font-size: 0.7777777778rem; }
  .c-form-item__description > :last-child {
    margin-bottom: 0; }

.c-form-item__required-marker::before {
  content: '*'; }


.c-form-item__color,
.c-form-item__date,
.c-form-item__email,
.c-form-item__month,
.c-form-item__number,
.c-form-item__password,
.c-form-item__search,
.c-form-item__select,
.c-form-item__tel,
.c-form-item__text,
.c-form-item__textarea,
.c-form-item__time,
.c-form-item__url,
.c-form-item__week {
  appearance: none;
  background-color: #f0f0f2;
  border: 1px solid #c5c8ce;
  border-radius: 0;
  display: inline-block;
  font-size: 0.8888888889rem;
  height: 3.3333333333rem;
  line-height: 1.875;
  margin: 0;
  max-width: 100%;
  padding: 0.625em 0.4375em;
  transition-duration: 200ms;
  transition-property: background-color, border;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  
  .c-form-item__color::placeholder,
  .c-form-item__date::placeholder,
  .c-form-item__email::placeholder,
  .c-form-item__month::placeholder,
  .c-form-item__number::placeholder,
  .c-form-item__password::placeholder,
  .c-form-item__search::placeholder,
  .c-form-item__select::placeholder,
  .c-form-item__tel::placeholder,
  .c-form-item__text::placeholder,
  .c-form-item__textarea::placeholder,
  .c-form-item__time::placeholder,
  .c-form-item__url::placeholder,
  .c-form-item__week::placeholder {
    color: #515760; }
  
  .c-form-item__color:focus,
  .c-form-item__date:focus,
  .c-form-item__email:focus,
  .c-form-item__month:focus,
  .c-form-item__number:focus,
  .c-form-item__password:focus,
  .c-form-item__search:focus,
  .c-form-item__select:focus,
  .c-form-item__tel:focus,
  .c-form-item__text:focus,
  .c-form-item__textarea:focus,
  .c-form-item__time:focus,
  .c-form-item__url:focus,
  .c-form-item__week:focus {
    outline: 2px solid transparent;
    outline-offset: 2px; }
    
    .c-form-item__color:focus:focus,
    .c-form-item__date:focus:focus,
    .c-form-item__email:focus:focus,
    .c-form-item__month:focus:focus,
    .c-form-item__number:focus:focus,
    .c-form-item__password:focus:focus,
    .c-form-item__search:focus:focus,
    .c-form-item__select:focus:focus,
    .c-form-item__tel:focus:focus,
    .c-form-item__text:focus:focus,
    .c-form-item__textarea:focus:focus,
    .c-form-item__time:focus:focus,
    .c-form-item__url:focus:focus,
    .c-form-item__week:focus:focus {
      outline-color: #006ece; }
  
  .c-form-item__color:hover,
  .c-form-item__color:focus,
  .c-form-item__date:hover,
  .c-form-item__date:focus,
  .c-form-item__email:hover,
  .c-form-item__email:focus,
  .c-form-item__month:hover,
  .c-form-item__month:focus,
  .c-form-item__number:hover,
  .c-form-item__number:focus,
  .c-form-item__password:hover,
  .c-form-item__password:focus,
  .c-form-item__search:hover,
  .c-form-item__search:focus,
  .c-form-item__select:hover,
  .c-form-item__select:focus,
  .c-form-item__tel:hover,
  .c-form-item__tel:focus,
  .c-form-item__text:hover,
  .c-form-item__text:focus,
  .c-form-item__textarea:hover,
  .c-form-item__textarea:focus,
  .c-form-item__time:hover,
  .c-form-item__time:focus,
  .c-form-item__url:hover,
  .c-form-item__url:focus,
  .c-form-item__week:hover,
  .c-form-item__week:focus {
    background-color: #fff;
    border: 1px solid #515760; }
  
  .c-form-item__color:disabled,
  .c-form-item__date:disabled,
  .c-form-item__email:disabled,
  .c-form-item__month:disabled,
  .c-form-item__number:disabled,
  .c-form-item__password:disabled,
  .c-form-item__search:disabled,
  .c-form-item__select:disabled,
  .c-form-item__tel:disabled,
  .c-form-item__text:disabled,
  .c-form-item__textarea:disabled,
  .c-form-item__time:disabled,
  .c-form-item__url:disabled,
  .c-form-item__week:disabled {
    cursor: default;
    opacity: 0.5; }
    
    .c-form-item__color:disabled:hover,
    .c-form-item__color:disabled:focus,
    .c-form-item__date:disabled:hover,
    .c-form-item__date:disabled:focus,
    .c-form-item__email:disabled:hover,
    .c-form-item__email:disabled:focus,
    .c-form-item__month:disabled:hover,
    .c-form-item__month:disabled:focus,
    .c-form-item__number:disabled:hover,
    .c-form-item__number:disabled:focus,
    .c-form-item__password:disabled:hover,
    .c-form-item__password:disabled:focus,
    .c-form-item__search:disabled:hover,
    .c-form-item__search:disabled:focus,
    .c-form-item__select:disabled:hover,
    .c-form-item__select:disabled:focus,
    .c-form-item__tel:disabled:hover,
    .c-form-item__tel:disabled:focus,
    .c-form-item__text:disabled:hover,
    .c-form-item__text:disabled:focus,
    .c-form-item__textarea:disabled:hover,
    .c-form-item__textarea:disabled:focus,
    .c-form-item__time:disabled:hover,
    .c-form-item__time:disabled:focus,
    .c-form-item__url:disabled:hover,
    .c-form-item__url:disabled:focus,
    .c-form-item__week:disabled:hover,
    .c-form-item__week:disabled:focus {
      background-color: #f0f0f2;
      border: 1px solid #c5c8ce; }
  .is-full-width >
  .c-form-item__color, .is-full-width >
  .c-form-item__date, .is-full-width >
  .c-form-item__email, .is-full-width >
  .c-form-item__month, .is-full-width >
  .c-form-item__number, .is-full-width >
  .c-form-item__password, .is-full-width >
  .c-form-item__search, .is-full-width >
  .c-form-item__select, .is-full-width >
  .c-form-item__tel, .is-full-width >
  .c-form-item__text, .is-full-width >
  .c-form-item__textarea, .is-full-width >
  .c-form-item__time, .is-full-width >
  .c-form-item__url, .is-full-width >
  .c-form-item__week {
    width: 100%; }

.c-form-item__textarea {
  height: auto; }

.c-form-item--checkbox {
  margin-bottom: 8px; }

.c-form-item__checkbox + .c-form-item__label {
  cursor: pointer;
  display: block; }
  .c-form-item__checkbox + .c-form-item__label::before {
    outline: 2px solid transparent;
    outline-offset: 2px;
    background: #fff;
    border: 1px solid #0058a4;
    content: '\a0';
    display: inline-block;
    height: 1em;
    line-height: 1;
    margin-right: 0.6em;
    margin-top: -0.1875em;
    text-indent: 0.15em;
    transition-duration: 200ms;
    transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    width: 1em; }
    .c-form-item__checkbox + .c-form-item__label::before:focus {
      outline-color: #006ece; }

.c-form-item__checkbox:checked + .c-form-item__label::before {
  background-image: url("../images/correct.svg");
  background-color: #0058a4;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1em; }

.c-form-item__checkbox:focus + .c-form-item__label::before {
  outline-color: #006ece; }

.c-form-item__checkbox:disabled + .c-form-item__label {
  cursor: default;
  opacity: 0.5; }
  .c-form-item__checkbox:disabled + .c-form-item__label::before {
    cursor: not-allowed; }

.c-form-item--checkboxes {
  margin-bottom: 0; }

.c-form-item--inverted .c-form-item__label,
.c-form-item--inverted .c-form-item__description {
  color: #fff; }

.c-form-item--inverted .c-form-item__color,
.c-form-item--inverted .c-form-item__date,
.c-form-item--inverted .c-form-item__email,
.c-form-item--inverted .c-form-item__month,
.c-form-item--inverted .c-form-item__number,
.c-form-item--inverted .c-form-item__password,
.c-form-item--inverted .c-form-item__search,
.c-form-item--inverted .c-form-item__select,
.c-form-item--inverted .c-form-item__tel,
.c-form-item--inverted .c-form-item__text,
.c-form-item--inverted .c-form-item__textarea,
.c-form-item--inverted .c-form-item__time,
.c-form-item--inverted .c-form-item__url,
.c-form-item--inverted .c-form-item__week {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #c5c8ce;
  color: #fff;
  font-size: 20px; }
  .c-form-item--inverted .c-form-item__color::placeholder,
  .c-form-item--inverted .c-form-item__date::placeholder,
  .c-form-item--inverted .c-form-item__email::placeholder,
  .c-form-item--inverted .c-form-item__month::placeholder,
  .c-form-item--inverted .c-form-item__number::placeholder,
  .c-form-item--inverted .c-form-item__password::placeholder,
  .c-form-item--inverted .c-form-item__search::placeholder,
  .c-form-item--inverted .c-form-item__select::placeholder,
  .c-form-item--inverted .c-form-item__tel::placeholder,
  .c-form-item--inverted .c-form-item__text::placeholder,
  .c-form-item--inverted .c-form-item__textarea::placeholder,
  .c-form-item--inverted .c-form-item__time::placeholder,
  .c-form-item--inverted .c-form-item__url::placeholder,
  .c-form-item--inverted .c-form-item__week::placeholder {
    color: #c5c8ce; }
  .c-form-item--inverted .c-form-item__color:hover, .c-form-item--inverted .c-form-item__color:focus,
  .c-form-item--inverted .c-form-item__date:hover,
  .c-form-item--inverted .c-form-item__date:focus,
  .c-form-item--inverted .c-form-item__email:hover,
  .c-form-item--inverted .c-form-item__email:focus,
  .c-form-item--inverted .c-form-item__month:hover,
  .c-form-item--inverted .c-form-item__month:focus,
  .c-form-item--inverted .c-form-item__number:hover,
  .c-form-item--inverted .c-form-item__number:focus,
  .c-form-item--inverted .c-form-item__password:hover,
  .c-form-item--inverted .c-form-item__password:focus,
  .c-form-item--inverted .c-form-item__search:hover,
  .c-form-item--inverted .c-form-item__search:focus,
  .c-form-item--inverted .c-form-item__select:hover,
  .c-form-item--inverted .c-form-item__select:focus,
  .c-form-item--inverted .c-form-item__tel:hover,
  .c-form-item--inverted .c-form-item__tel:focus,
  .c-form-item--inverted .c-form-item__text:hover,
  .c-form-item--inverted .c-form-item__text:focus,
  .c-form-item--inverted .c-form-item__textarea:hover,
  .c-form-item--inverted .c-form-item__textarea:focus,
  .c-form-item--inverted .c-form-item__time:hover,
  .c-form-item--inverted .c-form-item__time:focus,
  .c-form-item--inverted .c-form-item__url:hover,
  .c-form-item--inverted .c-form-item__url:focus,
  .c-form-item--inverted .c-form-item__week:hover,
  .c-form-item--inverted .c-form-item__week:focus {
    border-color: #fff; }
  .c-form-item--inverted .c-form-item__color:focus,
  .c-form-item--inverted .c-form-item__date:focus,
  .c-form-item--inverted .c-form-item__email:focus,
  .c-form-item--inverted .c-form-item__month:focus,
  .c-form-item--inverted .c-form-item__number:focus,
  .c-form-item--inverted .c-form-item__password:focus,
  .c-form-item--inverted .c-form-item__search:focus,
  .c-form-item--inverted .c-form-item__select:focus,
  .c-form-item--inverted .c-form-item__tel:focus,
  .c-form-item--inverted .c-form-item__text:focus,
  .c-form-item--inverted .c-form-item__textarea:focus,
  .c-form-item--inverted .c-form-item__time:focus,
  .c-form-item--inverted .c-form-item__url:focus,
  .c-form-item--inverted .c-form-item__week:focus {
    outline-color: #1888bb; }

.c-form-item--radio {
  margin-bottom: 8px; }
  .c-form-item--radio .c-form-item__radio {
    outline: 2px solid transparent;
    outline-offset: 2px;
    appearance: none;
    background-color: #fff;
    border: 1px solid #0058a4;
    border-radius: 50%;
    box-shadow: 0 0 0 2px transparent;
    cursor: pointer;
    font-size: 0.8888888889rem;
    height: 1em;
    margin-right: 0.125em;
    position: relative;
    top: 0.1875em;
    transition-duration: 200ms;
    transition-property: background-color, border-color, border-radius;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 1em; }
    .c-form-item--radio .c-form-item__radio:focus {
      outline-color: #006ece; }
    .c-form-item--radio .c-form-item__radio:checked {
      background-color: #0058a4;
      border: 2px solid #fff;
      box-shadow: 0 0 0 2px #0058a4; }
    .c-form-item--radio .c-form-item__radio:disabled {
      cursor: default;
      opacity: 0.5; }
    .c-form-item--radio .c-form-item__radio + .c-form-item__label {
      cursor: pointer;
      display: inline; }
    .c-form-item--radio .c-form-item__radio:disabled + .c-form-item__label {
      cursor: default;
      opacity: 0.5; }

.c-form-item--radios {
  margin-bottom: 0; }

.c-form-item--range .c-form-item__range {
  appearance: none;
  background: transparent;
  border: 0;
  height: 2.4444444444rem;
  margin: 0.2em 0;
  padding: 0; }
  .c-form-item--range .c-form-item__range::-moz-focus-outer {
    border: 0; }
  .c-form-item--range .c-form-item__range:focus {
    box-shadow: none;
    outline: 0; }
    .c-form-item--range .c-form-item__range:focus::-moz-range-thumb {
      outline-color: #006ece; }
    .c-form-item--range .c-form-item__range:focus::-ms-thumb {
      outline-color: #006ece; }
    .c-form-item--range .c-form-item__range:focus::-webkit-slider-thumb {
      outline-color: #006ece; }
  .c-form-item--range .c-form-item__range:disabled {
    cursor: default;
    opacity: 0.5; }
  .c-form-item--range .c-form-item__range::-moz-range-thumb {
    outline: 2px solid transparent;
    outline-offset: 2px;
    background: #515760;
    border: 1px solid #fafafb;
    border-radius: 0;
    cursor: pointer;
    height: 2.4444444444rem;
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 1.2222222222rem; }
    .c-form-item--range .c-form-item__range::-moz-range-thumb:focus {
      outline-color: #006ece; }
  .c-form-item--range .c-form-item__range::-moz-range-track {
    background: #959ba5;
    border: 1px solid #515760;
    cursor: pointer;
    display: block;
    height: 0.5555555556rem;
    margin: 0;
    outline: 0;
    width: 100%; }
  .c-form-item--range .c-form-item__range::-ms-thumb {
    outline: 2px solid transparent;
    outline-offset: 2px;
    background: #515760;
    border: 1px solid #fafafb;
    border-radius: 0;
    cursor: pointer;
    height: 2.4444444444rem;
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 1.2222222222rem;
    margin-top: 0 !important; }
    .c-form-item--range .c-form-item__range::-ms-thumb:focus {
      outline-color: #006ece; }
  .c-form-item--range .c-form-item__range::-ms-track {
    background: #959ba5;
    border: 1px solid #515760;
    cursor: pointer;
    display: block;
    height: 0.5555555556rem;
    margin: 0;
    outline: 0;
    width: 100%;
    background: transparent;
    border: 0;
    color: transparent; }
  .c-form-item--range .c-form-item__range::-ms-fill-lower, .c-form-item--range .c-form-item__range::-ms-fill-upper {
    background: #959ba5;
    border: 1px solid #515760; }
  .c-form-item--range .c-form-item__range::-webkit-slider-thumb {
    outline: 2px solid transparent;
    outline-offset: 2px;
    background: #515760;
    border: 1px solid #fafafb;
    border-radius: 0;
    cursor: pointer;
    height: 2.4444444444rem;
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 1.2222222222rem;
    -webkit-appearance: none;
    margin-top: -1rem; }
    .c-form-item--range .c-form-item__range::-webkit-slider-thumb:focus {
      outline-color: #006ece; }
  .c-form-item--range .c-form-item__range::-webkit-slider-runnable-track {
    background: #959ba5;
    border: 1px solid #515760;
    cursor: pointer;
    display: block;
    height: 0.5555555556rem;
    margin: 0;
    outline: 0;
    width: 100%; }

.c-form-item--range.is-full-width > .c-form-item__range {
  width: 100%; }

.c-form-item--select .c-form-item__select {
  background-image: url("../images/select-arrows.svg");
  background-color: #fff;
  background-position: right 0.5555555556rem center;
  background-repeat: no-repeat;
  background-size: 1.1111111111rem;
  padding-right: 2.2222222222rem; }
  [dir='rtl'] .c-form-item--select .c-form-item__select {
    background-position: left 0.5555555556rem center;
    padding-left: 2.2222222222rem;
    padding-right: 0; }
  .c-form-item--select .c-form-item__select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  .c-form-item--select .c-form-item__select::-ms-expand {
    display: none; }

.c-form-item--tertiary .c-form-item__label,
.c-form-item--tertiary .c-form-item__description {
  color: #fff; }

.c-form-item--tertiary .c-form-item__color,
.c-form-item--tertiary .c-form-item__date,
.c-form-item--tertiary .c-form-item__email,
.c-form-item--tertiary .c-form-item__month,
.c-form-item--tertiary .c-form-item__number,
.c-form-item--tertiary .c-form-item__password,
.c-form-item--tertiary .c-form-item__search,
.c-form-item--tertiary .c-form-item__select,
.c-form-item--tertiary .c-form-item__tel,
.c-form-item--tertiary .c-form-item__text,
.c-form-item--tertiary .c-form-item__textarea,
.c-form-item--tertiary .c-form-item__time,
.c-form-item--tertiary .c-form-item__url,
.c-form-item--tertiary .c-form-item__week {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  transition-duration: 200ms;
  transition-property: background, border, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .c-form-item--tertiary .c-form-item__color::placeholder,
  .c-form-item--tertiary .c-form-item__date::placeholder,
  .c-form-item--tertiary .c-form-item__email::placeholder,
  .c-form-item--tertiary .c-form-item__month::placeholder,
  .c-form-item--tertiary .c-form-item__number::placeholder,
  .c-form-item--tertiary .c-form-item__password::placeholder,
  .c-form-item--tertiary .c-form-item__search::placeholder,
  .c-form-item--tertiary .c-form-item__select::placeholder,
  .c-form-item--tertiary .c-form-item__tel::placeholder,
  .c-form-item--tertiary .c-form-item__text::placeholder,
  .c-form-item--tertiary .c-form-item__textarea::placeholder,
  .c-form-item--tertiary .c-form-item__time::placeholder,
  .c-form-item--tertiary .c-form-item__url::placeholder,
  .c-form-item--tertiary .c-form-item__week::placeholder {
    color: #c5c8ce; }
  .c-form-item--tertiary .c-form-item__color:hover, .c-form-item--tertiary .c-form-item__color:focus,
  .c-form-item--tertiary .c-form-item__date:hover,
  .c-form-item--tertiary .c-form-item__date:focus,
  .c-form-item--tertiary .c-form-item__email:hover,
  .c-form-item--tertiary .c-form-item__email:focus,
  .c-form-item--tertiary .c-form-item__month:hover,
  .c-form-item--tertiary .c-form-item__month:focus,
  .c-form-item--tertiary .c-form-item__number:hover,
  .c-form-item--tertiary .c-form-item__number:focus,
  .c-form-item--tertiary .c-form-item__password:hover,
  .c-form-item--tertiary .c-form-item__password:focus,
  .c-form-item--tertiary .c-form-item__search:hover,
  .c-form-item--tertiary .c-form-item__search:focus,
  .c-form-item--tertiary .c-form-item__select:hover,
  .c-form-item--tertiary .c-form-item__select:focus,
  .c-form-item--tertiary .c-form-item__tel:hover,
  .c-form-item--tertiary .c-form-item__tel:focus,
  .c-form-item--tertiary .c-form-item__text:hover,
  .c-form-item--tertiary .c-form-item__text:focus,
  .c-form-item--tertiary .c-form-item__textarea:hover,
  .c-form-item--tertiary .c-form-item__textarea:focus,
  .c-form-item--tertiary .c-form-item__time:hover,
  .c-form-item--tertiary .c-form-item__time:focus,
  .c-form-item--tertiary .c-form-item__url:hover,
  .c-form-item--tertiary .c-form-item__url:focus,
  .c-form-item--tertiary .c-form-item__week:hover,
  .c-form-item--tertiary .c-form-item__week:focus {
    background: #fff;
    border-color: #fff;
    color: #002d55; }
    .c-form-item--tertiary .c-form-item__color:hover::placeholder, .c-form-item--tertiary .c-form-item__color:focus::placeholder,
    .c-form-item--tertiary .c-form-item__date:hover::placeholder,
    .c-form-item--tertiary .c-form-item__date:focus::placeholder,
    .c-form-item--tertiary .c-form-item__email:hover::placeholder,
    .c-form-item--tertiary .c-form-item__email:focus::placeholder,
    .c-form-item--tertiary .c-form-item__month:hover::placeholder,
    .c-form-item--tertiary .c-form-item__month:focus::placeholder,
    .c-form-item--tertiary .c-form-item__number:hover::placeholder,
    .c-form-item--tertiary .c-form-item__number:focus::placeholder,
    .c-form-item--tertiary .c-form-item__password:hover::placeholder,
    .c-form-item--tertiary .c-form-item__password:focus::placeholder,
    .c-form-item--tertiary .c-form-item__search:hover::placeholder,
    .c-form-item--tertiary .c-form-item__search:focus::placeholder,
    .c-form-item--tertiary .c-form-item__select:hover::placeholder,
    .c-form-item--tertiary .c-form-item__select:focus::placeholder,
    .c-form-item--tertiary .c-form-item__tel:hover::placeholder,
    .c-form-item--tertiary .c-form-item__tel:focus::placeholder,
    .c-form-item--tertiary .c-form-item__text:hover::placeholder,
    .c-form-item--tertiary .c-form-item__text:focus::placeholder,
    .c-form-item--tertiary .c-form-item__textarea:hover::placeholder,
    .c-form-item--tertiary .c-form-item__textarea:focus::placeholder,
    .c-form-item--tertiary .c-form-item__time:hover::placeholder,
    .c-form-item--tertiary .c-form-item__time:focus::placeholder,
    .c-form-item--tertiary .c-form-item__url:hover::placeholder,
    .c-form-item--tertiary .c-form-item__url:focus::placeholder,
    .c-form-item--tertiary .c-form-item__week:hover::placeholder,
    .c-form-item--tertiary .c-form-item__week:focus::placeholder {
      color: #515760; }
  .c-form-item--tertiary .c-form-item__color[disabled],
  .c-form-item--tertiary .c-form-item__date[disabled],
  .c-form-item--tertiary .c-form-item__email[disabled],
  .c-form-item--tertiary .c-form-item__month[disabled],
  .c-form-item--tertiary .c-form-item__number[disabled],
  .c-form-item--tertiary .c-form-item__password[disabled],
  .c-form-item--tertiary .c-form-item__search[disabled],
  .c-form-item--tertiary .c-form-item__select[disabled],
  .c-form-item--tertiary .c-form-item__tel[disabled],
  .c-form-item--tertiary .c-form-item__text[disabled],
  .c-form-item--tertiary .c-form-item__textarea[disabled],
  .c-form-item--tertiary .c-form-item__time[disabled],
  .c-form-item--tertiary .c-form-item__url[disabled],
  .c-form-item--tertiary .c-form-item__week[disabled] {
    background: transparent;
    border-color: #959ba5;
    color: #959ba5; }

@media (min-width: 62.5em) {
  .c-hero .l-constrain {
    display: flex;
    justify-content: space-between; } }

.cta-right {
  align-self: flex-start;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 60px;
  padding: 35px; }
  @media (min-width: 62.5em) {
    .cta-right {
      margin: 60px 0 0 40px;
      min-width: 360px; } }

.hero-cta__form-title {
  font-size: 32px; }

.hero-cta__form-content {
  font-size: 18px; }

.hero-cta__thanks {
  display: none; }

.hero-cta__form .c-button[disabled],
.hero-cta__form .c-button[disabled]::before {
  background: #515760; }

.cta-form .c-form-item__label {
  display: none; }

.cta-form .cta-email-input {
  background-image: url("../images/email.svg");
  background-position-x: 10px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 12px;
  border: 1px solid #c5c8ce;
  border-radius: 2px;
  box-shadow: none;
  padding: 5px 0 5px 30px;
  width: 100%; }
  .cta-form .cta-email-input::placeholder {
    font-size: 14px;
    padding-left: 20px; }

.cta-form .c-button {
  margin-bottom: 0;
  width: 100%; }

.errortext {
  color: #a91552;
  font-size: .7em; }

.c-hero {
  background-color: #f0f0f2;
  padding: 2.6666666667rem 0 1.7777777778rem 0; }

.c-hero__content > :last-child {
  margin-bottom: 0; }

.c-hero__type {
  color: #902f57;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
  margin: 0 0 1.3333333333rem; }

.c-hero__tag {
  border: 1px solid #002d55;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
  margin: 0 0.8888888889rem 0.8888888889rem 0;
  padding: 0.2222222222rem; }

.c-hero__title {
  margin: 0 0 1.3333333333rem; }

.c-hero__project-title {
  font-weight: 700;
  margin-bottom: 0.8888888889rem; }

.c-hero__summary {
  margin-bottom: 0.4444444444rem; }
  .c-hero__summary p {
    font-size: 18px;
    margin-bottom: 0; }

.c-hero__submitted {
  color: #515760;
  font-size: 14px;
  margin-bottom: 0.4444444444rem; }

.c-hero__date {
  color: #515760;
  display: inline-block;
  font-size: 0.7777777778rem; }

.c-hero__actor {
  border-radius: 50%;
  overflow: hidden; }

.c-hero__main > :last-child {
  margin-bottom: 0; }

.c-hero__meta > :last-child {
  margin-bottom: 0; }

.c-hero__link {
  color: #002d55; }
  .c-hero__link:visited, .c-hero__link:hover, .c-hero__link:focus, .c-hero__link:active {
    color: #002d55; }

.c-hero__downloads {
  align-items: center;
  display: inline-flex;
  margin-top: 0.8888888889rem;
  position: relative; }
  .c-hero__downloads > * {
    margin-bottom: 0; }

.c-hero__link--file {
  background-image: url("../images/file-link.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 0.8888888889rem;
  color: #902f57;
  display: inline-block;
  padding-left: 0.8888888889rem;
  vertical-align: middle; }

.c-hero__info-icon {
  height: 16px;
  overflow: hidden;
  width: 16px;
  background-image: url("../images/info-bubble.svg");
  background-size: contain;
  display: inline-block;
  margin-left: 0.8888888889rem;
  opacity: 0.3; }
  .c-hero__info-icon::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }

.c-hero__help-text {
  background-color: #fff;
  display: none;
  min-width: 25vw;
  padding: 0.75rem;
  position: absolute;
  right: -25.5vw;
  top: -15px; }

.c-hero--homepage {
  background-color: #fff; }
  .c-hero--homepage .c-hero__intro {
    position: relative; }
  .c-hero--homepage .c-hero__title {
    color: #002d55;
    font-family: Oswald, sans-serif;
    font-size: 3.5555555556rem;
    font-weight: 500;
    line-height: 1.1;
    font-weight: 500;
    margin-bottom: 1rem; }
    @media (max-width: 43.75em) {
      .c-hero--homepage .c-hero__title {
        font-size: 1.5555555556rem;
        font-size: calc(1.2222222222rem + 6vw); } }
  .c-hero--homepage .c-hero__subtitle {
    font-size: 1rem;
    margin-bottom: 1rem; }
  .c-hero--homepage .c-hero__content {
    align-items: center;
    /* stylelint-disable */
    display: -ms-grid;
    /* stylelint-enable */
    display: grid;
    /* stylelint-disable */
    -ms-grid-columns: 1fr;
    /* stylelint-enable */
    grid-template-columns: 1fr;
    /* stylelint-disable */
    -ms-grid-rows: repeat(1fr);
    /* stylelint-enable */
    grid-template-rows: repeat(1fr);
    padding: 2rem 0 3.3333333333rem 0; }
    @media (min-width: 53.75em) {
      .c-hero--homepage .c-hero__content {
        /* stylelint-disable */
        -ms-grid-columns: 1fr 1fr;
        /* stylelint-enable */
        grid-template-columns: 1fr 1fr;
        padding: 5.5555555556rem 0 3.3333333333rem 0; } }
  @media (min-width: 0) and (max-width: 53.75em) {
    .c-hero--homepage .c-hero__text {
      padding-top: 1.5rem; } }
  @media (min-width: 53.75em) {
    .c-hero--homepage .c-hero__text {
      margin-right: 120px; } }
  .c-hero--homepage .c-hero__image {
    /* stylelint-disable */
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    /* stylelint-enable */
    order: -1;
    padding-bottom: 2rem;
    position: relative; }
    @media (min-width: 28.125em) and (max-width: 53.75em) {
      .c-hero--homepage .c-hero__image {
        margin: 0 auto;
        max-width: 80%; } }
    @media (min-width: 53.75em) {
      .c-hero--homepage .c-hero__image {
        order: 2;
        /* stylelint-disable */
        -ms-grid-column: 2;
        /* stylelint-enable */
        padding-bottom: 0; } }
    .c-hero--homepage .c-hero__image img {
      border-radius: 50%;
      position: relative;
      z-index: 4; }
    .c-hero--homepage .c-hero__image::before, .c-hero--homepage .c-hero__image::after {
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute; }
    .c-hero--homepage .c-hero__image::before {
      background-image: url("../images/arrow-right--blue.svg");
      animation-duration: 1.25s;
      animation-name: sneak;
      background-size: contain;
      height: 5rem;
      left: 0;
      top: 0;
      transition: all 0.5s ease;
      width: 4.1666666667rem;
      z-index: 5; }
      @media (min-width: 31.25em) {
        .c-hero--homepage .c-hero__image::before {
          height: 11.7777777778rem;
          left: -3.6111111111rem;
          width: 10.0555555556rem; } }
    .c-hero--homepage .c-hero__image::after {
      animation-duration: 2.25s;
      animation-name: sneak;
      background-image: url("../images/triangle-trio-light-blue.png");
      background-size: contain;
      bottom: -1.1111111111rem;
      height: 6.9444444444rem;
      right: 0;
      transition: all 1.5s ease;
      width: 8.3333333333rem;
      z-index: 2; }
      @media (min-width: 0) and (max-width: 61.25em) {
        .c-hero--homepage .c-hero__image::after {
          bottom: -1.1111111111rem;
          height: 6.9444444444rem;
          right: -0.8333333333rem;
          width: 8.3333333333rem; } }
      @media (min-width: 66.875em) {
        .c-hero--homepage .c-hero__image::after {
          bottom: 1.1111111111rem;
          height: 6.9444444444rem;
          right: -0.8333333333rem;
          width: 8.3333333333rem; } }
      @media (min-width: 81.25em) {
        .c-hero--homepage .c-hero__image::after {
          bottom: 1.1111111111rem;
          height: 6.9444444444rem;
          right: -2.7777777778rem;
          width: 8.3333333333rem; } }

@keyframes flight {
  0% {
    left: -150px;
    top: -20px;
    transform: rotate(90deg); }
  50% {
    left: -80px;
    top: 40px;
    transform: rotate(45deg); }
  100% {
    left: 0;
    top: 0;
    transform: rotate(0); } }

@keyframes sneak {
  0% {
    transform: translateX(-100px); }
  100% {
    transform: translateX(0); } }

.c-hero--image {
  background-position: 50%;
  background-size: cover;
  overflow: hidden;
  padding: 0; }
  .c-hero--image .c-hero__logo {
    align-items: flex-end;
    display: flex;
    min-height: 27.7777777778rem; }
    .c-hero--image .c-hero__logo img {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }

.c-hero--maroon {
  background: #63203c;
  background: linear-gradient(90deg, #63203c 0%, #902f57 50%, #a91552 100%);
  border-top: 1px solid #fff;
  color: #fff; }
  .c-hero--maroon .c-hero__type {
    border: 1px solid #fff;
    color: #fff; }
  .c-hero--maroon .c-hero__title {
    color: #fff; }
  .c-hero--maroon .c-hero__date {
    color: #fff; }

.c-hero--navy-blue-dark {
  background: #002d55;
  background: linear-gradient(90deg, #002d55 0%, #00427c 50%, #0058a4 100%);
  color: #fff; }
  .c-hero--navy-blue-dark .c-hero__type {
    border: 1px solid #fff;
    color: #fff; }
  .c-hero--navy-blue-dark .c-hero__title {
    color: #fff; }
  .c-hero--navy-blue-dark .c-hero__date {
    color: #fff; }

.c-hero--tall {
  padding-bottom: 2.6666666667rem;
  padding-top: 11.1111111111rem; }

.c-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  top: 0;
  transition: none;
  vertical-align: middle;
  width: 1em; }
  .c-icon * {
    transition: none; }
  .c-icon.is-spaced-before {
    margin-left: 0.25em; }
  .c-icon.is-spaced-after {
    margin-right: 0.25em; }

.c-icon--breadcrumb {
  top: -0.0555555556rem; }

.c-icon--button {
  top: -0.0555555556rem; }

.c-info-bar {
  background: #002d55;
  background: linear-gradient(90deg, #002d55 0%, #0058a4 50%, #2cace3 100%);
  color: #fff;
  font-size: 0.7777777778rem;
  margin-bottom: 1.3333333333rem;
  padding: 0.4444444444rem 0; }

.info-box {
  box-shadow: 0 0 7px 0 rgba(149, 155, 165, 0.5);
  display: block;
  margin: 1.3333333333rem 0;
  padding: 0.8888888889rem;
  text-decoration: none; }
  .info-box.info-box__link {
    background-image: url("../images/external-link--grey.svg");
    background-color: #fff;
    background-position: 95% 1rem;
    background-repeat: no-repeat; }
  .info-box img {
    max-width: calc(100% - 20px); }
  .info-box h6 {
    color: #002d55;
    margin-bottom: 0;
    text-decoration: none; }
  .info-box::after {
    display: none !important; }

.info-box__title {
  background-image: inline("../images/trophy.svg");
  background-position: left top;
  background-repeat: no-repeat;
  border-bottom: 1px solid #c5c8ce;
  color: #902f57;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 1.3333333333rem;
  padding-bottom: 0.8888888889rem;
  padding-left: 1.7777777778rem;
  padding-top: 0.4444444444rem;
  text-decoration: none; }

.c-info-card {
  background: #fff;
  box-shadow: 0 0 7px 0 rgba(149, 155, 165, 0.5);
  margin: 1.7777777778rem 0;
  padding: 1.7777777778rem; }
  .c-info-card .info-card__description {
    color: #515760;
    line-height: 1.1; }

.c-info-card__title {
  color: #902f57;
  margin-bottom: 1.3333333333rem; }

.c-info-card__intro h6 {
  margin-bottom: 0.2222222222rem; }

.c-info-card__intro h4 {
  margin-bottom: 0.8888888889rem; }

.c-info-card__intro a {
  color: #002d55;
  text-decoration: none; }

.c-info-card__content {
  border-top: 1px solid #c5c8ce;
  margin-top: 1.7777777778rem; }
  .c-info-card__content .l-grid--2col {
    margin: 1.7777777778rem 0; }
  .c-info-card__content .c-field {
    margin-bottom: 0; }

.c-introduction {
  color: #902f57;
  font-size: 1.2222222222rem;
  margin-bottom: 1.3333333333rem; }
  @media (max-width: 43.75em) {
    .c-introduction {
      font-size: 1.2222222222rem;
      font-size: calc(0.8888888889rem + 1.1428571429vw); } }

.c-list--border {
  list-style: none;
  margin-left: 0;
  padding-left: 0; }
  .c-list--border li {
    padding-left: 0; }
  .c-list--border > li {
    border-bottom: 1px solid #959ba5;
    padding: 0.25rem; }

.c-list--clean {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .c-list--clean li {
    padding-left: 0; }
    .c-list--clean li::before {
      display: none; }

.c-list--column {
  list-style: none;
  margin-left: 0;
  padding-left: 0; }
  .c-list--column li {
    padding-left: 0; }
  .c-list--column > li {
    break-inside: avoid-column;
    display: table;
    margin-bottom: 0;
    padding-bottom: 0.25em; }
  @media (min-width: 31.25em) {
    .c-list--column {
      column-count: 2;
      column-gap: 2em; } }

.c-list--inline {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .c-list--inline li {
    padding-left: 0; }
  .c-list--inline > li {
    display: inline; }

.c-list--pipeline {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .c-list--pipeline::after {
    clear: both;
    content: "";
    display: table; }
  .c-list--pipeline > li {
    border-right: 1px solid #959ba5;
    display: block;
    float: left;
    margin-right: 0.75em;
    padding-left: 0;
    padding-right: 0.75em;
    /* stylelint-disable-next-line selector-no-qualifying-type */ }
    [dir='rtl'] .c-list--pipeline > li {
      border-left: 1px solid #959ba5;
      border-right: 0;
      float: right;
      margin-left: 0.75em;
      margin-right: 0;
      padding-left: 0.75em;
      padding-right: 0; }
    .c-list--pipeline > li.is-active {
      font-weight: 700; }
    .c-list--pipeline > li:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0; }
      [dir='rtl'] .c-list--pipeline > li:last-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0; }

.c-list--filters {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .c-list--filters li {
    padding-left: 0; }
    .c-list--filters li::before {
      display: none; }
  .c-list--filters li:last-child {
    margin-bottom: 0; }

.location-select {
  position: relative; }
  .location-select .c-form-item__select {
    background-image: url("../images/location.svg");
    background-color: #fff;
    background-position: 10px center;
    background-repeat: no-repeat;
    border: 0;
    box-shadow: 0 0 7px 0 #0000004d;
    padding-left: 2.2222222222rem; }

.login {
  padding: 20px;
  position: relative; }
  @media (min-width: 50em) {
    .login {
      max-width: 45%; } }
  .login h2 {
    font-weight: 300; }
  .login form {
    display: flex;
    flex-direction: column; }
    .login form input {
      margin-bottom: 1rem; }
    .login form .c-form-item__label {
      display: none; }
    .login form .c-button {
      align-self: flex-start; }
  .login::after {
    background-image: url("../images/triangle-trio-light-blue.png");
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -95px;
    content: '';
    display: block;
    height: 5.5555555556rem;
    position: absolute;
    right: 0;
    width: 5.5555555556rem;
    z-index: 5; }
    @media (min-width: 31.25em) {
      .login::after {
        bottom: -25px;
        height: 6.6666666667rem;
        right: -15px;
        width: 8.3333333333rem; } }

.c-menu {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .c-menu li {
    padding-left: 0; }
    .c-menu li::before {
      display: none; }
  @media print {
    .c-menu {
      display: none; } }

.c-menu__item {
  margin-bottom: 0; }

.c-menu--footer {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 1.3333333333rem; }
  .c-menu--footer li {
    padding-left: 0; }
  .c-menu--footer > li {
    display: inline; }
  .c-menu--footer .c-menu__item {
    margin-right: 1.3333333333rem; }
    .c-menu--footer .c-menu__item:last-child {
      margin-right: 0; }
  .c-menu--footer .c-menu__link {
    color: #002d55;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
    padding: 0.5rem 0;
    text-decoration: none; }
    .c-menu--footer .c-menu__link:hover, .c-menu--footer .c-menu__link:focus {
      color: #902f57;
      text-decoration: underline; }
  @media print {
    .c-menu--footer {
      display: none; } }

.c-menu--legal {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 1.3333333333rem; }
  .c-menu--legal li {
    padding-left: 0; }
    .c-menu--legal li::before {
      display: none; }
  .c-menu--legal .c-menu__item {
    line-height: 1.1;
    margin-right: 0.8888888889rem; }
    .c-menu--legal .c-menu__item:last-child {
      margin-right: 0; }
  .c-menu--legal .c-menu__link {
    color: #515760;
    font-size: 12px;
    line-height: 1.1; }
    .c-menu--legal .c-menu__link:hover, .c-menu--legal .c-menu__link:focus {
      color: #902f57; }
  @media print {
    .c-menu--legal {
      display: none; } }

.c-menu--main {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .c-menu--main li {
    padding-left: 0; }
    .c-menu--main li::before {
      display: none; }
  @media (min-width: 26.875em) {
    .c-menu--main {
      display: flex; } }
  .c-menu--main > .c-menu__item {
    display: inline-block;
    margin-right: 1.7777777778rem;
    position: relative; }
    .c-menu--main > .c-menu__item > .c-menu__link {
      border-bottom: 8px solid transparent;
      color: #fff;
      display: inline-block;
      font-size: 1rem;
      font-weight: 700;
      padding-bottom: 1em;
      position: relative;
      text-decoration: none;
      transition-duration: 200ms;
      transition-property: background, border, color;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
      .c-menu--main > .c-menu__item > .c-menu__link:hover, .c-menu--main > .c-menu__item > .c-menu__link:focus, .c-menu--main > .c-menu__item > .c-menu__link.c-menu__link--active {
        border-bottom: 8px solid #fcb23b; }
  @media print {
    .c-menu--main {
      display: none; } }

.js .js-primary-menu {
  display: none; }
  @media (min-width: 50em) {
    .js .js-primary-menu {
      display: flex; } }

.c-menu--secondary {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 1.3333333333rem; }
  .c-menu--secondary li {
    padding-left: 0; }
    .c-menu--secondary li::before {
      display: none; }
  @media (min-width: 68.75em) {
    .c-menu--secondary {
      margin: 0 auto 1.3333333333rem; } }
  .c-menu--secondary .c-menu__item {
    color: #fcb23b;
    font-size: 14px;
    line-height: 1.1;
    margin-right: 0.8888888889rem; }
    .c-menu--secondary .c-menu__item:last-child {
      margin-right: 0; }
  .c-menu--secondary .c-menu__item.has-subnav {
    position: relative; }
  .c-menu--secondary .c-menu__link {
    border-bottom: 1px solid transparent;
    color: #fff;
    text-decoration: none;
    transition: all linear 0.3s;
    /* stylelint-disable */ }
    .c-menu--secondary .c-menu__link:hover, .c-menu--secondary .c-menu__link:focus, .c-menu--secondary .c-menu__link[aria-expanded='true'] {
      border-color: #d61b5a;
      color: #fff; }
    .js .c-menu--secondary .c-menu__link.has-subnav {
      padding-right: 2rem;
      z-index: 2;
      /* stylelint-enable */ }
      @media (min-width: 56.25em) {
        .js .c-menu--secondary .c-menu__link.has-subnav {
          padding-right: 1.6666666667rem; } }
      .js .c-menu--secondary .c-menu__link.has-subnav::after {
        height: 0.75rem;
        overflow: hidden;
        width: 0.75rem;
        background-image: url("../images/menu-arrow-down.svg");
        background-size: 0.75rem;
        content: '';
        display: block;
        font-size: 1.2222222222rem;
        position: absolute;
        right: 0;
        top: 0; }
        .js .c-menu--secondary .c-menu__link.has-subnav::after::before {
          content: '';
          display: block;
          height: 150%;
          width: 0; }
        @media (min-width: 56.25em) {
          .js .c-menu--secondary .c-menu__link.has-subnav::after {
            height: 1rem;
            overflow: hidden;
            width: 1rem;
            background-size: 1rem; }
            .js .c-menu--secondary .c-menu__link.has-subnav::after::before {
              content: '';
              display: block;
              height: 150%;
              width: 0; } }
      .js .c-menu--secondary .c-menu__link.has-subnav:hover::after, .js .c-menu--secondary .c-menu__link.has-subnav:focus::after, .js .c-menu--secondary .c-menu__link.has-subnav:active::after {
        background-image: url("../images/menu-arrow-down-yellow.svg"); }
  .c-menu--secondary .c-menu__subnav {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    display: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0; }
    @media (min-width: 50em) and (max-width: 71.875em) {
      .c-menu--secondary .c-menu__subnav {
        right: 0; } }
    .c-menu--secondary .c-menu__subnav .c-menu__item {
      margin-right: 0; }
    .c-menu--secondary .c-menu__subnav .c-menu__link {
      color: #515760;
      display: block;
      font-size: 0.7777777778rem;
      font-weight: 300;
      line-height: 1.1;
      padding: 0.6666666667rem;
      position: relative;
      text-decoration: none;
      transition: all ease-in-out 0.3s;
      width: 9.4444444444rem; }
      .c-menu--secondary .c-menu__subnav .c-menu__link:hover, .c-menu--secondary .c-menu__subnav .c-menu__link:focus {
        background-color: #d61b5a;
        color: #fff; }
      .c-menu--secondary .c-menu__subnav .c-menu__link.has-subnav::after {
        height: 16px;
        overflow: hidden;
        width: 16px;
        background-image: url("../images/menu-arrow-right.svg");
        background-size: 16px 16px;
        content: '';
        display: block;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease-in-out 0.3s; }
        .c-menu--secondary .c-menu__subnav .c-menu__link.has-subnav::after::before {
          content: '';
          display: block;
          height: 150%;
          width: 0; }
    .c-menu--secondary .c-menu__subnav .c-menu__subnav {
      margin: 0; }

.c-menu--share {
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
  display: flex;
  margin-left: 0.3333333333rem; }
  .c-menu--share li {
    padding-left: 0; }
    .c-menu--share li::before {
      display: none; }
  .c-menu--share .c-menu__link {
    color: #0058a4;
    display: block;
    font-size: 0.8888888889rem;
    line-height: 0.75;
    padding: 0.3888888889rem;
    text-decoration: none; }
    .c-menu--share .c-menu__link:hover, .c-menu--share .c-menu__link:focus {
      color: #902f57; }

.c-menu--social {
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.3333333333rem; }
  .c-menu--social li {
    padding-left: 0; }
    .c-menu--social li::before {
      display: none; }
  @media (min-width: 53.75em) {
    .c-menu--social {
      justify-content: flex-start;
      margin-left: -0.5555555556rem; } }
  .c-menu--social .c-menu__link {
    color: #0058a4;
    display: block;
    font-size: 1.3333333333rem;
    line-height: 0.75;
    padding: 0.5555555556rem;
    text-decoration: none; }
    .c-menu--social .c-menu__link:hover, .c-menu--social .c-menu__link:focus {
      color: #902f57; }

.c-mobile-menu-button {
  outline: 2px solid transparent;
  outline-offset: 2px;
  align-items: center;
  align-self: center;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #902f57;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen-Sans", Ubuntu, Cantarell, "Fira Sans", Droid Sans, sans-serif;
  font-size: 0.7777777778rem;
  font-weight: 700;
  height: 4.1666666667rem;
  line-height: 1.1;
  margin-bottom: 0;
  outline-offset: 0;
  padding: 0 0.8888888889rem;
  text-decoration: none; }
  .c-mobile-menu-button:focus {
    outline-color: #006ece; }
  @media (min-width: 0) and (max-width: 44.4444444444rem) {
    .c-mobile-menu-button {
      padding: 0 0.4444444444rem; } }

.c-mobile-menu-button__icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.1111111111rem 0.7777777778rem;
  height: 0.7777777778rem;
  margin-left: 0.4444444444rem;
  width: 1.1111111111rem; }

.c-mobile-menu-button--close {
  float: right; }
  .c-mobile-menu-button--close .c-mobile-menu-button__icon {
    background-image: inline("../images/mobile-close.svg");
    background-size: 0.7777777778rem;
    width: 0.7777777778rem; }

.c-mobile-menu-button--menu .c-mobile-menu-button__icon {
  background-image: inline("../images/mobile-menu.svg"); }

.c-mobile-menu {
  background-color: #902f57;
  background-color: ggesso-brand(maroon, base);
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999; }

.c-mobile-menu__menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  clear: both;
  margin: 0; }
  .c-mobile-menu__menu li {
    padding-left: 0; }
    .c-mobile-menu__menu li::before {
      display: none; }
  @media (min-width: 0) and (max-width: 50em) {
    .c-mobile-menu__menu + .c-mobile-menu__menu > .c-mobile-menu__item:first-child {
      display: none; } }

.c-mobile-menu__item {
  margin: 0;
  padding: 0;
  position: relative; }

.c-mobile-menu__link {
  background-color: transparent;
  border: 0;
  color: #fff;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen-Sans", Ubuntu, Cantarell, "Fira Sans", Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1;
  outline-offset: 0;
  padding: 0.6722222222rem 0.8888888889rem;
  text-align: left;
  text-decoration: none;
  white-space: normal;
  width: auto;
  z-index: 3; }
  .c-mobile-menu__link:visited {
    color: #fff; }
  .c-mobile-menu__link:hover, .c-mobile-menu__link:focus, .c-mobile-menu__link:active, .c-mobile-menu__link.is-active {
    color: #fff; }
  .c-mobile-menu__link.has-subnav {
    margin-right: 2.4444444444rem; }

.c-mobile-menu__subnav-arrow {
  outline: 2px solid transparent;
  outline-offset: 2px;
  height: 2.4444444444rem;
  overflow: hidden;
  width: 2.4444444444rem;
  background-image: inline("../images/mobile-arrow-down.svg");
  background-attachment: initial;
  background-color: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 0.7777777778rem 0.4444444444rem;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  outline-offset: 0;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: top;
  z-index: 2; }
  .c-mobile-menu__subnav-arrow:focus {
    outline-color: #006ece; }
  .c-mobile-menu__subnav-arrow::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }
  [dir='rtl'] .c-mobile-menu__subnav-arrow {
    left: 0;
    right: auto; }
  .c-mobile-menu__subnav-arrow[aria-expanded='true'] {
    background-image: inline("../images/mobile-arrow-up.svg"); }

.c-mobile-menu__subnav {
  background-color: #fafafb;
  background-color: rgba(250, 250, 251, 0.98);
  display: none;
  list-style: none;
  margin: 0;
  padding: 0; }
  .c-mobile-menu__subnav .c-mobile-menu__link {
    color: #515760;
    font-size: 0.8888888889rem;
    font-weight: 300;
    line-height: 1.1;
    padding-left: 32px; }
    .c-mobile-menu__subnav .c-mobile-menu__link:visited {
      color: #515760; }
    .c-mobile-menu__subnav .c-mobile-menu__link:hover, .c-mobile-menu__subnav .c-mobile-menu__link:focus, .c-mobile-menu__subnav .c-mobile-menu__link:active {
      background-color: #d61b5a;
      color: #fff; }

.c-newsletter-signup {
  margin-bottom: 1.3333333333rem; }

.c-newsletter-signup__content {
  display: flex; }
  .c-newsletter-signup__content > * {
    flex-grow: 1;
    margin-bottom: 0; }

.c-newsletter-signup__button {
  flex-grow: 0; }
  .c-newsletter-signup__button > * {
    margin: 0; }

.c-newsletter-signup--banner .c-newsletter-signup__header {
  color: inherit;
  text-align: left; }

.c-newsletter-signup--banner .c-newsletter-signup__button .c-button {
  border-left: 0; }

@media (min-width: 50em) {
  .c-newsletter-signup--banner {
    align-items: center;
    display: flex;
    justify-content: space-around; }
    .c-newsletter-signup--banner .c-newsletter-signup__header,
    .c-newsletter-signup--banner .c-newsletter-signup__content {
      margin: 0;
      width: 33%; } }

.c-pager__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  clear: both;
  padding: 0;
  text-align: center; }
  .c-pager__items li {
    padding-left: 0; }
    .c-pager__items li::before {
      display: none; }

.c-pager__item {
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: top; }
  .c-pager__item:not([class*='pager__item--']) {
    display: none; }
    @media (min-width: 37.5em) {
      .c-pager__item:not([class*='pager__item--']) {
        display: inline-block; } }

.c-pager__item--ellipsis, .c-pager__item--current, .c-pager__link {
  background-color: transparent;
  border-radius: 50%;
  color: #002d55;
  display: inline-block;
  min-width: 1.7777777778rem;
  padding: 0.4444444444em;
  transition-duration: 200ms;
  transition-property: background, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .c-pager__item--ellipsis:visited, .c-pager__item--current:visited, .c-pager__link:visited {
    color: #002d55; }
  .c-pager__item--ellipsis:hover, .c-pager__item--current:hover, .c-pager__link:hover, .c-pager__item--ellipsis:focus, .c-pager__item--current:focus, .c-pager__link:focus, .c-pager__item--ellipsis:active, .c-pager__item--current:active, .c-pager__link:active {
    background-color: #d61b5a;
    color: #fff; }

.c-pager__item--ellipsis {
  display: none; }
  @media (min-width: 50em) {
    .c-pager__item--ellipsis {
      display: inline-block; } }

.c-pager__item--current {
  background-color: #902f57;
  color: #fff;
  padding-left: 0.4444444444em !important; }
  .c-pager__item--current:visited, .c-pager__item--current:hover, .c-pager__item--current:focus, .c-pager__item--current:active {
    color: #fff; }

.c-pager__link {
  text-decoration: none; }

.c-pager__link--previous,
.c-pager__link--next {
  background-color: transparent;
  position: relative; }
  .c-pager__link--previous:hover, .c-pager__link--previous:focus,
  .c-pager__link--next:hover,
  .c-pager__link--next:focus {
    background-color: transparent;
    color: #002d55;
    text-decoration: underline; }

@media print {
  .print-hidden {
    display: none; } }

.c-pull-quote {
  text-align: center; }
  .c-pull-quote > :last-child {
    margin-bottom: 0; }
  @media (min-width: 31.25em) {
    .c-pull-quote.is-aligned-left, .c-pull-quote.is-aligned-right {
      font-size: 1.3333333333rem;
      margin-bottom: 1.3333333333rem;
      margin-top: 0.3888888889rem;
      max-width: 40%;
      padding: 1.3333333333rem 0;
      text-align: left; } }
  @media (min-width: 31.25em) and (max-width: 43.75em) {
    .c-pull-quote.is-aligned-left, .c-pull-quote.is-aligned-right {
      font-size: 1.1111111111rem;
      font-size: calc(0.7777777778rem + 1.4285714286vw); } }
  @media (min-width: 31.25em) {
      .c-pull-quote.is-aligned-left p::before, .c-pull-quote.is-aligned-left p::after, .c-pull-quote.is-aligned-right p::before, .c-pull-quote.is-aligned-right p::after {
        content: ''; }
      .c-pull-quote.is-aligned-left .c-pull-quote__share-links, .c-pull-quote.is-aligned-right .c-pull-quote__share-links {
        justify-content: flex-start; }
    .c-pull-quote.is-aligned-left {
      float: left;
      margin-right: 3.5555555556rem; }
    .c-pull-quote.is-aligned-right {
      float: right;
      margin-left: 3.5555555556rem; } }

.c-pull-quote__share-links {
  align-items: center;
  color: #515760;
  display: flex;
  font-size: 0.7777777778rem;
  justify-content: center; }
  .c-pull-quote__share-links .c-menu__link:hover, .c-pull-quote__share-links .c-menu__link:active {
    cursor: pointer; }

.c-read-more {
  align-items: center;
  display: inline-flex;
  font-size: 1.1111111111rem;
  font-weight: 500;
  padding: 0.3333333333rem 0;
  position: relative;
  text-decoration: none;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .c-read-more::before, .c-read-more::after {
    background-color: #d61b5a;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    transition-duration: 200ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 0; }
  .c-read-more::before {
    width: 100%; }
  .c-read-more::after {
    bottom: 0;
    top: auto; }
  .c-read-more:hover, .c-read-more:focus, .c-read-more:active {
    color: #002d55; }
    .c-read-more:hover::before, .c-read-more:focus::before, .c-read-more:active::before {
      width: 0; }
    .c-read-more:hover::after, .c-read-more:focus::after, .c-read-more:active::after {
      width: 100%; }
    .c-read-more:hover .c-read-more__icon, .c-read-more:focus .c-read-more__icon, .c-read-more:active .c-read-more__icon {
      right: 0.2777777778rem; }

.c-read-more__icon {
  color: #d61b5a;
  font-size: 0.6666666667rem;
  margin-left: 1.3333333333rem;
  margin-top: -0.1666666667rem;
  position: relative;
  right: 0;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.c-read-more--reverse {
  color: #fff; }
  .c-read-more--reverse::before, .c-read-more--reverse::after {
    background-color: #fcb23b; }
  .c-read-more--reverse:visited, .c-read-more--reverse:hover, .c-read-more--reverse:focus, .c-read-more--reverse:active {
    color: #fff; }
  .c-read-more--reverse .c-read-more__icon {
    color: #fcb23b; }

.c-return-to-top {
  align-items: center;
  color: #0058a4;
  display: inline-flex;
  font-size: 0.7777777778rem;
  font-weight: 300;
  text-decoration: none; }
  .c-return-to-top::before {
    background-image: url("../images/return-arrow.svg");
    background-color: #0058a4;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.7777777778rem 0.4444444444rem;
    border-radius: 0 0 60% 60% / 0 0 100% 100%;
    content: '';
    display: inline-block;
    height: 1.8333333333rem;
    margin-right: 8px;
    transition-duration: 200ms;
    transition-property: background-color background-position;
    transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1.5);
    width: 2.6666666667rem; }
  .c-return-to-top:visited {
    color: #0058a4; }
  .c-return-to-top:hover, .c-return-to-top:focus, .c-return-to-top:active {
    color: #006ece; }
    .c-return-to-top:hover::before, .c-return-to-top:focus::before, .c-return-to-top:active::before {
      background-color: #006ece;
      background-position: 50% 40%; }

.c-search-bar {
  background-color: #902f57;
  color: #fff;
  padding: 1.3333333333rem 0; }

.c-search-bar__content {
  align-items: center;
  display: flex;
  justify-content: center; }
  .c-search-bar__content > * {
    flex-grow: 1;
    margin-bottom: 0; }

.c-search-bar__button {
  outline: 2px solid transparent;
  outline-offset: 2px;
  align-items: center;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Source Sans Pro, sans-serif;
  height: 2.4444444444rem;
  justify-content: center;
  margin-left: 0.4444444444rem;
  padding: 0;
  transition-duration: 200ms;
  transition-property: background, color;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  width: 2.4444444444rem; }
  .c-search-bar__button:focus {
    outline-color: #1888bb; }
  .c-search-bar__button:hover, .c-search-bar__button:focus {
    background-color: #d61b5a; }

.c-search-bar__button-icon {
  height: 0.7777777778rem;
  overflow: hidden;
  width: 0.4444444444rem;
  background-image: inline("../images/search-arrow.svg");
  background-position: 50%;
  background-repeat: no-repeat; }
  .c-search-bar__button-icon::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }

.c-search-bar--mobile {
  margin-bottom: 1.3333333333rem;
  margin-left: 0.8888888889rem; }

.js .js-search-block {
  display: none; }

.c-search-button {
  outline: 2px solid transparent;
  outline-offset: 2px;
  align-items: center;
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Source Sans Pro, sans-serif;
  font-size: 0.8888888889rem;
  font-weight: 300;
  justify-content: center;
  margin: 0.1111111111rem 0 1.4444444444rem; }
  .c-search-button:focus {
    outline-color: #006ece; }
  @media print {
    .c-search-button {
      display: none; } }

.c-search-button__icon {
  background-image: inline("../images/search-white.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  font-size: 0.8333333333rem;
  height: 1em;
  margin-right: 8px;
  width: 1em; }

.c-search-forms {
  margin: 24px 0; }
  .c-search-forms .c-form-item {
    margin-bottom: 0.6666666667rem; }
    @media (min-width: 50em) {
      .c-search-forms .c-form-item {
        max-width: 85%; } }
  .c-search-forms .c-form-item__label {
    display: none; }

.c-search-forms__select,
.c-search-forms__search {
  background-color: #fff;
  background-position: 10px center;
  background-repeat: no-repeat;
  border: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  padding-left: 2.2222222222rem; }

.c-banner--maroon .c-search-forms__search:hover,
.c-banner--maroon .c-search-forms__search:focus {
  border: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.9); }

.c-search-forms__select {
  background-image: url("../images/location.svg"); }

.c-search-forms__search {
  background-image: url("../images/search-gray.svg");
  margin-bottom: 1.3333333333rem; }

.c-search {
  display: flex;
  margin-bottom: 1.3333333333rem; }
  .c-search > * {
    flex-grow: 1;
    margin-bottom: 0; }
  .c-search .c-button {
    flex-grow: 0; }

.c-search--maroon {
  margin-bottom: 0; }
  .c-search--maroon .c-form-item {
    position: relative; }
  .c-search--maroon .c-form-item__label {
    display: inline-block;
    font-size: 22px;
    font-weight: 700;
    margin-right: 1rem; }
    @media (min-width: 85em) {
      .c-search--maroon .c-form-item__label {
        /*left: rem(-180px);
      position: absolute;
      top: rem(7px);*/ } }
  .c-search--maroon .c-form-item__text {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.7);
    color: #fff;
    height: auto;
    line-height: 1;
    min-width: 16.6666666667rem;
    padding-bottom: 0.8888888889rem;
    padding-left: 1.3333333333rem;
    padding-top: 0.8888888889rem; }
    .c-search--maroon .c-form-item__text::placeholder {
      color: #fff; }

/* stylelint-disable */
.selectability-offscreen {
  position: absolute;
  left: -999em; }

.selectability {
  background-image: url("../images/icon--location.svg");
  display: block;
  max-width: 100%;
  background-color: #fff;
  background-position: 10px center;
  background-repeat: no-repeat;
  border: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  padding-left: 2.2222222222rem; }
  .selectability:focus {
    border: 2px solid #FCB23B; }
  .location-select .selectability::after {
    background-image: url("../images/menu-arrow-down-magenta.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    font-size: 1.3333333333rem;
    height: 1.6666666667rem;
    line-height: 1;
    position: absolute;
    right: 0.5555555556rem;
    top: 0.6111111111rem;
    width: 1.6666666667rem; }
  .location-select .selectability[aria-expanded="true"]::after {
    transform: rotateX(180deg); }

.selectability [role*=textbox] {
  color: #515760;
  padding: 10px; }

.selectability[aria-expanded=true] [role*=listbox] {
  padding: 1rem 2.2222222222rem;
  background-color: white;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 800;
  width: 100%;
  left: 0; }

.selectability [role=option] {
  cursor: default; }

.selectability [role=option][aria-disabled=false]:hover,
.selectability [role=option][aria-disabled=false]:focus {
  font-weight: bold; }

.selectability [aria-disabled=true] {
  color: #999; }

/* stylelint-enable */
.c-site-name {
  background-image: url("../images/lfc-logo--white.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 85px auto;
  display: block;
  margin-left: 1.3333333333rem;
  padding-left: 5.2777777778rem;
  text-decoration: none; }
  .c-site-name.c-site-name--mobile {
    margin-bottom: 2rem;
    margin-top: 0.8888888889rem; }
  @media (min-width: 0) and (max-width: 23.8888888889rem) {
    .c-site-name {
      float: left;
      max-width: 10rem; } }
  @media (min-width: 50em) {
    .c-site-name {
      background-size: 150px auto;
      margin-bottom: 0.8888888889rem;
      margin-left: 1.3333333333rem;
      padding-left: 8.0555555556rem; } }
  @media (min-width: 68.75em) {
    .c-site-name {
      left: 0;
      position: absolute;
      top: 15px; } }
  @media print {
    .c-site-name {
      background: transparent;
      padding-left: 0; } }

.c-site-name__text {
  color: #fff;
  font-size: 1rem;
  line-height: 3rem;
  margin-bottom: 0; }
  @media (min-width: 0) and (max-width: 23.8888888889rem) {
    .c-site-name__text {
      line-height: 1;
      padding-bottom: 5px;
      padding-top: 5px; } }
  @media (min-width: 0) and (max-width: 50em) {
    .c-site-name__text {
      font-size: 0.8888888889rem; } }
  @media (min-width: 50em) {
    .c-site-name__text {
      font-size: 1.5555555556rem; } }

.c-site-name--footer {
  height: 5.5555555556rem;
  margin: 0 0 0.8888888889rem;
  width: 12.6651126651rem; }

.c-skiplinks {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen-Sans", Ubuntu, Cantarell, "Fira Sans", Droid Sans, sans-serif;
  left: 50%;
  margin-left: -7em;
  position: absolute;
  width: 14em;
  z-index: 9999; }

.c-skiplinks__link,
.c-skiplinks__link:visited,
.c-skiplinks__link:focus {
  background-color: #333;
  border: 2px solid #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  color: #eee;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  outline: 0;
  padding: 0.25em 0.5em;
  text-align: center;
  text-decoration: none;
  width: 14em; }

/* stylelint-disable-next-line selector-max-id */
#main:focus {
  outline: 0; }

.c-slider {
  background: linear-gradient(to right, #fff 0%, #fff 30%, #f0f0f2 30%, #f0f0f2 100%);
  overflow-x: hidden;
  padding: 64px 0 64px 32px;
  position: relative; }
  .c-slider .slick-track {
    padding: 0.5555555556rem 0 0.5555555556rem 0.3888888889rem; }
  .c-slider.c-slider--no-nav {
    padding-right: 64px; }

.c-slider__count,
.c-slider__title {
  display: inline-block; }

.c-slider__count {
  color: #515760;
  font-size: 16px;
  margin-left: 1.3333333333rem; }
  .c-slider__count strong {
    font-size: 24px; }
  @media (min-width: 50em) {
    .c-slider__count {
      margin-left: 5.3333333333rem; } }

.c-slider__slides {
  /*left: 15rem;
  position: absolute;*/ }
  @media (min-width: 50em) {
    .c-slider__slides {
      margin-left: 5rem;
      width: 102vw; } }
  @media (min-width: 50em) {
    .c-slider--no-nav .c-slider__slides {
      margin-left: 0;
      width: 95vw; } }
  .c-slider__slides .c-card__image img {
    object-fit: cover;
    width: 100%; }

.c-slider__content .c-card {
  margin-right: 1.1111111111rem; }
  @media (min-width: 0) and (max-width: 50em) {
    .c-slider__content .c-card {
      margin-bottom: 1.1111111111rem; } }

.c-slider__content .slick-current {
  box-shadow: 0 0 7px 0 rgba(12, 15, 20, 0.5), inset 0 0 0 5px #0058a4; }
  .c-slider__content .slick-current .c-card__image {
    border: 5px solid #0058a4;
    border-bottom: 0; }

@media (min-width: 50em) {
  .c-slider__navigation {
    left: 32px;
    position: absolute; } }

.c-slider__navigation .slick-arrow {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 3.3333333333rem;
  margin-bottom: 0.8888888889rem;
  transition: transform 0.3s ease-in-out;
  width: 3.3333333333rem; }
  @media (min-width: 50em) {
    .c-slider__navigation .slick-arrow {
      display: block; } }
  .c-slider__navigation .slick-arrow:hover, .c-slider__navigation .slick-arrow:focus {
    transform: translate(5px); }

.c-slider__navigation .slick-prev {
  background-image: url("../images/circle-arrow--left.svg"); }
  .c-slider__navigation .slick-prev:hover, .c-slider__navigation .slick-prev:focus {
    background-image: url("../images/circle-arrow--left-full.svg"); }

.c-slider__navigation .slick-next {
  background-image: url("../images/circle-arrow--right-full.svg"); }

.slick-track {
  display: flex; }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    padding-bottom: 3.5555555556rem; }

.tag {
  border: 1px solid #d61b5a;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  margin: 0 8px 8px 0;
  padding: 4px 8px;
  text-decoration: none; }
  .tag:visited, .tag:hover, .tag:focus, .tag:active {
    background-color: #d61b5a;
    color: #fff; }

.c-tagline {
  color: #515760;
  font-size: 0.7777777778rem;
  font-weight: 300;
  margin-bottom: 1.3333333333rem; }

.c-tagline--legal {
  font-size: 12px;
  margin-bottom: 0.4444444444rem; }
  .c-tagline--legal img {
    margin-right: 0.8888888889rem; }
    @media (min-width: 0) and (max-width: 53.75em) {
      .c-tagline--legal img {
        display: block;
        margin-bottom: 0.8888888889rem; } }
  .l-footer__affiliated .c-tagline--legal {
    font-size: 14px; }

.teaser {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  margin-bottom: calc(2rem + 40px); }
  @media (min-width: 50em) {
    .teaser {
      grid-template-columns: 270px 1fr; } }

.teaser__title {
  margin: 16px 0; }
  .teaser__title a {
    text-decoration: none; }
    .teaser__title a:hover, .teaser__title a:focus {
      border-bottom: 2px solid #002d55; }
    .teaser__title a:visited, .teaser__title a:hover, .teaser__title a:focus, .teaser__title a:active {
      color: #002d55; }

.teaser__tag {
  color: #902f57;
  font-weight: 600; }

.award-teaser {
  color: #515760;
  font-size: 14px;
  line-height: 1;
  margin-top: 1.3333333333rem;
  padding-bottom: 0.8888888889rem;
  padding-top: 0.4444444444rem; }
  .award-teaser.award-teaser__award {
    background-image: url("../images/trophy.svg");
    background-position: left top;
    background-repeat: no-repeat;
    padding-left: 1.7777777778rem; }

.three-columns {
  margin-top: 4rem; }
  @media (min-width: 48.4375em) {
    .three-columns {
      align-items: stretch;
      /* stylelint-disable */
      display: -ms-grid;
      /* stylelint-enable */
      display: grid;
      /* stylelint-disable */
      -ms-grid-columns: 200px 1fr 1fr;
      -ms-grid-rows: 1fr 1fr;
      /* stylelint-enable */
      grid-template-columns: 200px 1fr 1fr; } }
  @media (min-width: 61.25em) {
    .three-columns {
      /* stylelint-disable */
      -ms-grid-columns: 400px 1fr 1fr;
      /* stylelint-enable */
      grid-template-columns: 400px 1fr 1fr; } }
  .three-columns p {
    font-size: 18px; }

.three-columns__image {
  /* stylelint-disable */
  -ms-grid-column: 200px;
  /* stylelint-enable */ }
  @media (min-width: 0) and (max-width: 48.4375em) {
    .three-columns__image {
      margin-bottom: 2rem; } }
  @media (min-width: 48.4375em) {
    .three-columns__image {
      margin-right: 72px; } }
  @media (min-width: 61.25em) {
    .three-columns__image {
      /* stylelint-disable */
      -ms-grid-column: 400px;
      /* stylelint-enable */ } }

.three-columns__text-first {
  margin-right: 24px;
  /* stylelint-disable */
  -ms-grid-column: 2;
  /* stylelint-enable */ }

.three-columns__text-last {
  /* stylelint-disable */
  -ms-grid-column: 3;
  /* stylelint-enable */ }

.three-columns__empty {
  /* stylelint-disable */
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  /* stylelint-enable */ }

.three-columns__button {
  /* stylelint-disable */
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  /* stylelint-enable */ }

.c-video {
  height: 0;
  margin-bottom: 2.2222222222rem;
  margin-top: 2.2222222222rem;
  padding-bottom: 56.25%;
  position: relative; }
  .c-video > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  @media print {
    .c-video {
      display: none; } }

.u-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  width: 1px !important;
  word-wrap: normal !important; }
  .u-visually-hidden.is-focusable:focus, .u-visually-hidden.is-focusable:active {
    clip: auto !important;
    height: auto !important;
    overflow: visible !important;
    position: static !important;
    width: auto !important; }

.u-hidden {
  display: none !important; }

.u-invisible {
  visibility: hidden !important; }

.u-clearfix::after {
  clear: both !important;
  content: "" !important;
  display: table !important; }

.u-spaced-2xs,
.u-spaced-2xs-above {
  margin-top: 0.2222222222rem !important; }

.u-spaced-2xs,
.u-spaced-2xs-below {
  margin-bottom: 0.2222222222rem !important; }

.u-spaced-xs,
.u-spaced-xs-above {
  margin-top: 0.4444444444rem !important; }

.u-spaced-xs,
.u-spaced-xs-below {
  margin-bottom: 0.4444444444rem !important; }

.u-spaced-sm,
.u-spaced-sm-above {
  margin-top: 0.8888888889rem !important; }

.u-spaced-sm,
.u-spaced-sm-below {
  margin-bottom: 0.8888888889rem !important; }

.u-spaced-md,
.u-spaced-md-above {
  margin-top: 1.3333333333rem !important; }

.u-spaced-md,
.u-spaced-md-below {
  margin-bottom: 1.3333333333rem !important; }

.u-spaced-lg,
.u-spaced-lg-above {
  margin-top: 1.7777777778rem !important; }

.u-spaced-lg,
.u-spaced-lg-below {
  margin-bottom: 1.7777777778rem !important; }

.u-spaced-xl,
.u-spaced-xl-above {
  margin-top: 2.6666666667rem !important; }

.u-spaced-xl,
.u-spaced-xl-below {
  margin-bottom: 2.6666666667rem !important; }

.u-spaced-2xl,
.u-spaced-2xl-above {
  margin-top: 3.5555555556rem !important; }

.u-spaced-2xl,
.u-spaced-2xl-below {
  margin-bottom: 3.5555555556rem !important; }

.u-spaced-3xl,
.u-spaced-3xl-above {
  margin-top: 5.3333333333rem !important; }

.u-spaced-3xl,
.u-spaced-3xl-below {
  margin-bottom: 5.3333333333rem !important; }

.u-spaced-none,
.u-spaced-none-above {
  margin-top: 0 !important; }

.u-spaced-none,
.u-spaced-none-below {
  margin-bottom: 0 !important; }
