// @file
// Styles for an image card.

.c-card--image {
  background-color: gesso-grayscale(white);
  padding: 0;

  .c-card__image {
    position: relative;
  }

  .c-card__status {
    left: rem(gesso-spacing(md));
    position: absolute;
    top: rem(gesso-spacing(md));
  }

  &.c-card__view-more {
    @include linear-gradient(
    gesso-color(gradients, maroon, start),
    gesso-color(gradients, maroon, middle),
    gesso-color(gradients, maroon, end),
    90deg);
    color: gesso-grayscale(white);

    .c-card__content {
      align-self: center;
    }

    a,
    .c-card__title {
      color: gesso-grayscale(white);
      font-size: gesso-font-size(10);
      max-width: 80%;

      &::after {
        @include svg-background(yellow-arrow-right);
        background-position: right rem(5px);
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 52px;
        margin-left: 0.5rem;
        vertical-align: middle;
        width: 30px;
      }
    }

    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: gesso-grayscale(white);
      .c-card__title { 
        color: gesso-grayscale(white);
      }
    }

    &:hover,
    &:focus {
      background: gesso-brand(maroon, base);
    }
  }
}
