// @file
// Styles for a figure.

.c-figure {
  margin: 0 auto rem(gesso-spacing(md));

  @include breakpoint(gesso-breakpoint(figure)) {
    &.is-aligned-left,
    &.is-aligned-right {
      max-width: 40%;
    }

    &.is-aligned-left {
      float: left;
      margin-left: 0;
      margin-right: rem(gesso-spacing(md));
      margin-top: rem(5px);
    }

    &.is-aligned-right {
      float: right;
      margin-left: rem(gesso-spacing(md));
      margin-right: 0;
      margin-top: rem(5px);
    }
  }
}

.c-figure__caption {
  color: gesso-grayscale(gray-5);
  font-size: rem(gesso-font-size(1));
  margin-top: rem(gesso-spacing(md));
}

.c-figure__credit {
  font-size: rem(gesso-font-size(0));
  margin-top: rem(gesso-spacing(2xs));
}
