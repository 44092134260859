// @file
// Definition list styles.

dd {
  margin-bottom: rem(gesso-spacing(xs));
}

dl {
  margin: 0 0 rem(gesso-spacing(xs));
}

// dt {}
