// @file
// Styles for an image hero.

.c-hero--image {
  background-position: 50%;
  background-size: cover;
  overflow: hidden;
  padding: 0;

  .c-hero__logo {
    align-items: flex-end;
    display: flex;
    min-height: rem(500px);

    img {
      box-shadow: gesso-box-shadow(1);
    }
  }
}
