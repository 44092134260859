// @file
// Styles for the main menu.

$subnav-width: 170px;

.c-menu--main {
  @include list-clean;

  @include breakpoint(430px) {
    display: flex;
  }

  > .c-menu__item {
    display: inline-block;
    margin-right: rem(gesso-spacing(lg));
    position: relative;

    > .c-menu__link {
      border-bottom: 8px solid transparent;
      color: gesso-grayscale(white);
      display: inline-block;
      font-size: rem(gesso-font-size(3));
      font-weight: 700;
      padding-bottom: 1em;
      position: relative;
      text-decoration: none;
      transition-duration: gesso-duration(short);
      transition-property: background, border, color;
      transition-timing-function: gesso-easing(ease-in);

      &:hover,
      &:focus,
      &.c-menu__link--active {
        border-bottom: 8px solid gesso-brand(yellow, base);
      }
    }
  }

  @media print {
    display: none;
  }
}

// Hide primary menu on load if JS is enabled so there’s no ghosting.
.js .js-primary-menu {
  display: none;

  @include breakpoint(gesso-breakpoint('mobile-menu')) {
    display: flex;
  }
}
