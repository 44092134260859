// @file
// Styles for a card.

.c-card {
  box-shadow: 0 0 7px 0 rgba(149,155,165,0.50);
  color: gesso-brand(navy, base);
  display: block;
  text-decoration: none;
  transition: background 0.2s ease-in-out;

  * {
    transition: background 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    background-color: gesso-brand(navy, base);
    color: gesso-grayscale(white);

    .c-card__type,
    .c-card__date,
    .c-card__award,
    .award-teaser {
      color: gesso-grayscale(white);
    }
  }

  &:visited {
    .c-card__title,
    .c-card__text {
      color: gesso-brand(navy, base);
    }

  }

  &:hover,
  &:focus {
    .c-card__title,
    .c-card__text,
    .c-card__type,
    .c-card__date,
    .c-card__award {
      color: gesso-grayscale(white) !important;
    }
  }
}

.c-card__content {
  > :last-child {
    margin-bottom: 0;
  }
}

.c-card__text {
  padding: rem(20px);
}

.c-card__title {
  @extend %h4;
  color: inherit;
  margin: gesso-spacing(sm) 0;
}

.c-card__status {
  background: gesso-brand(maroon, light);
  color: gesso-grayscale(white);
  font-size: rem(gesso-font-size(2));
  padding: rem(3px) rem(gesso-spacing(xs));
}

.c-card__meta {
  align-items: center;
  display: flex;
  margin-bottom: rem(gesso-spacing(md));
}

.c-card__type {
  color: gesso-brand(maroon, base);
  display: inline-block;
  line-height: gesso-line-height(tight);
  margin-right: rem(gesso-spacing(sm));
  transition-duration: gesso-duration(short);
  transition-property: background, color;
  transition-timing-function: gesso-easing(ease-in);
}

.c-card__date {
  color: gesso-grayscale(gray-5);
  font-size: rem(gesso-font-size(1));
  transition-duration: gesso-duration(short);
  transition-property: background, color;
  transition-timing-function: gesso-easing(ease-in);
}

.c-card__view-more {
  transition: none;
}
