// @file
// Styles for a maroon-blue banner.

.c-banner--blue {
  @include linear-gradient(
    gesso-color(gradients, navy-blue-dark, end),
    gesso-color(gradients, navy-blue-dark, middle),
    gesso-color(gradients, navy-blue-dark, start),
    270deg
  );
  color: gesso-grayscale(white);
}

.c-banner__content {
}
