// @file
// Styles for select form items.

$form-select-arrow-size: 20px !default;

.c-form-item--select {
  .c-form-item__select {
    @include svg-background(select-arrows);
    background-color: gesso-grayscale(white);
    background-position: right rem($form-select-arrow-size / 2) center; // LTR
    background-repeat: no-repeat;
    background-size: rem($form-select-arrow-size);
    padding-right: rem($form-select-arrow-size * 2); // LTR

    @if $support-for-rtl {
      [dir='rtl'] & {
        background-position: left rem($form-select-arrow-size / 2) center;
        padding-left: rem($form-select-arrow-size * 2);
        padding-right: 0;
      }
    }

    // Remove inner focus styling on Firefox.
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
