// @file
// Styles for the secondary menu.
$subnav-width: 170px;
$bp-masthead-pin: 1100px;

.c-menu--secondary {
  @include list-clean;
  display: flex;
  margin-bottom: rem(gesso-spacing(md));

  @include breakpoint($bp-masthead-pin) {
    margin: 0 auto rem(gesso-spacing(md));
  }

  .c-menu__item {
    color: gesso-brand(yellow, base);
    font-size: gesso-font-size(1);
    line-height: gesso-line-height(tight);
    margin-right: rem(gesso-spacing(sm));

    &:last-child {
      margin-right: 0;
    }
  }

  .c-menu__item.has-subnav {
    position: relative;
  }

  .c-menu__link {
    border-bottom: 1px solid transparent;
    color: gesso-grayscale(white);
    text-decoration: none;
    transition: all linear 0.3s;

    &:hover,
    &:focus,
    &[aria-expanded='true'] {
      border-color: gesso-brand(magenta, base);
      color: gesso-grayscale(white);
    }

    /* stylelint-disable */
    .js &.has-subnav {
      padding-right: rem(36px);
      z-index: 2;

      @include breakpoint(900px) {
        padding-right: rem(30px);
      }

      &::after {
        @include image-replace(0.75rem, 0.75rem);
        @include svg-background(menu-arrow-down);
        background-size: 0.75rem;
        content: '';
        display: block;
        font-size: rem(22px);
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(900px) {
          @include image-replace(1rem, 1rem);
          background-size: 1rem;
        }
      }

      &:hover,
      &:focus,
      &:active {
        &::after {
          @include svg-background(menu-arrow-down-yellow);
        }
      }
      /* stylelint-enable */
    }
  }

  .c-menu__subnav {
    background-color: gesso-grayscale(white);
    box-shadow: gesso-box-shadow(3);
    display: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;

    @include breakpoint(800px 1150px) {
      right: 0;
    }

    .c-menu__item {
      margin-right: 0;
    }

    .c-menu__link {
      color: gesso-grayscale(gray-5);
      display: block;
      font-size: rem(gesso-font-size(1));
      font-weight: 300;
      line-height: gesso-line-height(tight);
      padding: rem(12px);
      position: relative;
      text-decoration: none;
      transition: all ease-in-out 0.3s;
      width: rem($subnav-width);

      &:hover,
      &:focus {
        background-color: gesso-brand(magenta, base);
        color: gesso-grayscale(white);
      }

      &.has-subnav::after {
        @include image-replace(16px, 16px);
        @include svg-background(menu-arrow-right);
        background-size: 16px 16px;
        content: '';
        display: block;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease-in-out 0.3s;
      }
    }

    .c-menu__subnav {
      margin: 0;
    }
  }
}
