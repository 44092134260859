// @file
// Styles for the mobile menu.

$mobile-menu-fallback-bg-color: gesso-brand(maroon, base) !default;
$mobile-menu-bg-color: ggesso-brand(maroon, base) !default;
$mobile-menu-link-color: gesso-grayscale(white) !default;
$mobile-menu-link-hover-color: gesso-grayscale(white) !default;
$mobile-menu-submenu-fallback-bg-color: gesso-color(
  ui,
  generic,
  background-lighter
) !default;
$mobile-menu-submenu-bg-color: rgba(
  gesso-color(ui, generic, background-lighter),
  0.98
) !default;
$mobile-menu-submenu-link-color: gesso-grayscale(gray-5) !default;
$mobile-menu-submenu-link-hover-color: gesso-grayscale(white) !default;
$mobile-menu-button-height: 44px;
$mobile-menu-button-width: 44px;
$mobile-menu-font-size: gesso-font-size(3);
$mobile-menu-line-height: gesso-line-height(tight);
$bp-mobile-menu--secondary: 800px;

.c-mobile-menu {
  background-color: $mobile-menu-fallback-bg-color;
  background-color: $mobile-menu-bg-color;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}

.c-mobile-menu__menu {
  @include list-clean;
  clear: both;
  margin: 0;

  + .c-mobile-menu__menu {
    > .c-mobile-menu__item:first-child {
      @include breakpoint(0 $bp-mobile-menu--secondary) {
        display: none;
      }
    }
  }
}

.c-mobile-menu__item {
  margin: 0;
  padding: 0;
  position: relative;
}

.c-mobile-menu__link {
  background-color: transparent;
  border: 0;
  color: $mobile-menu-link-color;
  display: block;
  font-family: gesso-font-family(system);
  font-size: rem($mobile-menu-font-size);
  font-weight: gesso-font-weight(bold);
  line-height: $mobile-menu-line-height;
  outline-offset: 0;
  padding: rem(
      (
          $mobile-menu-button-height -
            ($mobile-menu-font-size * $mobile-menu-line-height)
        ) / 2
    )
    rem(gesso-spacing(sm));
  text-align: left;
  text-decoration: none;
  white-space: normal;
  width: auto;
  z-index: 3;

  &:visited {
    color: $mobile-menu-link-color;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    color: $mobile-menu-link-hover-color;
  }

  &.has-subnav {
    margin-right: rem($mobile-menu-button-width);
  }
}

.c-mobile-menu__subnav-arrow {
  @include focus();
  @include image-replace(
    rem($mobile-menu-button-width),
    rem($mobile-menu-button-height)
  );
  @include svg-background-inline(mobile-arrow-down);
  background-attachment: initial;
  background-color: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: rem(14px) rem(8px);
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  outline-offset: 0;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: top;
  z-index: 2;

  @if $support-for-rtl {
    [dir='rtl'] & {
      left: 0;
      right: auto;
    }
  }

  &[aria-expanded='true'] {
    @include svg-background-inline(mobile-arrow-up);
  }
}

.c-mobile-menu__subnav {
  background-color: $mobile-menu-submenu-fallback-bg-color;
  background-color: $mobile-menu-submenu-bg-color;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  .c-mobile-menu__link {
    color: $mobile-menu-submenu-link-color;
    font-size: rem(gesso-font-size(2));
    font-weight: 300;
    line-height: gesso-line-height(tight);
    padding-left: gesso-spacing(lg);

    &:visited {
      color: $mobile-menu-submenu-link-color;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: gesso-brand(magenta, base);
      color: $mobile-menu-submenu-link-hover-color;
    }
  }
}
