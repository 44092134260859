// @file
// Styles for fields.

.c-field {
  color: gesso-brand(navy, base);
  margin-bottom: rem(gesso-spacing(md));

  > :last-child {
    margin-bottom: 0;
  }
}

.c-field__label {
  font-weight: gesso-font-weight(bold);
}
