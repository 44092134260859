// @file
// Styles for an icon button.

.c-button--icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;

  .c-button__text {
    margin-left: rem(gesso-spacing(xs));
  }
}
