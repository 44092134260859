// @file
// Styles for the site name.

$bp-sitename: 800px;
$bp-masthead-pin: 1100px;

.c-site-name {
  background-image: url('../images/lfc-logo--white.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 85px auto;
  display: block;
  margin-left: rem(gesso-spacing(md));
  padding-left: rem(95px);
  text-decoration: none;

  &.c-site-name--mobile {
    margin-bottom: 2rem;
    margin-top: rem(16px);
  }

  @include breakpoint(0 rem(430px)) {
    float: left;
    max-width: rem(180px);
  }

  @include breakpoint($bp-sitename) {
    background-size: 150px auto;
    margin-bottom: rem(gesso-spacing(sm));
    margin-left: rem(gesso-spacing(md));
    padding-left: rem(145px);
  }

  @include breakpoint($bp-masthead-pin) {
    left: 0;
    position: absolute;
    top: 15px;
  }

  @media print {
    background: transparent;
    padding-left: 0;
  }
}

.c-site-name__text {
  color: gesso-grayscale(white);
  font-size: rem(gesso-font-size(3));
  line-height: rem(54px);
  margin-bottom: 0;

  @include breakpoint(0 rem(430px)) {
    line-height: 1;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  @include breakpoint(0 $bp-sitename) {
    //line-height: 1;
  font-size: rem(gesso-font-size(2));
  }

  @include breakpoint($bp-sitename) {
    font-size: rem(gesso-font-size(7));
  }
}
