// @file
// Styles for the introduction.

.c-introduction {
  color: gesso-brand(maroon, base);
  font-size: rem(gesso-font-size(5));
  margin-bottom: rem(gesso-spacing(md));

  @include breakpoint(max-width gesso-breakpoint(font-size)) {
    font-size: rem(gesso-font-size(5));
    font-size: responsive-font-size(
      gesso-font-size(5),
      gesso-font-size(6),
      gesso-breakpoint(font-size)
    );
  }
}
