.info-box {
  box-shadow: 0 0 7px 0 rgba(149, 155, 165, 0.5);
  display: block;
  margin: rem(gesso-spacing(md)) 0;
  padding: rem(gesso-spacing(sm));
  text-decoration: none;

  &.info-box__link {
    @include svg-background(external-link--grey);
    background-color: gesso-grayscale(white);
    background-position: 95% 1rem;
    background-repeat: no-repeat;
  }

  img {
    max-width: calc(100% - 20px);
  }

  h6 {
    color: gesso-brand(navy, base);
    margin-bottom: 0;
    text-decoration: none;
  }

  &::after {
    display: none !important;
  }
}

.info-box__title {
  @include svg-background-inline(trophy);
  background-position: left top;
  background-repeat: no-repeat;
  border-bottom: 1px solid gesso-grayscale(gray-3);
  color: gesso-brand(maroon, base);
  font-size: rem(gesso-font-size(3));
  line-height: 1;
  margin-bottom: rem(gesso-spacing(md));
  padding-bottom: rem(gesso-spacing(sm));
  padding-left: rem(gesso-spacing(lg));
  padding-top: rem(gesso-spacing(xs));
  text-decoration: none;
}
