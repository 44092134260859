// @file
// Accordion Icon styles

// Note: icons may require custom positioning inside their containers
// Note: icon parent should be position: relative for proper positioning

@mixin accordion-icon-is-open() {
  color: gesso-brand(maroon, light);
  content: '-';
  display: block;
  font-size: rem(gesso-font-size(10));
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
}

@mixin accordion-icon-is-closed() {
  color: gesso-brand(maroon, light);
  content: '+';
  display: block;
  font-size: rem(gesso-font-size(8));
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
