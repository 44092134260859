// @file
// Styles for the read more link.

.c-read-more {
  align-items: center;
  //border-bottom: 1px solid transparent;
  //border-top: 1px solid gesso-brand(magenta, base);
  display: inline-flex;
  font-size: rem(gesso-font-size(4));
  font-weight: 500;
  padding: rem(6px) 0;
  position: relative;
  text-decoration: none;
  transition-duration: gesso-duration(short);
  transition-property: all;
  transition-timing-function: gesso-easing(ease-in-out);

  &::before,
  &::after {
    background-color: gesso-brand(magenta, base);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    transition-duration: gesso-duration(short);
    transition-property: all;
    transition-timing-function: gesso-easing(ease-in-out);
    width: 0;
  }

  &::before {
    width: 100%;
  }

  &::after {
    bottom: 0;
    top: auto;
  }

  &:hover,
  &:focus,
  &:active {
    //border-bottom-color: gesso-brand(magenta, base);
    //border-top-color: transparent;
    color: gesso-brand(navy, base);

    &::before {
      width: 0;
    }

    &::after {
      width: 100%;
    }

    .c-read-more__icon {
      right: rem(5px);
    }
  }
}

.c-read-more__icon {
  color: gesso-brand(magenta, base);
  font-size: rem(gesso-font-size(0));
  margin-left: rem(gesso-spacing(md));
  margin-top: rem(-3px);
  position: relative;
  right: 0;
  transition-duration: gesso-duration(short);
  transition-property: all;
  transition-timing-function: gesso-easing(ease-in-out);
}

.c-read-more--reverse {
  color: gesso-grayscale(white);

  &::before,
  &::after {
    background-color: gesso-brand(yellow, base);
  }

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: gesso-grayscale(white);
  }

  .c-read-more__icon {
    color: gesso-brand(yellow, base);
  }
}
