// @file
// List item styles.

li {
  margin-bottom: rem(gesso-spacing(xs));
  padding-left: rem(gesso-spacing(xs));

  @media print {
    page-break-inside: avoid;
  }
}
