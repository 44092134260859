// @file
// Styles for the footer menu.

.c-menu--footer {
  @include list-inline;
  margin-bottom: rem(gesso-spacing(md));

  .c-menu__item {
    margin-right: rem(gesso-spacing(md));

    &:last-child {
      margin-right: 0;
    }
  }

  .c-menu__link {
    color: gesso-brand(navy, base);
    font-size: gesso-font-size(3);
    font-weight: 400;
    line-height: gesso-line-height(tight);
    padding: rem(9px) 0;
    text-decoration: none;

    &:hover,
    &:focus {
      color: gesso-brand(maroon, base);
      text-decoration: underline;
    }
  }

  @media print {
    display: none;
  }
}
