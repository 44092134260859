// @file
// Styles for a legal tagline.

.c-tagline--legal {
  font-size: gesso-font-size(0);
  margin-bottom: rem(gesso-spacing(xs));

  img {
    margin-right: rem(gesso-spacing(sm));

    @include breakpoint(0 gesso-breakpoint(footer-layout)) {
      display: block;
      margin-bottom: rem(gesso-spacing(sm));
    }
  }

  .l-footer__affiliated & {
    font-size: gesso-font-size(1);
  }
}
