// @file
// Styles for the hero.

.c-hero {
  background-color: gesso-grayscale(gray-2);
  padding: rem(gesso-spacing(xl)) 0 rem(gesso-spacing(lg)) 0;
}

.c-hero__content {
  > :last-child {
    margin-bottom: 0;
  }
}

.c-hero__type {
  color: gesso-brand(maroon, base);
  display: inline-block;
  font-size: rem(gesso-font-size(3));
  font-weight: 600;
  line-height: gesso-line-height(tight);
  margin: 0 0 rem(gesso-spacing(md));
}

.c-hero__tag {
  border: 1px solid gesso-brand(navy, base);
  display: inline-block;
  font-size: rem(gesso-font-size(3));
  font-weight: 600;
  line-height: gesso-line-height(tight);
  margin: 0 rem(gesso-spacing(sm)) rem(gesso-spacing(sm)) 0;
  padding: rem(gesso-spacing(2xs));
}

.c-hero__title {
  margin: 0 0 rem(gesso-spacing(md));
}

.c-hero__project-title {
  font-weight: gesso-font-weight(bold);
  margin-bottom: rem(gesso-spacing(sm));
}

.c-hero__summary {
  margin-bottom: rem(gesso-spacing(xs));

  p {
    font-size: gesso-font-size(3);
    margin-bottom: 0;
  }
}

.c-hero__submitted {
  color: gesso-grayscale(gray-5);
  font-size: gesso-font-size(1);
  margin-bottom: rem(gesso-spacing(xs));
}

.c-hero__date {
  color: gesso-grayscale(gray-5);
  display: inline-block;
  font-size: rem(gesso-font-size(1));
}

.c-hero__actor {
  border-radius: 50%;
  overflow: hidden;
}

.c-hero__main {
  > :last-child {
    margin-bottom: 0;
  }
}

.c-hero__meta {
  > :last-child {
    margin-bottom: 0;
  }
}

.c-hero__link {
  color: gesso-brand(navy, base);

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: gesso-brand(navy, base);
  }
}

.c-hero__downloads {
  align-items: center;
  display: inline-flex;
  margin-top: rem(gesso-spacing(sm));
  position: relative;

  > * {
    margin-bottom: 0;
  }
}

// TODO: Remove once no longer in Wagtail templates.
.c-hero__link--file {
  @include svg-background(file-link);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto rem(16px);
  color: gesso-brand(maroon, base);
  display: inline-block;
  padding-left: rem(gesso-spacing(sm));
  vertical-align: middle;
}

.c-hero__info-icon {
  @include image-replace(16px, 16px);
  @include svg-background(info-bubble);
  background-size: contain;
  display: inline-block;
  margin-left: rem(gesso-spacing(sm));
  opacity: 0.3;
}

.c-hero__help-text {
  background-color: gesso-grayscale(white);
  display: none;
  min-width: 25vw;
  padding: 0.75rem;
  position: absolute;
  right: -25.5vw;
  top: -15px;
}
