// @file
// Paragraph styles.

p {
  font-size: rem(gesso-font-size(5));
  line-height: gesso-line-height(base);
  margin: 0 0 rem(gesso-spacing(md));

  @media print {
    orphans: 3;
    widows: 3;
  }
}
