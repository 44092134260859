// @file
// Styles for the site name.

$svg-height: 62.92;
$svg-width: 143.44;
$logo-height-footer: 100px;

.c-site-name--footer {
  height: rem($logo-height-footer);
  margin: 0 0 rem(gesso-spacing(sm));
  width: rem(($svg-width / $svg-height) * $logo-height-footer);
}
