/* stylelint-disable */
.selectability-offscreen {
  position: absolute;
  left: -999em;
}

.selectability {
  @include svg-background(icon--location);
  display: block;
  max-width: 100%;
  background-color: gesso-grayscale(white);
  background-position: 10px center;
  background-repeat: no-repeat;
  border: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  padding-left: rem(40px);

  &:focus {
    border: 2px solid #FCB23B;
  }

  .location-select & {
    &::after {
      @include svg-background(menu-arrow-down-magenta);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      font-size: rem(gesso-font-size(6));
      height: rem(30px);
      line-height: 1;
      position: absolute;
      right: rem(10px);
      top: rem(11px);
      width: rem(30px);
    }
  }

  &[aria-expanded="true"] {
    .location-select & {
      &::after {
        transform: rotateX(180deg);
      }
    }
  }
}

.selectability [role*=textbox] {
  color: gesso-grayscale(gray-5);
  padding: 10px;
}

.selectability [role*=textbox]::after {

}

.selectability[aria-expanded="true"] [role*=textbox]::after {

}

.selectability[aria-expanded=true] [role*=listbox] {
  padding: 1rem rem(40px);
  background-color: white;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 800;
  width: 100%;
  left: 0;
}

.selectability [role=option] {
  cursor: default;
}

.selectability [role=option][aria-disabled=false]:hover,
.selectability [role=option][aria-disabled=false]:focus {
  font-weight: bold;
}

.selectability [aria-disabled=true] {
  color: #999;
}
/* stylelint-enable */