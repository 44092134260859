// @file
// Styles for a maroon banner.

.c-banner--maroon {
  @include linear-gradient(
    gesso-color(gradients, maroon, start),
    gesso-color(gradients, maroon, middle),
    gesso-color(gradients, maroon, end),
    90deg);
  color: gesso-grayscale(white);
}
