// DO NOT EDIT THIS FILE.  This is a gitignored artifact created by Gulp.
// Design tokens should be edited in config.design-tokens.yml
$gesso: (
  palette: (
    brand: (
      true-blue: (
        base: #0058a4,
        dark: #00427c,
        light: #006ece,
      ),
      navy: (
        base: #002d55,
      ),
      light-blue: (
        base: #2cace3,
        dark: #1888bb,
        light: #d8eff9,
      ),
      green: (
        base: #00853e,
        dark: #005a2a,
      ),
      magenta: (
        base: #d61b5a,
      ),
      maroon: (
        base: #902f57,
        dark: #63203c,
        light: #a91552,
      ),
      yellow: (
        base: #fcb23b,
      ),
    ),
    grayscale: (
      white: #fff,
      gray-1: #fafafb,
      gray-2: #f0f0f2,
      gray-3: #c5c8ce,
      gray-4: #959ba5,
      gray-5: #515760,
      gray-6: #32353b,
      gray-7: #fbfbfb,
      black: #212121,
    ),
    other: (
      yellow: (
        base: #fad980,
        light: #fff1d2,
      ),
      yellow-neon: (
        base: #ff0,
      ),
      green: (
        base: #94bfa2,
        light: #e7f4e4,
      ),
      red: (
        base: #e31c3d,
        light: #e59393,
        light-1: #f9dede,
        dark: #cd2026,
        dark-1: #981b1e,
      ),
    ),
  ),
  box-shadow: (
    0: none,
    1: '0 0 4px 0 rgba(0, 0, 0, 0.2)',
    2: '0 0 6px rgba(0, 0, 0, 0.3)',
    3: '0 0 8px 0 rgba(0, 0, 0, 0.4)',
  ),
  constrains: (
    content: 720px,
    sm: 1000px,
    md: 1200px,
    lg: 1400px,
  ),
  breakpoints: (
    footer-menu: 452px,
    blockquote: 500px,
    breadcrumb: 500px,
    figure: 500px,
    font-size: 700px,
    media: 700px,
    mobile-menu: 800px,
    footer-layout: 860px,
    small-constrain: 1000px,
  ),
  colors: (
    gradients: (
      navy-blue-light: (
        start: #002d55,
        middle: #0058a4,
        end: #2cace3,
      ),
      navy-blue-dark: (
        start: #002d55,
        middle: #00427c,
        end: #0058a4,
      ),
      blue-maroon: (
        start: #002d55,
        middle: #0058a4,
        end: #902f57,
      ),
      maroon: (
        start: #63203c,
        middle: #902f57,
        end: #a91552,
      ),
    ),
    text: (
      primary: #002d55,
      secondary: #515760,
      on-light: #212121,
      on-dark: #fff,
      link: #902f57,
      link-hover: #63203c,
      link-active: #a91552,
      link-visited: #63203c,
    ),
    background: (
      site: #fbfbfb,
    ),
    button: (
      primary: (
        background: #902f57,
        background-hover: #902f57,
        background-active: #a91552,
        text: #fff,
        text-hover: #fff,
        text-active: #fff,
      ),
      secondary: (
        background: #00853e,
        background-hover: #005a2a,
        background-active: #005a2a,
        text: #fff,
        text-hover: #fff,
        text-active: #fff,
      ),
      disabled: (
        background: #515760,
        text: #fff,
      ),
      danger: (
        background: #e31c3d,
        background-hover: #cd2026,
        background-active: #981b1e,
        text: #fff,
        text-hover: #fff,
        text-active: #fff,
      ),
    ),
    form: (
      background: #f0f0f2,
      background-active: #fff,
      background-checked: #0058a4,
      background-unchecked: #fff,
      border: #c5c8ce,
      border-dark: #515760,
      border-light: #fafafb,
      thumb: #515760,
      track: #959ba5,
    ),
    mark: (
      background: #ff0,
      text: #212121,
    ),
    selection: (
      background: #0058a4,
      text: #fff,
    ),
    table: (
      border: #515760,
      background: #fff,
      background-head: #f0f0f2,
      background-foot: #f0f0f2,
    ),
    ui: (
      generic: (
        background: #959ba5,
        background-darker: #212121,
        background-dark: #32353b,
        background-light: #f0f0f2,
        background-lighter: #fafafb,
        border: #959ba5,
        border-dark: #32353b,
        border-light: #f0f0f2,
        text-lighter: #fff,
        text-light: #959ba5,
        text-dark: #515760,
        text-darker: #212121,
        accent: #0058a4,
        accent-dark: #00427c,
        accent-light: #006ece,
        focus: #006ece,
        focus-light: #1888bb,
      ),
      error: (
        background: #f9dede,
        border: #e59393,
      ),
      success: (
        background: #e7f4e4,
        border: #94bfa2,
      ),
      warning: (
        background: #fff1d2,
        border: #fad980,
      ),
    ),
  ),
  typography: (
    font-family: (
      primary: (
        name: Primary,
        stack: 'Source Sans Pro, sans-serif',
      ),
      secondary: (
        name: Secondary,
        stack: 'Oswald, sans-serif',
      ),
      system: (
        name: System,
        stack: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen-Sans", Ubuntu, Cantarell, "Fira Sans", Droid Sans, sans-serif',
      ),
      monospace: (
        name: Monospace,
        stack: 'Consolas, "Lucida Console", "Liberation Mono", "Courier New", monospace, sans-serif',
      ),
    ),
    base-font-size: 18px,
    font-size: (
      0: 12px,
      1: 14px,
      2: 16px,
      3: 18px,
      4: 20px,
      5: 22px,
      6: 24px,
      7: 28px,
      8: 32px,
      9: 40px,
      10: 48px,
      11: 64px,
      12: 54px,
    ),
    font-weight: (
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    ),
    line-height: (
      tight: 1.1,
      medium: 1.2,
      medium2: 1.3,
      base: 1.5,
      loose: 1.7,
    ),
    display: (
      display: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 64px,
        font-weight: 500,
        line-height: 1.1,
      ),
      h1: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 54px,
        font-weight: 500,
        line-height: 1.2,
      ),
      h2: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 40px,
        font-weight: 500,
        line-height: 1.2,
      ),
      h3: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 32px,
        font-weight: 500,
        line-height: 1.3,
      ),
      h4: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 28px,
        font-weight: 500,
        line-height: 1.3,
      ),
      h5: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 22px,
        font-weight: 500,
        line-height: 1.1,
      ),
      h6: (
        color: #002d55,
        font-family: 'Oswald, sans-serif',
        font-size: 18px,
        font-weight: 500,
        letter-spacing: .03em,
        line-height: 1.1,
      ),
      blockquote: (
        color: #002d55,
        font-family: 'Source Sans Pro, sans-serif',
        font-size: 32px,
        font-weight: 300,
        line-height: 1.5,
      ),
      body: (
        color: #002d55,
        font-family: 'Source Sans Pro, sans-serif',
        font-size: 18px,
        font-weight: 300,
        line-height: 1.5,
      ),
      body-large: (
        color: #002d55,
        font-family: 'Source Sans Pro, sans-serif',
        font-size: 20px,
        font-weight: 300,
        line-height: 1.5,
      ),
      cite: (
        color: #515760,
        font-family: 'Source Sans Pro, sans-serif',
        font-size: 16px,
        font-style: normal,
        font-weight: 400,
        letter-spacing: .02em,
        line-height: 1.1,
      ),
    ),
  ),
  transitions: (
    ease: (
      ease-in-out: 'cubic-bezier(0.4, 0, 0.2, 1)',
      ease-out: 'cubic-bezier(0.0, 0, 0.2, 1)',
      ease-in: 'cubic-bezier(0.4, 0, 1, 1)',
      ease-in-overshoot: 'cubic-bezier(0.5, 0, 0.5, 1.5)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    ),
    duration: (
      shortest: 150ms,
      short: 200ms,
      standard: 375ms,
      long: 400ms,
      intro: 270ms,
      outro: 195ms,
    ),
  ),
  z-index: (
    nav: 1000,
    drawer: 1200,
    modal: 1300,
  ),
  spacing: (
    2xs: 4px,
    xs: 8px,
    sm: 16px,
    md: 24px,
    lg: 32px,
    xl: 48px,
    2xl: 64px,
    3xl: 96px,
  ),
);