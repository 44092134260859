// @file
// Styles for a maroon card.

.c-card--maroon {
  background-color: transparent;
  color: gesso-grayscale(white);
  position: relative;

  &::before {
    @include linear-gradient(
      gesso-color(gradients, maroon, start),
      gesso-color(gradients, maroon, middle),
      gesso-color(gradients, maroon, end),
      134deg);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &:hover,
  &:focus {
    background-color: gesso-brand(navy, base);
    background-image: none;
  }

  .c-card__type {
    background-color: gesso-grayscale(white);
  }

  .c-card__date {
    color: gesso-grayscale(white);
  }
}
