.c-info-card {
  background: gesso-grayscale(white);
  box-shadow: 0 0 7px 0 rgba(149, 155, 165, 0.5);
  margin: rem(gesso-spacing(lg)) 0;
  padding: rem(gesso-spacing(lg));

  .info-card__description {
    color: gesso-grayscale(gray-5);
    line-height: 1.1;
  }
}

.c-info-card__title {
  color: gesso-brand(maroon, base);
  margin-bottom: rem(gesso-spacing(md));
}

.c-info-card__intro {
  h6 {
    margin-bottom: rem(gesso-spacing(2xs));
  }

  h4 {
    margin-bottom: rem(gesso-spacing(sm));
  }

  a {
    color: gesso-brand(navy, base);
    text-decoration: none;
  }
}

.c-info-card__content {
  border-top: 1px solid gesso-grayscale(gray-3);
  margin-top: rem(gesso-spacing(lg));

  .l-grid--2col {
    margin: rem(gesso-spacing(lg)) 0;
  }
  .c-field {
    margin-bottom: 0;
  }
}
