// @file
// Styles for the legal menu.

.c-menu--legal {
  @include list-clean;
  display: flex;
  margin-bottom: rem(gesso-spacing(md));

  .c-menu__item {
    line-height: gesso-line-height(tight);
    margin-right: rem(gesso-spacing(sm));

    &:last-child {
      margin-right: 0;
    }
  }

  .c-menu__link {
    color: gesso-grayscale(gray-5);
    font-size: gesso-font-size(0);
    line-height: gesso-line-height(tight);

    &:hover,
    &:focus {
      color: gesso-brand(maroon, base);
    }
  }

  @media print {
    display: none;
  }
}
