.location-select {
  position: relative;
  

  .c-form-item__select {
    @include svg-background(location);
    background-color: gesso-grayscale(white);
    background-position: 10px center;
    background-repeat: no-repeat;
    border: 0;
    box-shadow: 0 0 7px 0 #0000004d;
    padding-left: rem(40px);
  }
}
