// @file
// Iframe element styles.

iframe {
  border: 0;

  @media print {
    display: none;
  }
}
