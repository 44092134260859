// @file
// Styles for form items.

$form-background-color: gesso-color(form, background) !default;
$form-background-color-focus: gesso-color(form, background-active) !default;
$form-border-color: gesso-color(form, border) !default;
$form-border-color-focus: gesso-color(form, border-dark) !default;
$form-box-shadow-focus: gesso-box-shadow(3) !default;
$form-disabled-opacity: 0.5 !default;
$form-text-size: gesso-font-size(2) !default;

.c-form-item {
  margin: 0 0 rem(gesso-spacing(md));
}

.c-form-item__label {
  display: block;
  font-size: rem($form-text-size);
  font-weight: 300;

  .is-disabled > & {
    opacity: $form-disabled-opacity;
  }
}

.c-form-item__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// .c-form-item__legend {}

.c-form-item__description {
  color: gesso-color(text, secondary);
  font-size: rem(gesso-font-size(1));

  > :last-child {
    margin-bottom: 0;
  }
}

.c-form-item__required-marker::before {
  content: '*';
}

%form-item__box,
.c-form-item__color,
.c-form-item__date,
.c-form-item__email,
.c-form-item__month,
.c-form-item__number,
.c-form-item__password,
.c-form-item__search,
.c-form-item__select,
.c-form-item__tel,
.c-form-item__text,
.c-form-item__textarea,
.c-form-item__time,
.c-form-item__url,
.c-form-item__week {
  appearance: none;
  background-color: $form-background-color;
  border: 1px solid $form-border-color;
  border-radius: 0;
  display: inline-block;
  font-size: rem($form-text-size);
  height: rem(60px);
  line-height: (30px / $form-text-size);
  margin: 0;
  max-width: 100%;
  padding: em(10px, $form-text-size) em(7px, $form-text-size);
  transition-duration: gesso-duration(short);
  transition-property: background-color, border;
  transition-timing-function: gesso-easing(ease-in-out);

  &::placeholder {
    color: gesso-grayscale(gray-5);
  }

  &:focus {
    @include focus();
  }

  &:hover,
  &:focus {
    background-color: $form-background-color-focus;
    border: 1px solid $form-border-color-focus;
  }

  &:disabled {
    cursor: default;
    opacity: $form-disabled-opacity;

    &:hover,
    &:focus {
      background-color: $form-background-color;
      border: 1px solid $form-border-color;
    }
  }

  .is-full-width > & {
    width: 100%;
  }
}

.c-form-item__textarea {
  height: auto;
}
