// @file
// Styles for a maroon banner.

.c-banner--2col {
  .c-banner__content {
    align-items: center;
    display: grid;
    //grid-gap: 2rem;
    /* stylelint-disable */
    -ms-grid-columns: 1fr;
    /* stylelint-enable */
    grid-template-columns: 1fr;

    @include breakpoint(800px) {
      //grid-gap: 5rem;
      /* stylelint-disable */
      -ms-grid-columns: 1fr 1fr;
      /* stylelint-enable */
      grid-template-columns: 1fr 1fr;
    }
  }

  &.c-banner--top-align {
    .c-banner__content {
      align-items: flex-start;
    }
  }

  &.c-banner--featured-col {
    .c-banner__content {
      @include breakpoint(gesso-breakpoint(footer-layout)) {
        /* stylelint-disable */
        -ms-grid-columns: 1fr 2fr;
        /* stylelint-enable */
        grid-template-columns: 1fr 2fr;
      }
    }
  }

  .c-banner__title {
    /* stylelint-disable */
    -ms-grid-row: 1; 
    -ms-grid-column: 1;
    /* stylelint-enable */
    @include breakpoint(800px) {
      margin-right: 5rem;
    }
  }

  .c-banner__col2 {
    /* stylelint-disable */
    -ms-grid-row: 1; 
    -ms-grid-column: 2;
    /* stylelint-enable */
  }

  .c-banner__col2-title {
    color: gesso-grayscale(white);
    font-weight: 500;
    margin-bottom: gesso-spacing(sm);
  }

  .c-banner__col2-title--small {
    p {
      font-size: gesso-font-size(3);
    }

    a,
    a:visited {
      color: gesso-grayscale(white);
    }

    + .c-search-forms {
      margin-bottom: gesso-spacing(md);
      @include breakpoint(gesso-breakpoint(footer-layout)) {
        margin-bottom: 0;
      }
    }
  }
}
