// @file
// Responsive font-size

@function responsive-font-size($mobile-size, $desktop-size, $breakpoint) {
  //@return calc(#{rem($mobile-size)} + #{(($desktop-size - $mobile-size) / $breakpoint) * 100}vw);

  // $scale-factor: (($desktop-size - $mobile-size) / $breakpoint) * 100;
  // $small-size: $mobile-size - (2.5px * $scale-factor);
  // @return calc(#{rem($small-size)} + #{$scale-factor}vw);

  @return calc(#{rem($mobile-size - 6px)} + #{(($desktop-size - ($mobile-size - 6px)) / $breakpoint) * 100}vw);
}
