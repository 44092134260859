// @file
// Styles for a maroon-blue banner.

.c-banner--maroon-blue {
  @include linear-gradient(
    gesso-color(gradients, blue-maroon, start),
    false,
    gesso-color(gradients, blue-maroon, end),
    270deg);
  color: gesso-grayscale(white);
}
