// @file
// Preformatted text styles.

pre {
  margin: 0 0 rem(gesso-spacing(md));
  overflow: auto;

  @media print {
    page-break-inside: avoid;
  }
}
