// @file
// Styles for a search.

.c-search {
  display: flex;
  margin-bottom: rem(gesso-spacing(md));

  > * {
    flex-grow: 1;
    margin-bottom: 0;
  }

  .c-button {
    flex-grow: 0;
  }
}
