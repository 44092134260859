// @file
// Styles for the share menu.

$link-font-size: gesso-font-size(2);
$link-width: 30px;
$link-padding: ($link-width - $link-font-size) / 2;

.c-menu--share {
  @include list-clean;
  align-items: center;
  display: flex;
  margin-left: rem(6px);

  .c-menu__link {
    color: gesso-brand(true-blue, base);
    display: block;
    font-size: rem($link-font-size);
    line-height: 0.75;
    padding: rem($link-padding);
    text-decoration: none;

    &:hover,
    &:focus {
      color: gesso-brand(maroon, base);
    }
  }
}
