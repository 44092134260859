// @file
// Styles for Footer Layout.

.l-footer {
}

.l-footer__main {
  @include breakpoint(gesso-breakpoint(footer-layout)) {
    align-items: flex-end;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

.l-footer__site-name {
  padding-top: rem(44px);

  h3 a {
    color: gesso-brand(navy, base);
    margin-bottom: 0;
    text-decoration: none;
  }
}

.l-footer__menu {
  @include breakpoint(gesso-breakpoint(footer-layout)) {
    padding-top: rem(gesso-spacing(md));
    text-align: right;
  }
}

.l-footer__legal {
  @include breakpoint(gesso-breakpoint(footer-layout)) {
    align-items: baseline;
    display: flex;
    justify-content: flex-end;
  }

  > * {
    margin-right: rem(gesso-spacing(md));

    :last-child {
      margin-right: 0;
    }
  }

  @media print {
    justify-content: flex-start;
  }
}

.l-footer__affiliated {
  border-top: 1px solid #d8d8d8;
  padding: em(gesso-spacing(md)) 0;
}
