// @file
// Styles for the Listing layout.

$bp-listing-layout: 800px;

.l-listing {
  margin-bottom: rem(gesso-spacing(lg));
  padding-top: rem(gesso-spacing(3xl));

  @include breakpoint($bp-listing-layout) {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(gesso-spacing(3xl));
  }
}

.l-listing__facets {
  @include breakpoint($bp-listing-layout) {
    width: 23%;
    width: calc(25% - #{rem(gesso-spacing(sm))});
  }
}

.l-listing__sort {
  text-align: right;

  .c-form-item__label {
    display: inline;
  }
}

.l-listing__content {
  @include breakpoint($bp-listing-layout) {
    width: 73%;
    width: calc(75% - #{rem(gesso-spacing(sm))});
  }
}

.l-listing__items {
  margin-bottom: rem(gesso-spacing(md));
}
