// @file
// Styles for the banner newsletter signup.

$bp-banner-newsletter: 800px;

.c-newsletter-signup--banner {
  .c-newsletter-signup__header {
    @extend %h1;
    color: inherit;
    text-align: left;
  }

  .c-newsletter-signup__button {
    .c-button {
      border-left: 0;
    }
  }

  @include breakpoint($bp-banner-newsletter) {
    align-items: center;
    display: flex;
    justify-content: space-around;

    .c-newsletter-signup__header,
    .c-newsletter-signup__content {
      margin: 0;
      width: 33%;
    }
  }
}
