// @file
// Styles for Masthead layout.

$bp-masthead: 800px;
$bp-masthead-pin: 1100px;

.l-masthead {
  @include linear-gradient(
    gesso-color(gradients, maroon, start),
    gesso-color(gradients, maroon, middle),
    gesso-color(gradients, maroon, end),
    90deg
  );

  position: relative;

  @include breakpoint(0 $bp-masthead) {
    .l-constrain {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include breakpoint($bp-masthead) {
    padding-top: rem(gesso-spacing(sm));
  }

  @media print {
    background-color: transparent;
  }
}

.l-masthead__content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpoint($bp-masthead) {
    align-items: flex-end;
  }

  @include breakpoint($bp-masthead-pin) {
    padding-top: 15px;
  }
}

.l-masthead__footer {
  display: none;

  @include breakpoint($bp-masthead) {
    border-top: 1px solid #d8d8d8;
    display: block;
    padding-top: rem(gesso-spacing(md));
  }

  @media print {
    display: none;
  }
}
