// @file
// Normalize resets.
//
// Inspiration: http://necolas.github.com/normalize.css
// version 3.0.2

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
pre,
samp {
  font-family: gesso-font-family(monospace);
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

audio,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focusring,
[type='button']::-moz-focusring,
[type='reset']::-moz-focusring,
[type='submit']::-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

template,
[hidden] {
  display: none;
}

abbr,
blockquote,
code,
kbd,
q,
samp,
tt,
var {
  hyphens: none;
}

// Don’t show focus styles for elements that can’t be accessed via keyboard.
[tabindex='-1']:focus {
  outline: none !important;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
::-moz-selection {
  background: gesso-color(selection, background);
  color: gesso-color(selection, text);
  text-shadow: none;
}

::selection {
  background: gesso-color(selection, background);
  color: gesso-color(selection, text);
  text-shadow: none;
}
