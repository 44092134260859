// @file
// Styles for the second alternate billboard.

.c-billboard--alt-2 {
  .c-billboard__media {
    &::before {
      background-image: url('../images/billboard-alt-2-before.png');
    }

    &::after {
      background-image: url('../images/billboard-alt-2-after.png');
    }
  }
}
