// @file
// Styles for Breadcrumb trail.

$breadcrumb-spacing: 3px;
$bp-masthead-pin: 1100px;

.c-breadcrumb {
  @include clearfix();
  background-color: gesso-grayscale(gray-2);
  color: gesso-grayscale(gray-5);
  font-size: rem(gesso-font-size(1));
  padding: rem(gesso-spacing(lg) - $breadcrumb-spacing) 0;
}

.c-breadcrumb__title {
  @extend %visually-hidden;
}

.c-breadcrumb__list {
  @include layout-constrain;
  margin: 0;
  max-width: rem(gesso-constrain(sm));
  padding: 0;

  @include breakpoint($bp-masthead-pin) {
    @include layout-constrain;
    max-width: rem(gesso-constrain(sm));
  }
}

.c-breadcrumb__item {
  display: inline-block;
  margin: rem($breadcrumb-spacing) rem($breadcrumb-spacing) rem($breadcrumb-spacing) 0;
  padding: 0;

  &::after {
    content: '/';
    margin-left: rem($breadcrumb-spacing);
  }

  &:last-child::after {
    content: '';
    margin-left: 0;
  }
}

.c-breadcrumb__link {
  color: gesso-brand(navy, base);
  text-decoration: none;

  &:visited {
    color: gesso-brand(navy, base);
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover,
  &:focus {
    color: gesso-brand(maroon, base);
  }
}

.c-breadcrumb .c-breadcrumb__item {
  @include breakpoint(max-width gesso-breakpoint(breadcrumb)) {
    display: none;

    &:nth-last-child(2) {
      display: block;

      &::after {
        display: none;
      }

      &::before {
        content: '<';
        margin-right: rem($breadcrumb-spacing);
      }
    }
  }
}
