// @file
// Styles for a pullquote.

.c-pull-quote {
  text-align: center;

  > :last-child {
    margin-bottom: 0;
  }

  @include breakpoint(gesso-breakpoint(blockquote)) {
    &.is-aligned-left,
    &.is-aligned-right {
      font-size: rem(gesso-font-size(6));
      margin-bottom: rem(gesso-spacing(md));
      margin-top: rem(7px);
      max-width: 40%;
      padding: rem(gesso-spacing(md)) 0;
      text-align: left;

      @include breakpoint(max-width gesso-breakpoint(font-size)) {
        font-size: rem(gesso-font-size(4));
        font-size: responsive-font-size(
          gesso-font-size(4),
          gesso-font-size(6),
          gesso-breakpoint(font-size)
        );
      }

      p {
        &::before,
        &::after {
          content: '';
        }
      }

      .c-pull-quote__share-links {
        justify-content: flex-start;
      }
    }

    &.is-aligned-left {
      float: left;
      margin-right: rem(gesso-spacing(2xl));
    }

    &.is-aligned-right {
      float: right;
      margin-left: rem(gesso-spacing(2xl));
    }
  }
}

.c-pull-quote__text {
}

.c-pull-quote__cite {
}

.c-pull-quote__share-links {
  align-items: center;
  color: gesso-grayscale(gray-5);
  display: flex;
  font-size: rem(gesso-font-size(1));
  justify-content: center;

  .c-menu__link {
    &:hover,
    &:active {
      cursor: pointer;
    }
  }
}
