// @file
// Styles for the search bar.

.c-search-button {
  @include focus();
  align-items: center;
  background: transparent;
  border: 0;
  color: gesso-grayscale(white);
  cursor: pointer;
  display: flex;
  font-family: gesso-font-family(primary);
  font-size: rem(gesso-font-size(2));
  font-weight: 300;
  justify-content: center;
  margin: rem(2px) 0 rem(26px);

  @media print {
    display: none;
  }
}

.c-search-button__icon {
  @include svg-background-inline(search-white);
  background-position: 50%;
  background-repeat: no-repeat;
  font-size: rem(15px);
  height: 1em;
  margin-right: gesso-spacing(xs);
  width: 1em;
}
