// @file
// Styles for range form items.

$form-range-thumb-border-radius: 0 !default;
$form-range-thumb-border-width: 1px !default;
$form-range-thumb-height: 44px !default;
$form-range-thumb-width: 22px !default;
$form-range-track-border-width: 1px !default;
$form-range-track-height: 10px !default;

@mixin c-form-item__range-thumb {
  @include focus();
  background: gesso-color(form, thumb);
  border: $form-range-thumb-border-width solid gesso-color(form, border-light);
  border-radius: $form-range-thumb-border-radius;
  cursor: pointer;
  height: rem($form-range-thumb-height);
  transition: box-shadow gesso-duration(short) gesso-easing(ease-in-out);
  width: rem($form-range-thumb-width);
}

@mixin c-form-item__range-track {
  background: gesso-color(form, track);
  border: $form-range-track-border-width solid gesso-color(form, border-dark);
  cursor: pointer;
  display: block;
  height: rem($form-range-track-height);
  margin: 0;
  outline: 0;
  width: 100%;
}

.c-form-item--range {
  .c-form-item__range {
    appearance: none;
    background: transparent;
    border: 0;
    height: rem($form-range-thumb-height);
    margin: 0.2em 0;
    padding: 0;

    // Remove outer focus styling on Firefox.
    &::-moz-focus-outer {
      border: 0;
    }

    &:focus {
      box-shadow: none;
      outline: 0;

      &::-moz-range-thumb {
        outline-color: gesso-color(ui, generic, focus);
      }

      &::-ms-thumb {
        outline-color: gesso-color(ui, generic, focus);
      }

      &::-webkit-slider-thumb {
        outline-color: gesso-color(ui, generic, focus);
      }
    }

    &:disabled {
      cursor: default;
      opacity: $form-disabled-opacity;
    }

    &::-moz-range-thumb {
      @include c-form-item__range-thumb;
    }

    &::-moz-range-track {
      @include c-form-item__range-track;
    }

    &::-ms-thumb {
      @include c-form-item__range-thumb;
      margin-top: 0 !important; // Removes webkit-specific margin since IE uses webkit prefixes.
    }

    &::-ms-track {
      @include c-form-item__range-track;
      background: transparent;
      border: 0;
      color: transparent;
    }

    &::-ms-fill-lower,
    &::-ms-fill-upper {
      background: gesso-color(form, track);
      border: $form-range-track-border-width solid gesso-color(form, border-dark);
    }

    &::-webkit-slider-thumb {
      @include c-form-item__range-thumb;
      -webkit-appearance: none;
      margin-top: rem(((-$form-range-track-border-width * 2) + $form-range-track-height) / 2) - rem($form-range-thumb-height / 2);
    }

    &::-webkit-slider-runnable-track {
      @include c-form-item__range-track;
    }
  }

  &.is-full-width > .c-form-item__range {
    width: 100%;
  }
}
