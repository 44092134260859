// @file
// Styles for a video.

$video-aspect-ratio: (9 / 16); // 16:9

.c-video {
  height: 0;
  margin-bottom: rem(40px);
  margin-top: rem(40px);
  padding-bottom: $video-aspect-ratio * 100%;
  position: relative;

  > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media print {
    display: none;
  }
}
