// @file
// Styles for the skiplinks.

.c-skiplinks {
  font-family: gesso-font-family(system);
  left: 50%;
  margin-left: -7em;
  position: absolute;
  width: 14em;
  z-index: 9999;
}

.c-skiplinks__link,
.c-skiplinks__link:visited,
.c-skiplinks__link:focus {
  background-color: #333;
  border: 2px solid #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-shadow: gesso-box-shadow(2);
  color: #eee;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  outline: 0;
  padding: 0.25em 0.5em;
  text-align: center;
  text-decoration: none;
  width: 14em;
}

// Remove :focus styles for skiplink targets.
/* stylelint-disable-next-line selector-max-id */
#main:focus {
  outline: 0;
}
