// @file
// Styles for the Grid layout.

$gutter: gesso-spacing(lg);

.l-grid {
  display: flex;
  flex-wrap: wrap;

  &.has-gutters {
    margin: 0 rem($gutter / -2);

    > * {
      margin-left: rem($gutter / 2);
      margin-right: rem($gutter / 2);
    }
  }
}

.l-grid--2col {
  @include flex-grid(1, $gutter, 0);

  @supports (display: grid) {
    @include css-fixed-grid(1);
  }

  @include breakpoint(600px) {
    > * {
      @include set-flex-column(2, $gutter, 275px);
    }

    @supports (display: grid) {
      @include set-css-fixed-columns(2, $gutter, true);
    }
  }
}

.l-grid--3col {
  @include flex-grid(1, $gutter, 0);
  justify-content: center;

  @supports (display:grid) {
    @include css-fixed-grid(1);
  }

  @include breakpoint(760px) {
   > * {
     @include set-flex-column(3, $gutter, 275px);
   }

    @supports (display: grid) {
      @include set-css-fixed-columns(3, $gutter, true);
    }
  }
}

.l-grid--4col {
  @include flex-grid(1, $gutter, 0);
  align-items: center;
  justify-content: center;

  @include breakpoint(600px 800px) {
    > * {
      @include set-flex-column(2, $gutter, 275px);
    }
  }

  @include breakpoint(801px) {
    > * {
      @include set-flex-column(4, $gutter, 0);
    }
  }
}
